import React, { useState, useEffect } from "react";
import icon from "../../assets/images/lock_icon.png";
import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { useForm } from "react-hook-form";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import LottieLoader from '../lottie/lottieloader';

function Security() {
  const [otherlogin, setotherlogin] = useState(false);
  const handleOtherClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  //console.log(location.state,'QUESTIOn')
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const [disable, setDisable] = useState(false);
  const [count, setCount] = React.useState(0);
  const [questions, setQuestons] = useState([]);
  const [userselectedQ, setUserselectedQ] = useState(1);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const local_storage = JSON.parse(localStorage.getItem('userDetails'));
  console.log(local_storage)

  useEffect(() => {
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/security_questions`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': Auth,
      }
    }).then((res) => {
      console.log(res.data.data)
      setQuestons(res.data.data)
    }).catch((e) => {
      console.log(e)

    })

    const getUserFormData = new FormData();
   let key;
      getUserFormData.append('user_id', local_storage.user_id)
      key = local_storage.api_key;
    axios({
      method: 'GET',
      url: `${baseUrl}/Profiles/get_security_question/${local_storage.user_id}`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': key,
      },
      data: getUserFormData
    }).then((res) => {
      if (res.data.status === 1) {
        setValue('answers', res.data.user_answer)
        //setCount(res.data.data.about_me.length)
      }
    }).catch((error) => {
      console.log(error)
    })

  }, [])
  
  // console.log(questions,'questions')
  const onQuestionSubmit = (data) => {
    //alert('hi')
    //return;
    setDisable(true)
    //console.log(data);
    const regFormData = new FormData();
   
    regFormData.append('user_id', local_storage.user_id)
    regFormData.append('question_id', userselectedQ);
    regFormData.append('answer', data.answers);
    //regFormData.append('api_key', 'cd38819260c30284a96411e1747f585a');
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/question_submit`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData

    }).then((res) => {
      // console.log(res, 'ressss')
      setDisable(false)
      if (res.data.status === 1) {
  
          setTimeout(() => {
            navigate('/settings', { state: location.state })
          }, 2500);
        
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      console.log(e.response, 'e')
      if (e.message == 'Request failed with status code 401') {
        setotherlogin(true)
      }
      setDisable(false)
    })
  }
  function handlSelect(event) {
    setUserselectedQ(event.target.value)
    // console.log(event.target.value)
  }
  const handleKeyPress = (evt) => {
    var theEvent = evt || window.event;
    if (count >= 40) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  const aboutInput = (e) => {
    setCount(e.target.value.length)

  }

  //console.log(questions)
  return (

    <div className="d-flex">
      <Modal show={otherlogin} size="lg" onHide={handleOtherClose} animation={false} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="text-center">Sorry! Someone has logged in with your account.Please check and come back</Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => navigate('/Login')} variant="danger">
            ok
          </Button>
        </Modal.Footer>
      </Modal>
      {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}
      <div className="col-6 security_page"></div>
      <div className="col-6 mt-5 content_login">

        <div className="p-2 text-center">
          <img src={icon} />
          <h6 className="mt-5">Security Question</h6>
          <div>
            <p>This if you forget your password, your security question helps establish that own your account.</p>
          </div>
        </div>

        <h3 className="text-start">Question</h3>
        <Form className="mt-3" onSubmit={handleSubmit(onQuestionSubmit)}>
          <Form.Select aria-label="Default select example" onChange={handlSelect}>
            {/* <option value=''>Open this select menu</option> */}
            {questions.map((data, i) => {
              return (
                <option className={`${userselectedQ === data.question_id ? 'yes' : 'noo'}`} key={i} value={data.question_id} selected>{data.question}</option>
              )
            })}
          </Form.Select>
          {/* <span className='error_msg'> {errors.question?.type === 'required' && "Please select  "}</span> */}
          <Form.Control {...register("answers")} onChange={aboutInput}
            onKeyPress={handleKeyPress} type="text" className="form-control mt-4" placeholder="answer" />
          {/* <textarea
            className="form-control mt-4"
            id="exampleFormControlTextarea1"
            rows="1" 
            {...register("answers", {required: true, maxLength: 40})}
          /> */}
          {/* <span className='error_msg'> {errors.answers?.type==='required'&&"Enter your answer"}</span> */}
          {/* <span className='error_msg'> {errors.answers?.type==='minLength'&&" Answer should be 15 to 150 characters"}</span> */}
          {/* <span className='error_msg'> {errors.answers?.type==='maxLength'&& 'Answer should be 40 characters'}</span> */}
          <Button variant="dark" type="submit" className="d-block m-auto mt-3 w-25 btn_disabled">
            SUBMIT
          </Button>

        </Form>
      </div>

    </div>
  )
}

export default Security;