import React, { useEffect } from 'react';
import { Routes, Route, HashRouter } from "react-router-dom";
import Landing from '../components/landingPage';
import Login from '../components/login/login';
import Otp from '../components/pages/otpverification';
import Security from '../components/pages/securityquestions';
import Emailverify from '../components/pages/emailverification';
import Forgotpassword from '../components/pages/forgotpassword';
import Code from '../components/pages/newpassword';
import Welcome from '../components/welcome/welcome';
import Terms from '../components/pages/termsandcondition';
import Privacy from '../components/pages/privacypolicy';
import Displayname from '../components/display/displayname';
import Looking from '../components/display/lookingfor';
import About from '../components/display/aboutme';
import Moreabout from '../components/moreabout/moreabout';
import Hobbies from '../components/moreabout/hobbies';
import Google from '../components/login/google';
import NewPassword from '../components/pages/newpassword';
import AddPhotos from '../components/addphotos/addphotos';
import Selfie from '../components/selfie/selfie';
import SelfieVerification from '../components/selfie/selfieverification';
import Sidemenu from '../components/sidebar/sidemenu';
import Blog from '../components/profile_blog/blog';
import Matchedprofiles from '../components/matchedprofiles/matchedprofiles';
import GooglePass from '../components/social/google_password';
import FacebookPass from '../components/social/facebook_password'
import ToastMassage from '../components/ToastMassage/ToastMassage';
import Shortlist from '../components/shortlist/shortlist';
import Matchrequests from '../components/matchrequests/matchrequests';
import Mutualmatch from '../components/mutualmatch/mutualmatch';
import Requests from '../components/requests/requests';
import Likes from '../components/likes/likes';
import Chat from '../components/chat/chat';
import Notifications from '../components/notifications/notifications';
import Viewers from '../components/viewers/viewers';
import Payment from '../components/payment/payment';
import Matches from '../components/matches/matches';
import Settings from '../components/settings/settings';
import Newskipped from '../components/new&skipped/new&skipped';
import Googlelogin from '../components/login/sociallogins';
import Cookie from '../components/pages/cookiepolicy';
import Termsuse from '../components/pages/termsuse';
import Community from '../components/pages/community';
import Video from '../components/matches/videocall';
import Audio from '../components/matches/audiocall';
import NewChat from '../components/chat/Newchat';
import Testpage from '../components/matches/testpage';
import Paymentsuccess from '../components/payment/paymentsuccess';
import Paymentfail from '../components/payment/paymentfail';
import Aboutpage from '../components/header/About';
import Membership from '../components/header/Membership';
import Private from '../components/login/privateRoutes';
import Contactus from '../components/pages/contactus';
import ProfilePage from '../components/matchedprofiles/profilePage';

function Routing() {

    return (
        <div>
            <HashRouter>
                <Routes>
                {/* <Route path="/profile/:userId/:proId" element={<ProfilePage />} /> */}
                    <Route path='/' element={<Landing />} />
                    <Route path="/profile/:userId/:proId" element={<Private child={<ProfilePage />}><ProfilePage /></Private>} />
                    <Route path='/membership' element={<Membership />} />
                    <Route path='/aboutpage' element={<Aboutpage />} />
                    <Route path='/Terms' element={<Terms />} />
                    <Route path='/Privacy' element={<Privacy />} />
                    <Route path='/Login' element={<Login />} />
                    <Route path='/cookiepolicy' element={<Cookie />} />
                    <Route path='/termsuse' element={<Termsuse />} />
                    <Route path='/Otp' element={<Private child={<Otp />}><Otp /></Private>} />
                    <Route path='/Security' element={<Private child={<Security />}><Security /></Private>} />
                    <Route path='/Emailverify' element={<Private child={<Emailverify />}><Emailverify /></Private>} />
                    <Route path='/Forgotpassword' element={<Forgotpassword />} />
                    <Route path='/Code' element={<Private child={<Code />}><Code /></Private>} />
                    <Route path='/Welcome' element={<Private child={<Welcome />}><Welcome /></Private>} />
                    <Route path='/Displayname' element={<Private child={<Displayname />}><Displayname /></Private>} />
                    <Route path='/Lookingfor' element={<Private child={<Looking />}><Looking /></Private>} />
                    <Route path='/About' element={<Private child={<About />}><About /></Private>} />
                    <Route path='/Moreabout' element={<Private child={<Moreabout />}><Moreabout /></Private>} />
                    <Route path='/Hobbies' element={<Private child={<Hobbies />}><Hobbies /></Private>} />
                    <Route path='/Google' element={<Private child={<Google />}> <Google /> </Private>} />
                    <Route path='/newpassword' element={<NewPassword />} />
                    <Route path='/addphotos' element={<Private child={<AddPhotos />}> <AddPhotos /> </Private>} />
                    <Route path='/selfie' element={<Private child={<Selfie />}> <Selfie /> </Private>} />
                    <Route path='/selfieverification' element={<Private child={<SelfieVerification />}> <SelfieVerification /> </Private>} />
                    <Route path='/sidemenu' element={<Private child={<Sidemenu />}> <Sidemenu /> </Private>} />
                    <Route path='/blog' element={<Private child={<Blog />}> <Blog /> </Private>} />
                    <Route path='/google_password' element={<Private child={<GooglePass />}> <GooglePass /> </Private>} />
                    <Route path='/facebook_password' element={<Private child={<FacebookPass />}> <FacebookPass /> </Private>} />
                    <Route path='/ToastMassage' element={<Private child={<ToastMassage />}> <ToastMassage /> </Private>} />
                    <Route path='/payment' element={<Private child={<Payment />}> <Payment /> </Private>} />
                    <Route path='/googlelogin' element={<Private child={<Googlelogin />}> <Googlelogin /> </Private>} />
                    <Route path='/guideline' element={<Private child={<Community />}> <Community /> </Private>} />
                    <Route path='/video' element={<Private child={<Video />}> <Video /> </Private>} />
                    <Route path='/audio' element={<Private child={<Audio />}> <Audio /> </Private>} />
                    <Route path='/NewChat' element={<Private child={<NewChat />}> <NewChat /> </Private>} />
                    <Route path='/Testpage' element={<Private child={<Testpage />}> <Testpage /> </Private>} />
                    <Route path='/Paymentsuccess' element={<Private child={<Paymentsuccess />}> <Paymentsuccess /> </Private>} />
                    <Route path='/Paymentfail' element={<Private child={<Paymentfail />}><Paymentfail /></Private>} />
                    <Route path='/matchedprofiles' element={<Private child={<Matchedprofiles />}> <Matchedprofiles /> </Private>} />
                    <Route path='/matchrequests' element={<Private child={<Matchrequests />}><Matchrequests /></Private>} />
                    <Route path='/shortlist' element={<Private child={<Shortlist />}><Shortlist /></Private>} />
                    <Route path='/mutualmatch' element={<Private child={<Mutualmatch />}><Mutualmatch /></Private>} />
                    <Route path='/requests' element={<Private child={<Requests />}><Requests /></Private>} />
                    <Route path='/matches' element={<Private child={<Matches />}> <Matches /> </Private>} />
                    <Route path='/newskipped' element={<Private child={<Newskipped />}><Newskipped /></Private>} />
                    <Route path='/chat' element={<Private child={<Chat />}><Chat /></Private>} />
                    <Route path='/likes' element={<Private child={<Likes />}><Likes /></Private>} />
                    <Route path='/viewers' element={<Private child={<Viewers />}><Viewers /></Private>} />
                    <Route path='/notifications' element={<Private child={<Notifications />}><Notifications /></Private>} />
                    <Route path='/settings' element={<Private child={<Settings />}> <Settings /> </Private>} />
                    <Route path='/contactus' element={<Private child={<Contactus />}> <Contactus /> </Private>} />
                    {/* <Route path='/shortlisted' element={<shortlisted/>}/> */}
                    {/* <Route path='/matched' element={<Matched />} /> */}
                </Routes>
            </HashRouter>
        </div>
    );
}

export default Routing;
