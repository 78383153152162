import React, { useState, useEffect } from "react";
import icon from "../../assets/images/lock_icon.png";
import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import loader from '../../assets/images/loading-heart.json'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
//import LottieLoader from '../lottie/lottieloader';
import $ from 'jquery';
import LottieLoader from '../lottie/lottieloader';


function Hobbies() {
  const [otherlogin, setotherlogin] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const location = useLocation();
  // console.log(location)
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [hobbies, setHobbies] = useState([]);
  //const [value, setValue] = useState([]);
  const [disable, setDisable] = useState(false);
  const [getHobbies, setGetHobbies] = useState("");
  const [bdisable, setbdisable] = useState(true);
  const local_storage = JSON.parse(localStorage.getItem('userDetails'));

  useEffect(() => {

    const getUserFormData = new FormData();
    let key;
    if (location.state !== null) {
      getUserFormData.append('user_id', location.state.user_id);
      key = location.state.api_key;
    } else {
      getUserFormData.append('user_id', local_storage.user_id)
      key = local_storage.api_key;
    }
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/get_my_hobbies`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': key,
      },
      data: getUserFormData
    }).then((res) => {
      if (res.data.status === 1) {
        setGetHobbies(res.data.data.interests.split(','))
        //console.log(res.data.data.interests)
        if (res.data.data.interests === '') {
          setbdisable(true);
        } else {
          setbdisable(false);
        }
      }
    }).catch((error) => {
      console.log(error)

    })

    $(".progress-bar").animate({
      width: "99.9%"
    }, 2500);
    axios({
      method: 'GET',
      url: `${baseUrl}/Registration/get_hobbies`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': Auth,
      }
    }).then((res) => {
      // console.log(res.data.data)
      setHobbies(res.data.data)
    }).catch((e) => {
      console.log(e)

    })

  }, [])

  // useEffect(() => {


  // }, [])

  //console.log(getHobbies)

  const onHobbiesSubmit = (data) => {
    // console.log(data);
    setDisable(true)

    // var checkedValue = document.querySelector('.hobbies:checked').value;
    var checkedValue = [];
    var inputElements = document.getElementsByClassName('hobbies');
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        checkedValue.push(inputElements[i].value);
        // break;
      }
    }
    let newHobbies = checkedValue.join()
    // return false
    const regFormData = new FormData();
    let key;
    if (location.state !== null) {
      regFormData.append('user_id', location.state.user_id);
      key = location.state.api_key;
    } else {
      regFormData.append('user_id', local_storage.user_id)
      key = local_storage.api_key;
    }
    regFormData.append('hobbies', newHobbies);

    //regFormData.append('api_key', 'cd38819260c30284a96411e1747f585a');
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/my_hobbies`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': key,
      },
      data: regFormData

    }).then((res) => {
      console.log(res, 'ressss')
      setDisable(false)

      if (res.data.status === 1) {

        setTimeout(() => {
          navigate('/settings', { state: location.state })

        }, 3000);

        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      //  console.log(e.response, 'e')
      setDisable(false)
      if (e.message == 'Request failed with status code 401') {
        setotherlogin(true)
      }
    })
  }
  const handelChangee = (e) => {
    setbdisable(false);
  }
  return (

    <div className="d-flex">
      <Modal show={otherlogin} size="lg" animation={false} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="text-center">Sorry! Someone has logged in with your account.Please check and come back</Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => navigate('/Login')} variant="danger">
            ok
          </Button>
        </Modal.Footer>
      </Modal>
      {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}

      <div className="col-6 hobbies_page"></div>
      <div className="col-6 mt-5 content_login">
        <div class="col-xs-12 col-sm-12 progress-container">
          <div class="progress progress-striped active">
            <div class="progress-bar progress-bar-success" ></div>
          </div>
          {location.state !== null ? <Button variant="danger" onClick={() => navigate("/moreabout", { state: location.state })} className="back_button mt-3"><i className="fa fa-arrow-left">&nbsp; Back</i></Button> : ''}
        </div>
        <div className="text-center">
          <img src={icon} />
          <Form className="mt-5 px-5" onSubmit={handleSubmit(onHobbiesSubmit)}>
            <h3>My Hobbies and Interests</h3>
            <div className="form-check px-0" id="activeweights" >
              {hobbies.map((data, i) => {
                return (
                  <label className="custom-checkbox">
                    <input {...register("hobbies", { required: true })} className="hobbies custom-checkbox-input" onClick={handelChangee} name='hobbies' value={data.interest_id} type="checkbox" defaultChecked={getHobbies && getHobbies.includes(data.interest_id) ? true : false} />
                    <span className="custom-checkbox-text">{data.interest_name}</span>
                  </label>
                )
              })}
              <br></br>
              {/* <span className="checkbox remodal_box" id="1"></span>   */}

              <span className='error_msg'> {errors.hobbies?.type === 'required' && "Please  select Hobbies"}</span>
            </div>
            <Button variant="dark" disabled={bdisable} type="submit" className="d-block m-auto mt-3 w-100 btn_disabled">
              CONTINUE
            </Button>

          </Form>
        </div>
      </div>
    </div>
  )
}

export default Hobbies;