import React, { useState, useEffect } from "react";
import Sidemenu from "../sidebar/sidemenu";
import { useNavigate, useLocation } from 'react-router-dom';
import './requests.css';
import { Card, Carousel } from 'react-bootstrap';
import like from '../../assets/images/outlike.png';
import liked from '../../assets/images/outliked.png';
import female from '../../assets/images/avatarf.png';
import male from '../../assets/images/avatarm.png';
import male1 from '../../assets/images/men_s.svg';
import female1 from '../../assets/images/women_s.svg';
import shortlist from '../../assets/images/outshortlist1.png';
import shortlisted from '../../assets/images/shortlisted.svg';
// import interest from '../../assets/images/outinterest.png';
import Delete from '../../assets/images/delete2.png';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { Tabs, Tab } from 'react-bootstrap-tabs';
import axios from "axios";
import map from '../../assets/images/location.png';
import Toastify from 'toastify-js';
import NoData from '../../assets/images/no_data.gif';
import LottieLoader3 from '../lottie/lottieloader3';
import deleteicon from '../../assets/images/delete-icon.jpg';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import clipboardCopy from 'clipboard-copy';
import interest from '../../assets/images/interested.png';

function Requests() {

    const [closeaction, setcloseaction] = useState('true');
    const close = () => {
        setcloseaction('false');
        setdisplay({user_id:''})
    }

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const Auth = process.env.REACT_APP_USER_TOKEN;
    const [show, setShow] = useState(true);
    const location = useLocation();
    const [value, setValue] = useState({ 'items': [], 'path': '', 'requests': [] })
    const [values, setValues] = useState({ 'requests': [], 'path': '' })
    const bgcolor = '#ffdddd'
    //const gender = JSON.parse(localStorage.getItem('gender'));
    const local_storage = JSON.parse(localStorage.getItem('userDetails'));
    const navigate = useNavigate();
    const [usersData, setUsersData] = useState('');
    const [usersData1, setUsersData1] = useState('');

    function makePageTransparent() {
        var overlay = document.getElementById('overlay');
        overlay.style.display = 'block';
    }

    function makePagenotTransparent() {
        var overlay = document.getElementById('overlay');
        overlay.style.display = 'none';
    }

    const [display_list, setdisplay] = useState({
        about_me: "", age: "", country_name: "", gender: "",
        height: "",
        height_unit: "",
        images: [{ 'image': '' }],
        like_status: "",
        locked_status: "",
        mother_tongue: "",
        name: "",
        nationality_name: "",
        occupation_name: "",
        religion_name: "",
        report_user_list_status: "",
        request_list_id: "",
        request_status: "",
        selfie_status: "",
        shortlist_status: "",
        state_name: "",
        study_name: null,
        unique_id: "",
        user_id: "",
        weight: "",
        weight_unit: "",
        display_name:""
    })

    let path = 0;

    let request_id = []
    useEffect(() => {
        makePageTransparent();
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id);
        regFormData.append('min', 0)
        regFormData.append('max', 10)
        regFormData.append('type', 0);

        //console.log(lat)
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/sentrequests`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData

        }).then((res) => {
            const UserData = res.data.status
            //console.log(res);
            if (UserData === 0) {
                setUsersData(UserData);
                makePagenotTransparent();
            }
            if (res.data.status === 1) {
                //console.log(res.data.path)
                if(res.data.data.length>0){
                    const proFormData = new FormData();
                    proFormData.append('user_id', local_storage.user_id);
                    proFormData.append('profile_id',res.data.data[0].user_id);
                    axios({
                        method: 'POST',
                        url: `${baseUrl}/Profiles/profile_view`,
                        headers: {
                            "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                            'X-api-key': local_storage.api_key,
                        },
                        data: proFormData
                
                    }).then((response) => {
                        if (response.data.status === 1) {
                            setTimeout(() => {
                                setdisplay({
                                    about_me: response.data.data.about_me,
                                    age: response.data.data.age,
                                    country_name: response.data.data.country_name,
                                    gender: response.data.data.gender,
                                    height: response.data.data.height,
                                    height_unit: response.data.data.height_unit,
                                    images: response.data.data.images,
                                    like_status: response.data.data.like_status,
                                    locked_status: response.data.data.locked_status,
                                    mother_tongue: response.data.data.mother_tongue,
                                    name: response.data.data.name,
                                    display_name: response.data.data.display_name,
                                    nationality_name: response.data.data.nationality_name.length>1? response.data.data.nationality_name[0]+','+response.data.data.nationality_name[1]:response.data.data.nationality_name[0],
                                    occupation_name: response.data.data.occupation_name,
                                    religion_name: response.data.data.religion_name,
                                    report_user_list_status: response.data.data.report_user_list_status,
                                    request_list_id: response.data.data.report_list_id,
                                    request_status: response.data.data.request_status,
                                    selfie_status: response.data.data.selfie_status,
                                    shortlist_status: response.data.data.shortlist_status,
                                    state_name: response.data.data.state_name,
                                    study_name: response.data.data.study_name,
                                    unique_id: response.data.data.unique_id,
                                    user_id: response.data.data.user_id,
                                    weight: response.data.data.weight,
                                    weight_unit: response.data.data.weight_unit,
                                    photos_slider_status: response.data.data.photos_slider_status,
                                    drink: response.data.data.drink,
                                    smoke: response.data.data.smoke,
                                    diet: response.data.data.diet,
                                })
                                // if (response.data.data.locked_status=='1'&& local_storage.subscription_status=='1'){
                                //     setDisplayProfile(true)
                                // }
                                // if(response.data.data.locked_status=='1'&&local_storage.subscription_status=='0'){
                                //     setDisplayProfile(false)
                                // }
                                // if(response.data.data.locked_status=='0'&&local_storage.subscription_status=='0'){
                                //     setDisplayProfile(true)
                                // } if(response.data.data.locked_status=='0'&&local_storage.subscription_status=='1'){
                                //     setDisplayProfile(true)
                                // }
                                //makePagenotTransparent();
                            }, 1000);
                        }
                    }).catch((error) => {
                        console.log(error);
                        //makePagenotTransparent();
                    })
                }
                
                const proFormData = new FormData();
                proFormData.append('user_id', local_storage.user_id);
                let profile_list = []
                path = res.data.data.length
                if (res.data.data.length >= 1) {
                    if (res.data.data.length >= 1) {
                        for (let d in res.data.data) {
                            request_id.push(res.data.data[d])

                        }
                        // console.log(request_id)
                    }
                    setTimeout(() => {
                        setValue({ 'path': res.data.path, 'requests': request_id });
                        makePagenotTransparent();
                    }, 2000);
                }
                //setValue({'name':res.data.data[0].name,'country':res.data.data[0].country_name,'state':res.data.data[0].state_name,'image':`${res.data.path}/${res.data.data[0].image}`,})

            }
        }).catch((error) => {
            console.log(error);
            makePagenotTransparent();
        })
        const dregFormData = new FormData();
        dregFormData.append('user_id', local_storage.user_id);
        dregFormData.append('min', 0)
        dregFormData.append('max', 10)
        dregFormData.append('type', 2);
        //console.log(lat)
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/sentrequests`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: dregFormData

        }).then((res) => {
            const UserData = res.data.status
            setUsersData1(UserData);
            //console.log(res)
            if (res.data.status === 1) {
                // console.log(res.data.path)
                const dproFormData = new FormData();
                dproFormData.append('user_id', local_storage.user_id);
                let dpro_list = []
                path = res.data.data.length
                if (res.data.data.length >= 1) {
                    request_id = []
                    if (res.data.data.length >= 1) {
                        for (let d in res.data.data) {
                            request_id.push(res.data.data[d])
                        }
                        // console.log(request_id)
                    }
                    // for (let d in res.data.data) {
                    //     dproFormData.append('profile_id', res.data.data[d].user_id)
                    //     axios({
                    //         method: 'POST',
                    //         url: `${baseUrl}/Profiles/profile_view`,
                    //         headers: {
                    //             "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                    //             'X-api-key': local_storage.api_key,
                    //         },
                    //         data: dproFormData

                    //     }).then((response) => {

                    //         if (response.data.status === 1) {
                    //             dpro_list.push(response.data.data)

                    //             path = response.data.path;
                    //             console.log(path)
                    //         }
                    //     }).catch((error) => {
                    //         console.log(error)
                    //     })
                    // }
                    setTimeout(() => {
                        setValues({ 'requests': request_id, 'path': res.data.path })
                    }, 1000);

                }
                //setValue({'name':res.data.data[0].name,'country':res.data.data[0].country_name,'state':res.data.data[0].state_name,'image':`${res.data.path}/${res.data.data[0].image}`,})

            }
        }).catch((error) => {
            console.log(error)
        })
    }, [])
    const handleskip = event => {
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        // console.log(new_id)

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/skip`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                list_items = list_items.filter((item) => item.key !== new_id)
                //  console.log(list_items)
                document.getElementById(`${new_id}`).classList.add("display")
                document.getElementById(`item-${new_id}`).classList.add("display2")
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    const handleinterest = event => {
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        // console.log(new_id)

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/send_request`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                list_items = list_items.filter((item) => item.key !== new_id)
                // console.log(list_items)
                document.getElementById(`${new_id}`).classList.add("display")
                document.getElementById(`item-${new_id}`).classList.add("display2")
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    const handlelike = event => {
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        // console.log(new_id)

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/user_likes`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                if (res.data.message === "Profile Liked") {

                    setdisplay({
                        about_me: display_list.about_me, age: display_list.age, country_name: display_list.country_name, gender: display_list.gender,
                        height: display_list.height,
                        height_unit: display_list.height_unit,
                        images: display_list.images,
                        like_status: '1',
                        locked_status: display_list.locked_status,
                        mother_tongue: display_list.mother_tongue,
                        name: display_list.name,
                        display_name: display_list.display_name,
                        nationality_name: display_list.nationality_name,
                        occupation_name: display_list.occupation_name,
                        religion_name: display_list.religion_name,
                        report_user_list_status: display_list.report_user_list_status,
                        request_list_id: display_list.request_list_id,
                        request_status: display_list.request_status,
                        selfie_status: display_list.selfie_status,
                        shortlist_status: display_list.shortlist_status,
                        state_name: display_list.state_name,
                        study_name: display_list.study_name,
                        unique_id: display_list.unique_id,
                        user_id: display_list.user_id,
                        weight: display_list.weight,
                        weight_unit: display_list.weight_unit,
                        photos_slider_status: display_list.photos_slider_status,
                        smoke: display_list.smoke,
                        drink: display_list.drink,
                        diet: display_list.diet
                    })
                    Toastify({
                        text: res.data.message,
                        duration: 2000,
                        newWindow: true,
                        close: true,
                        gravity: "top", // `top` or `bottom`
                        position: "right", // `left`, `center` or `right`
                        stopOnFocus: true, // Prevents dismissing of toast on hover
                        style: {
                            background: "linear-gradient(to right, #00b09b, #96c93d)",
                        },
                        onClick: function () { } // Callback after click
                    }).showToast();
                } else {

                    setdisplay({
                        about_me: display_list.about_me, age: display_list.age, country_name: display_list.country_name, gender: display_list.gender,
                        height: display_list.height,
                        height_unit: display_list.height_unit,
                        images: display_list.images,
                        like_status: '0',
                        locked_status: display_list.locked_status,
                        mother_tongue: display_list.mother_tongue,
                        name: display_list.name,
                        display_name: display_list.display_name,
                        nationality_name: display_list.nationality_name,
                        occupation_name: display_list.occupation_name,
                        religion_name: display_list.religion_name,
                        report_user_list_status: display_list.report_user_list_status,
                        request_list_id: display_list.request_list_id,
                        request_status: display_list.request_status,
                        selfie_status: display_list.selfie_status,
                        shortlist_status: display_list.shortlist_status,
                        state_name: display_list.state_name,
                        study_name: display_list.study_name,
                        unique_id: display_list.unique_id,
                        user_id: display_list.user_id,
                        weight: display_list.weight,
                        weight_unit: display_list.weight_unit,
                        photos_slider_status: display_list.photos_slider_status,
                        smoke: display_list.smoke,
                        drink: display_list.drink,
                        diet: display_list.diet
                    })
                    Toastify({
                        text: res.data.message,
                        duration: 2000,
                        newWindow: true,
                        close: true,
                        gravity: "top", // `top` or `bottom`
                        position: "right", // `left`, `center` or `right`
                        stopOnFocus: true, // Prevents dismissing of toast on hover
                        style: {
                            background: "linear-gradient(to right, #00b09b, #96c93d)",
                        },
                        onClick: function () { } // Callback after click
                    }).showToast();
                }
            }
        }).catch((error) => {
            console.log(error)
        })
        // if(display_list.like_status=='0'){
        //     setdisplay({like_status:'1'})

        // }else{
        //     setdisplay({like_status:'0'})
        // }
    }
    // console.log(display_list.like_status)
    const handlesshortlist = event => {
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        // console.log(new_id)

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/shortlist`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                list_items = list_items.filter((item) => item.key !== new_id)
                //  console.log(list_items)
                if (res.data.message == "Profile Shortlisted") {
                    setdisplay({
                        about_me: display_list.about_me, age: display_list.age, country_name: display_list.country_name, gender: display_list.gender,
                        height: display_list.height,
                        height_unit: display_list.height_unit,
                        images: display_list.images,
                        like_status: display_list.like_status,
                        locked_status: display_list.locked_status,
                        mother_tongue: display_list.mother_tongue,
                        name: display_list.name,
                        display_name: display_list.display_name,
                        nationality_name: display_list.nationality_name,
                        occupation_name: display_list.occupation_name,
                        religion_name: display_list.religion_name,
                        report_user_list_status: display_list.report_user_list_status,
                        request_list_id: display_list.request_list_id,
                        request_status: display_list.request_status,
                        selfie_status: display_list.selfie_status,
                        shortlist_status: '1',
                        state_name: display_list.state_name,
                        study_name: display_list.study_name,
                        unique_id: display_list.unique_id,
                        user_id: display_list.user_id,
                        weight: display_list.weight,
                        weight_unit: display_list.weight_unit,
                        photos_slider_status: display_list.photos_slider_status,
                        smoke: display_list.smoke,
                        drink: display_list.drink,
                        diet: display_list.diet
                    })
                    Toastify({
                        text: res.data.message,
                        duration: 2000,
                        newWindow: true,
                        close: true,
                        gravity: "top", // `top` or `bottom`
                        position: "right", // `left`, `center` or `right`
                        stopOnFocus: true, // Prevents dismissing of toast on hover
                        style: {
                            background: "linear-gradient(to right, #00b09b, #96c93d)",
                        },
                        onClick: function () { } // Callback after click
                    }).showToast();

                } else {
                    setdisplay({
                        about_me: display_list.about_me, age: display_list.age, country_name: display_list.country_name, gender: display_list.gender,
                        height: display_list.height,
                        height_unit: display_list.height_unit,
                        images: display_list.images,
                        like_status: display_list.like_status,
                        locked_status: display_list.locked_status,
                        mother_tongue: display_list.mother_tongue,
                        name: display_list.name,
                        display_name: display_list.display_name,
                        nationality_name: display_list.nationality_name,
                        occupation_name: display_list.occupation_name,
                        religion_name: display_list.religion_name,
                        report_user_list_status: display_list.report_user_list_status,
                        request_list_id: display_list.request_list_id,
                        request_status: display_list.request_status,
                        selfie_status: display_list.selfie_status,
                        shortlist_status: '0',
                        state_name: display_list.state_name,
                        study_name: display_list.study_name,
                        unique_id: display_list.unique_id,
                        user_id: display_list.user_id,
                        weight: display_list.weight,
                        weight_unit: display_list.weight_unit,
                        photos_slider_status: display_list.photos_slider_status,
                        smoke: display_list.smoke,
                        drink: display_list.drink,
                        diet: display_list.diet
                    })
                    Toastify({
                        text: res.data.message,
                        duration: 2000,
                        newWindow: true,
                        close: true,
                        gravity: "top", // `top` or `bottom`
                        position: "right", // `left`, `center` or `right`
                        stopOnFocus: true, // Prevents dismissing of toast on hover
                        style: {
                            background: "linear-gradient(to right, #00b09b, #96c93d)",
                        },
                        onClick: function () { } // Callback after click
                    }).showToast();
                }
                //document.getElementById(`item-${new_id}`).classList.add("display2")
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    const handledeleterequest = event => {
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        //  console.log(new_id)
        let uid;
        for (let d in value.requests) {
            if (value.requests[d].user_id == new_id) {
                uid = value.requests[d].request_list_id
            }
        }
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('request_list_id', uid)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/delete_request`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                let r_items = value.requests.filter((item) => item.user_id !== new_id)
                // let l_items = value.items.filter((item) => item.user_id !== new_id)
                //console.log(l_items)
                setValue({ 'items': '', 'path': value.path, 'requests': r_items })
                //setValue({'items':list_items,'path':value.path,'requests':value.requests})
                // for(let d in value.requests){
                //     if(value.requests[d].request_list_id==new_id){
                //         //document.getElementById(`id-${value.requests[d].user_id}`).classList.add("display2")
                //         //document.getElementById(`item-${value.requests[d].user_id}`).classList.remove("display1")
                //         //console.log(`${value.requests[d].user_id}`)
                //         setdisplay({user_id:''})
                //     }
                // }
                setdisplay({ user_id: '' })
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    // console.log(value)
    //const [displayProfile, setDisplayProfile] = useState(false)
    const cardDisplay = event => {
        makePageTransparent();
        setcloseaction('true');
        window.scrollTo({ top: 0, behavior: 'smooth' });
        let id = event.currentTarget.id
        //  console.log(id)
        const proFormData = new FormData();
        proFormData.append('user_id', local_storage.user_id);
        proFormData.append('profile_id', id)
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/profile_view`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: proFormData
        }).then((response) => {
            if (response.data.status === 1) {
                //  console.log(response.data.data.about_me)
                setTimeout(() => {
                    setdisplay({
                        about_me: response.data.data.about_me,
                        age: response.data.data.age,
                        country_name: response.data.data.country_name,
                        gender: response.data.data.gender,
                        height: response.data.data.height,
                        height_unit: response.data.data.height_unit,
                        images: response.data.data.images,
                        like_status: response.data.data.like_status,
                        locked_status: response.data.data.locked_status,
                        mother_tongue: response.data.data.mother_tongue,
                        name: response.data.data.name,
                        display_name: response.data.data.display_name,
                        nationality_name: response.data.data.nationality_name.length>1? response.data.data.nationality_name[0]+','+response.data.data.nationality_name[1]:response.data.data.nationality_name[0],
                        occupation_name: response.data.data.occupation_name,
                        religion_name: response.data.data.religion_name,
                        report_user_list_status: response.data.data.report_user_list_status,
                        request_list_id: response.data.data.report_list_id,
                        request_status: response.data.data.request_status,
                        selfie_status: response.data.data.selfie_status,
                        shortlist_status: response.data.data.shortlist_status,
                        state_name: response.data.data.state_name,
                        study_name: response.data.data.study_name,
                        unique_id: response.data.data.unique_id,
                        user_id: response.data.data.user_id,
                        weight: response.data.data.weight,
                        weight_unit: response.data.data.weight_unit,
                        photos_slider_status: response.data.data.photos_slider_status,
                        drink: response.data.data.drink,
                        smoke: response.data.data.smoke,
                        diet: response.data.data.diet,
                    })
                    // if (response.data.data.locked_status=='1'&& local_storage.subscription_status=='1'){
                    //     setDisplayProfile(true)
                    // }
                    // if(response.data.data.locked_status=='1'&&local_storage.subscription_status=='0'){
                    //     setDisplayProfile(false)
                    // }
                    // if(response.data.data.locked_status=='0'&&local_storage.subscription_status=='0'){
                    //     setDisplayProfile(true)
                    // } if(response.data.data.locked_status=='0'&&local_storage.subscription_status=='1'){
                    //     setDisplayProfile(true)
                    // }
                    makePagenotTransparent();
                }, 1000);
            }
        }).catch((error) => {
            console.log(error);
            makePagenotTransparent();
        })
        // for (let i in value.items){
        //     if(response.data.data.user_id==id){
        //        setTimeout(() => {
        //         setdisplay({about_me: response.data.data.about_me,
        //              age: response.data.data.age,
        //              country_name: response.data.data.country_name,
        //              gender: response.data.data.gender,
        //         height: response.data.data.height,
        //         height_unit: response.data.data.height_unit,
        //         images: response.data.data.images,
        //         like_status: response.data.data.like_status,
        //         locked_status: response.data.data.locked_status,
        //         mother_tongue: response.data.data.mother_tongue,
        //         name: response.data.data.name,
        //         nationality_name: response.data.data.nationality_name,
        //         occupation_name: response.data.data.occupation_name,
        //         religion_name: response.data.data.religion_name,
        //         report_user_list_status: response.data.data.report_user_list_status,
        //         request_list_id: response.data.data.report_list_id,
        //         request_status: response.data.data.request_status,
        //         selfie_status: response.data.data.selfie_status,
        //         shortlist_status: response.data.data.shortlist_status,
        //         state_name: response.data.data.state_name,
        //         study_name: response.data.data.study_name,
        //         unique_id: response.data.data.unique_id,
        //         user_id: response.data.data.user_id,
        //         weight: response.data.data.weight,
        //         weight_unit: response.data.data.weight_unit})
        //     }, 2000);
        //     }
        // }
        //console.log(display_list)
        // let itemid='item-'+id
        // console.log(itemid)
        // document.getElementById(itemid).classList.add('display1')
    }
    // console.log(value.items.length)

    var list_items = value.requests.map((item) =>
        <div className="card-button mb-3" key={item.user_id} id={`id-${item.user_id}`} style={{ outline: 'none' }}>
            <Card className="request_card" style={display_list.user_id == item.user_id ? { backgroundColor: bgcolor } : { backgroundColor: '#fff' }}>
                <button onClick={cardDisplay} id={`${item.user_id}`} style={{ outline: 'none', width: '70%' }} className="d-flex card-button">
                    <CardHeader>
                        <img src={`${item.selfie_status == '1' && item.photos_slider_status == '1' ? value.path + '/' + item.image : item.gender == '1' ? male : female}`} style={{ width: '80px', height: '80px', borderRadius: '50px', backgroundColor: '#50555c', objectFit: 'contain' }} />
                    </CardHeader>
                    <Card.Body>
                        <Card.Text className="loc_request">
                            <h6 className="profile_name">{item.name}, {item.age}</h6><br></br>
                            <p className="user_location"><img src={map} className="px-1" />{item.state_name != '' ? item.state_name + ',' : ''} {item.country_name}.</p>
                        </Card.Text>
                    </Card.Body>
                </button>
                <Card.Footer>
                <OverlayTrigger overlay={<Tooltip>{"Delete"}</Tooltip>}>
                    <button className="action-buttons m-2" id={`skip-${item.user_id}`} onClick={handledeleterequest}><img src={Delete} className="mx-2" /></button>
                </OverlayTrigger>
                </Card.Footer>
            </Card>
        </div>)
    var dlist_items = values.requests.map((item) =>

        <button className="card-button" key={item.user_id} onClick={cardDisplay} id={`${item.user_id}`} style={{ outline: 'none' }}>

            <Card className="shortlist_card mb-3" style={display_list.user_id == item.user_id ? { backgroundColor: bgcolor } : { backgroundColor: '#fff' }}>
                <CardHeader>
                    <img src={`${item.selfie_status == '1' && item.photos_slider_status == '1' ? values.path + '/' + item.image : item.gender == '1' ? male : female}`} style={{ width: '80px', height: '80px', borderRadius: '50px', backgroundColor: '#c4c4c49e' }} />
                </CardHeader>
                <Card.Body>

                    <Card.Text className="loc_request">
                        <h6 className="profile_name">{item.name}, {item.age}</h6><br></br>
                        <p className="user_location"><img src={map} className="px-1" />{item.state_name != '' ? item.state_name + ',' : ''}  {item.country_name}.</p>
                    </Card.Text>
                </Card.Body>
            </Card>
        </button>)
    const setab = () => {
        //alert("hello")
        setdisplay({
            about_me: "", age: "", country_name: "", gender: "",
            height: "",
            height_unit: "",
            images: [{ 'image': '' }],
            like_status: "",
            locked_status: "",
            mother_tongue: "",
            name: "",
            nationality_name: "",
            occupation_name: "",
            religion_name: "",
            report_user_list_status: "",
            request_list_id: "",
            request_status: "",
            selfie_status: "",
            shortlist_status: "",
            state_name: "",
            study_name: null,
            unique_id: "",
            user_id: "",
            weight: "",
            weight_unit: ""
        })
    }
    const [shareprofile, setshare] = useState(false)
    const handleopenshare = () => {
        urlShortner()
        setTimeout(() => {
            setshare(true)
        }, 1000);

    }
    const handlecloseshare = () => {
        setshare(false)
    }
    const [shorturl, setshorturl] = useState('')
    const urlShortner = async () => {
        let longurl = `https://bu3is.krify.com/matchmade4u_web/#/profile/${local_storage.user_id}/${display_list.user_id}`
        setshorturl(longurl)
    }
    //console.log(shorturl)
    const handleClose = (event) =>{
        event.preventDefault(); // Prevent the default link behavior (e.g., navigating to a new page)
        
      }
      const [linkToCopy, setLinkToCopy] = useState('');
      useEffect(() => {
          // Assuming display_list.user_id becomes available asynchronously
          if (display_list && display_list.user_id) {
              const newLink = `https://bu3is.krify.com/matchmade4u_web/#/profile/${local_storage.user_id}/${display_list.user_id}`;
              setLinkToCopy(newLink);
          }
      }, [display_list]);
      const copyLink = () => {
          clipboardCopy(linkToCopy)
              .then(() => {
                  Toastify({
                      text: "Link copied",
                      duration: 2000,
                      newWindow: true,
                      close: true,
                      gravity: "top", // `top` or `bottom`
                      position: "right", // `left`, `center` or `right`
                      stopOnFocus: true, // Prevents dismissing of toast on hover
                      style: {
                          background: "linear-gradient(to right, #00b09b, #96c93d)",
                      },
                      onClick: function () { } // Callback after click
                  }).showToast();
                  // alert('Link copied to clipboard');
              })
              .catch((error) => {
                  console.error('Error copying link:', error);
              });
      };
    return (
        <div className="d-flex">
            {shareprofile && shorturl != '' &&
                <div className="modal modal_blur" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header p-0 ">
                                <h5></h5>
                                <h5 className="modal-title">Share Profile</h5>
                                <button type="button" className="close share_close" onClick={handlecloseshare}>
                                    <span aria-hidden="true">&times;</span>
                                    
                                </button>
                               
                            </div>
                            <div className="text-center mb-3 mt-3">
                              
                                <WhatsappShareButton url={shorturl}>
                                    <WhatsappIcon size={32} round={true} className="mr-3"></WhatsappIcon>
                                </WhatsappShareButton>&nbsp;
                                <FacebookShareButton url={`${baseUrl}/profile/${local_storage.user_id}/${display_list.user_id}`}>
                                    <FacebookIcon size={32} round={true} ></FacebookIcon>
                                </FacebookShareButton>&nbsp;
                                <OverlayTrigger overlay={<Tooltip>{"Copy link"}</Tooltip>}>
                                    <i className="fa fa-copy" onClick={copyLink} ></i>
                                </OverlayTrigger>
                            </div>

                        </div>
                    </div>
                </div>

            }
            <div className="col-3 page_left" style={{ width: 'auto' }}>
                <Sidemenu />
            </div>

            <div className="col-9 d-flex flex-column chat-page page_right">

                <div className="d-flex flex-row bg-white top-bar shadow">
                    <p style={{ fontWeight: 500 }}>Requested</p>

                    <div className="menu-nav">
                        <div className="dropdown-container" tabindex="-1">
                            <div className="three-dots display_matches" id="three-dots"></div>
                            <div className="dropdown">
                            <a onClick={handleopenshare}><div>Share this profile</div></a>
                            <a href="#" onClick={handleClose}><div className="cancel">Close</div></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex p-0 m-0">

                    <div className="user_info col-7 mt-4 mb-3 px-5">

                        {/* <ul className="d-flex flex-column">{list_items}</ul> */}
                        <Tabs onSelect={setab}>
                            <Tab label="Sent Request">
                                <div className="mt-3">
                                    {list_items}
                                </div>
                                {usersData == '0' ? <div class="d-flex justify-content-center align-items-center mt-5" ><img src={NoData} /></div> : ''}
                            </Tab>
                            <Tab label="Declined">
                                <div className="mt-3">
                                    {dlist_items}
                                </div>
                                {usersData1 == '0' ? <div class="d-flex justify-content-center align-items-center mt-5" ><img src={NoData} /></div> : ''}
                            </Tab>
                        </Tabs>

                    </div>

                    <div className="col-5">
                        {display_list.user_id !== '' ?
                            <div className="" style={{ right: '0', position: 'fixed' }} id={`item-${display_list.user_id}`}>
                                {closeaction == 'true' ?
                                    <Card className="right-card" id="right-card" style={{ width: '401px', height: 'calc(100vh - 73px)', overflowY: "auto", overflowX: "hidden" }}>
                                        {display_list.images.length > 1 
                                            ?
                                            <div style={{ backgroundColor: '#c4c4c4' }}>
                                                <span style={{ visibility: 'hidden' }}> aa </span>
                                                <span className="close_button float-end px-2" onClick={close} >
                                                    <img src={deleteicon} class="close-button" alt="" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                                                </span>
                                                <div>
                                                    <Carousel>
                                                        {display_list.images.map((particular_image, imageindex) =>
                                                            <Carousel.Item key={imageindex} style={{ width: '400px', height: '300px', overflow: 'hidden', borderRadius: '0px', backgroundColor: '#c4c4c4' }}>
                                                                <Card.Img variant="top" src={`${display_list.selfie_status == '1'  ? value.path + '/' + display_list.images[imageindex].image : display_list.gender == '1' ? male1 : female1}`} style={{ width: '100%', height: '100%', objectFit: 'contain', top: '0' }} />
                                                            </Carousel.Item>
                                                        )}
                                                    </Carousel>
                                                </div>
                                            </div>
                                            :
                                            <div style={{ color: "red", backgroundColor: '#c4c4c4' }}>
                                                <span className="close_button float-end px-2" onClick={close} >
                                                    <img src={deleteicon} class="close-button" alt="" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                                                </span>
                                                <Card.Img variant="top" src={`${display_list.selfie_status == '1' && display_list.images.length == '1' ? value.path + '/' + display_list.images[0].image : display_list.gender == '1' ? male1 : female1}`} style={{ width: '400px', height: '300px', top: '0', backgroundColor: '#c4c4c4', objectFit: 'contain' }} />
                                            </div>
                                        }
                                        <Card.Body>
                                            <Card.Title><p style={{ fontWeight: 500 }}>{display_list.display_name}, {display_list.age}, {display_list.gender == 1 ? "Male" : "Female"} <span style={{ color: 'red' }}>(#{display_list.unique_id})</span></p>
                                                <p><img src={map} className="px-1" />{display_list.state_name == '' ? '' : display_list.state_name + ','} {display_list.country_name}.</p>
                                                {display_list.request_status == '4' ? <span style={{ color: 'red' }}>{display_list.display_name} sent you a request</span> : ''}

                                                <p style={{ fontWeight: 500 }} className="mt-2">About me</p>
                                                <p>{display_list.about_me}</p>
                                            </Card.Title>

                                            <Card.Text>
                                            <div className="profile_data">
                                                <div><span>Languages</span></div>
                                                <div><span>{display_list.mother_tongue}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Nationality</span></div>
                                                <div><span>{display_list.nationality_name}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Country</span></div>
                                                <div><span>{display_list.country_name}</span></div>
                                            </div>
                                            {display_list.state_name != '' ? <div className="profile_data">
                                                <div><span>State</span></div>
                                                <div><span>{display_list.state_name}</span></div>
                                            </div> : ''}
                                            <div className="profile_data">
                                                <div><span>Occupation</span></div>
                                                <div><span>{display_list.occupation_name}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Height</span></div>
                                                <div><span>{display_list.height} {display_list.height_unit}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Weight</span></div>
                                                <div><span>{display_list.weight}</span></div>
                                            </div>
                                            {display_list.study_name &&
                                            <div className="profile_data">
                                                <div><span>Education</span></div>
                                                <div><span>{display_list.study_name}</span></div>
                                            </div>}
                                            <div className="profile_data">
                                                <div><span>Religion</span></div>
                                                <div><span>{display_list.religion_name}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Smoke</span></div>
                                                <div><span>{display_list.smoke}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Drink</span></div>
                                                <div><span>{display_list.drink}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Diet</span></div>
                                                <div><span>{display_list.diet}</span></div>
                                            </div>
                                                <hr className="m-0 p-0" style={{ color: "#ff0000", backgroundColor: "#ff0000" }}>
                                                </hr>
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="m-auto">
                                            <OverlayTrigger overlay={<Tooltip>{"Interest"}</Tooltip>}>
                                                <button className="action-buttons" id={`dinterest-${display_list.user_id}`} onClick={handledeleterequest}><img src={interest} className="mx-2" /></button>
                                            </OverlayTrigger>

                                            <OverlayTrigger overlay={<Tooltip>{"Like"}</Tooltip>}>
                                                <button className="action-buttons" id={`dlike-${display_list.user_id}`} onClick={handlelike}><img id={`dlikes-${display_list.user_id}`} src={display_list.like_status == '0' ? like : liked} className="mx-2" /></button>
                                            </OverlayTrigger>

                                            <OverlayTrigger overlay={<Tooltip>{"Shortlist"}</Tooltip>}>
                                                <button className="action-buttons" id={`dshortlist-${display_list.user_id}`} onClick={handlesshortlist}><img src={display_list.shortlist_status == '0' ? shortlist : shortlisted} className="mx-2" /></button>
                                            </OverlayTrigger>
                                        </Card.Footer>
                                    </Card> : ""}
                            </div> : ''}
                    </div>
                </div>
            </div>
            <div id="overlay">
                <div className='AddloadingImage'>
                    <LottieLoader3 />
                </div>
            </div>
        </div>
    )
}

export default Requests;