import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, Carousel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import like from '../../assets/images/outlike.png';
import info from '../../assets/images/info.png';
import liked from '../../assets/images/outliked.png';
import shortlist from '../../assets/images/outshortlist.png';
import shortlist1 from '../../assets/images/outshortlist1.png';
import interest from '../../assets/images/outinterest.png';
import skip from '../../assets/images/outskip.png';
import 'web-social-share';
import { useForm } from "react-hook-form";
import male from '../../assets/images/male.png';
import female from '../../assets/images/female.png';
import male1 from '../../assets/images/men_s.svg';
import female1 from '../../assets/images/women_s.svg';
import map from '../../assets/images/location.png';
import LottieLoader1 from '../lottie/findprofiles';
import LottieLoader3 from '../lottie/lottieloader3';
import deleteicon from '../../assets/images/delete-icon.jpg';
import Sidemenu from "../sidebar/sidemenu";
import Toastify from 'toastify-js';

const ProfilePage = () => {
  const { userId,proId } = useParams();
  //const [display_list,setdisplay] = useState({});
  const local_storage = JSON.parse(localStorage.getItem('userDetails'));
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const [value, setValue] = useState({ 'profiles': [], 'path': '' })
  const [display_list, setdisplay] = useState({
      about_me: "", age: "", country_name: "", gender: "",
      height: "",
      height_unit: "",
      images: [{ 'image': '' }],
      like_status: "",
      locked_status: "",
      mother_tongue: "",
      name: "",
      nationality_name: "",
      occupation_name: "",
      religion_name: "",
      report_user_list_status: "",
      request_list_id: "",
      request_status: "",
      selfie_status: "",
      shortlist_status: "",
      state_name: "",
      study_name: null,
      unique_id: "",
      user_id: "",
      weight: "",
      weight_unit: "",
      photo_slider_status: "",
      display_name: ""
  })
  const shareOptions = {
    text: `Check out this profile: `,
    url: `${baseUrl}/profile/${userId}`,
  };
  console.log(local_storage)
  useEffect(async() => {
    //alert('test')
    //let url ='https://docs.google.com/spreadsheets/d/1hupzkou_AGlqyjkOTEJkHa8QN17gZ-vcC7i3LTAu3aM/edit#gid=0'
    
    // Fetch profile data using userId (you need to implement this)
    const proFormData = new FormData();
        proFormData.append('user_id', userId);
        proFormData.append('profile_id', proId)
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/profile_view`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: proFormData
        }).then((response) => {
            console.log(response.data.data);
           setdisplay(response.data.data)
        }).catch((error) => {
            console.log(error);
            //setDisable(false);
            //makePagenotTransparent();

        })
    
  }, [userId]);
  const handleinterest = event => {
    let id = event.currentTarget.id.split('-')
    let new_id = id[1]
    // console.log(new_id)

    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id)
    regFormData.append('profile_id', new_id)

    axios({
        method: 'POST',
        url: `${baseUrl}/Profiles/send_request`,
        headers: {
            "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
            'X-api-key': local_storage.api_key,
        },
        data: regFormData
    }).then((res) => {
        if (res.data.status === 1) {
            let l_items = value.profiles.filter((item) => item.user_id !== new_id)
            // console.log(l_items)
            setValue({ 'profiles': l_items, 'path': value.path })
            setdisplay({ user_id: '' })

        }
        Toastify({
            text: res.data.message,
            duration: 2000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
                background: "linear-gradient(to right, #00b09b, #96c93d)",
            },
            onClick: function () { } // Callback after click
        }).showToast();

    }).catch((error) => {
        console.log(error)
    })

}
const handlelike = event => {
    let id = event.currentTarget.id.split('-')
    let new_id = id[1]
    // console.log(new_id)

    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id)
    regFormData.append('profile_id', new_id)

    axios({
        method: 'POST',
        url: `${baseUrl}/Profiles/user_likes`,
        headers: {
            "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
            'X-api-key': local_storage.api_key,
        },
        data: regFormData
    }).then((res) => {
        if (res.data.status === 1) {
            if (res.data.message === "Profile liked") {
                document.getElementById(`likes-${new_id}`).src = `${liked}`
                setdisplay({
                    about_me: display_list.about_me,
                    age: display_list.age,
                    country_name: display_list.country_name,
                    gender: display_list.gender,
                    height: display_list.height,
                    height_unit: display_list.height_unit,
                    images: display_list.images,
                    like_status: '1',
                    locked_status: display_list.locked_status,
                    mother_tongue: display_list.mother_tongue,
                    name: display_list.name,
                    display_name: display_list.display_name,
                    nationality_name: display_list.nationality_name,
                    occupation_name: display_list.occupation_name,
                    religion_name: display_list.religion_name,
                    report_user_list_status: display_list.report_user_list_status,
                    request_list_id: display_list.request_list_id,
                    request_status: display_list.request_status,
                    selfie_status: display_list.selfie_status,
                    shortlist_status: display_list.shortlist_status,
                    state_name: display_list.state_name,
                    study_name: display_list.study_name,
                    unique_id: display_list.unique_id,
                    user_id: display_list.user_id,
                    weight: display_list.weight,
                    weight_unit: display_list.weight_unit,
                    photos_slider_status: display_list.photos_slider_status
                })

            } else {
                document.getElementById(`likes-${new_id}`).src = `${like}`
                setdisplay({
                    about_me: display_list.about_me, age: display_list.age, country_name: display_list.country_name, gender: display_list.gender,
                    height: display_list.height,
                    height_unit: display_list.height_unit,
                    images: display_list.images,
                    like_status: '0',
                    locked_status: display_list.locked_status,
                    mother_tongue: display_list.mother_tongue,
                    name: display_list.name,
                    display_name: display_list.display_name,
                    nationality_name: display_list.nationality_name,
                    occupation_name: display_list.occupation_name,
                    religion_name: display_list.religion_name,
                    report_user_list_status: display_list.report_user_list_status,
                    request_list_id: display_list.request_list_id,
                    request_status: display_list.request_status,
                    selfie_status: display_list.selfie_status,
                    shortlist_status: display_list.shortlist_status,
                    state_name: display_list.state_name,
                    study_name: display_list.study_name,
                    unique_id: display_list.unique_id,
                    user_id: display_list.user_id,
                    weight: display_list.weight,
                    weight_unit: display_list.weight_unit,
                    photos_slider_status: display_list.photos_slider_status
                })

            }
            Toastify({
                text: res.data.message,
                duration: 2000,
                newWindow: true,
                close: true,
                gravity: "top", // `top` or `bottom`
                position: "right", // `left`, `center` or `right`
                stopOnFocus: true, // Prevents dismissing of toast on hover
                style: {
                    background: "linear-gradient(to right, #00b09b, #96c93d)",
                },
                onClick: function () { } // Callback after click
            }).showToast();
        }

    }).catch((error) => {
        console.log(error)
    })

}
const handlesshortlist = event => {
    let id = event.currentTarget.id.split('-')
    let new_id = id[1]
    // console.log(new_id)

    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id)
    regFormData.append('profile_id', new_id)

    axios({
        method: 'POST',
        url: `${baseUrl}/Profiles/shortlist`,
        headers: {
            "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
            'X-api-key': local_storage.api_key,
        },
        data: regFormData
    }).then((res) => {
        if (res.data.status === 1) {
            let l_items = value.profiles.filter((item) => item.user_id !== new_id)
            // console.log(l_items)
            setValue({ 'profiles': l_items, 'path': value.path })
            setdisplay({ user_id: '' })
            Toastify({
                text: res.data.message,
                duration: 2000,
                newWindow: true,
                close: true,
                gravity: "top", // `top` or `bottom`
                position: "right", // `left`, `center` or `right`
                stopOnFocus: true, // Prevents dismissing of toast on hover
                style: {
                    background: "linear-gradient(to right, #00b09b, #96c93d)",
                },
                onClick: function () { } // Callback after click
            }).showToast();
        }
    }).catch((error) => {
        console.log(error)
    })

}
  return (
    <div>
      {/* Display user profile information */}
      {/* <h1>{profileData?.name}</h1>
      <p>{profileData?.about_me}</p> */}
        <div className="col-3 page_left" style={{ width: 'auto' }}>
                <Sidemenu />
            </div>
      {display_list.user_id !== '' ?
                        <div className=""  id={`item-${display_list.user_id}`}>
                         
                                <Card className="right-card" id="right-card" style={{ margin:'auto',width: '500px', overflowY: "auto", overflowX: "hidden" }}>
                                    {display_list?.images && display_list.selfie_status == 1 && display_list.photos_slider_status == 1
                                        ?
                                        <div style={{ backgroundColor: '#c4c4c4' }}>
                                            <span style={{ visibility: 'hidden' }}> aa </span>
                                            {/* <span className="close_button float-end px-2" >
                                                <img src={deleteicon} class="close-button" alt="" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                                            </span> */}
                                            <div>
                                                <Carousel>
                                                    {display_list.images.map((particular_image, imageindex) =>
                                                        <Carousel.Item key={imageindex} style={{ width: '400px', height: '300px', overflow: 'hidden', borderRadius: '0px', backgroundColor: '#c4c4c4' }}>
                                                            <Card.Img variant="top" src={`${display_list.selfie_status == '1' && display_list.photos_slider_status == '1' ? baseUrl + '/' + display_list.images[imageindex].image : display_list.gender == '1' ? male1 : female1}`} style={{ width: '100%', height: '100%', objectFit: 'contain', top: '0' }} />
                                                        </Carousel.Item>
                                                    )}
                                                </Carousel>
                                            </div>
                                        </div>
                                        :
                                        <div style={{ color: "red", backgroundColor: '#c4c4c4' }}>
                                            {/* <span className="close_button float-end px-2" >
                                                <img src={deleteicon} class="close-button" alt="" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                                            </span> */}
                                            <Card.Img variant="top" src={`${display_list.selfie_status == '1' && display_list.photos_slider_status == '1' ? baseUrl + '/' + display_list.images[0].image : display_list.gender == '1' ? male1 : female1}`} style={{ width: '500px', height: '300px', top: '0', backgroundColor: '#c4c4c4', objectFit: 'contain' }} />
                                        </div>
                                    }
                                    <Card.Body>
                                        <Card.Title><p style={{ fontWeight: 500 }}>{display_list.name}, {display_list.age}, {display_list.gender == 1 ? "Male" : "Female"} <span style={{ color: 'red' }}>(#{display_list.unique_id})</span></p>
                                            <p><img src={map} className="px-1" />{display_list.state_name == '' ? '' : display_list.state_name + ','} {display_list.country_name}.</p>
                                            {display_list.request_status == '4' ? <span style={{ color: 'red' }}>{display_list.name} sent you a request</span> : ''}

                                            <p style={{ fontWeight: 500 }} className="mt-2">About me</p>
                                            <p>{display_list.about_me}</p>
                                        </Card.Title>

                                        <Card.Text>
                                            <div className="profile_data">
                                                <div><span>Languages</span></div>
                                                <div><span>{display_list.mother_tongue}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Nationality</span></div>
                                                <div><span>{display_list.nationality_name}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Country</span></div>
                                                <div><span>{display_list.country_name}</span></div>
                                            </div>
                                            {display_list.state_name != '' ? <div className="profile_data">
                                                <div><span>State</span></div>
                                                <div><span>{display_list.state_name}</span></div>
                                            </div> : ''}
                                            <div className="profile_data">
                                                <div><span>Occupation</span></div>
                                                <div><span>{display_list.occupation_name}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Height</span></div>
                                                <div><span>{display_list.height} {display_list.height_unit}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Weight</span></div>
                                                <div><span>{display_list.weight}</span></div>
                                            </div>
                                            {display_list.study_name &&
                                            <div className="profile_data">
                                                <div><span>Education</span></div>
                                                <div><span>{display_list.study_name}</span></div>
                                            </div>}
                                            <div className="profile_data">
                                                <div><span>Religion</span></div>
                                                <div><span>{display_list.religion_name}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Smoke</span></div>
                                                <div><span>{display_list.smoke}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Drink</span></div>
                                                <div><span>{display_list.drink}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Diet</span></div>
                                                <div><span>{display_list.diet}</span></div>
                                            </div>
                                            <hr className="m-0 p-0" style={{ color: "#ff0000", backgroundColor: "#ff0000" }}></hr>
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="m-auto">
                                        <OverlayTrigger overlay={<Tooltip>{"Interest"}</Tooltip>}>
                                            <button className="action-buttons" id={`dinterest-${display_list.user_id}`} onClick={handleinterest}><img src={interest} className="mx-2" /></button>
                                        </OverlayTrigger>

                                        <OverlayTrigger overlay={<Tooltip>{"Like"}</Tooltip>}>
                                            <button className="action-buttons" id={`dlike-${display_list.user_id}`} onClick={handlelike}><img id={`dlikes-${display_list.user_id}`} src={display_list.like_status == '0' ? like : liked} className="mx-2" /></button>
                                        </OverlayTrigger>

                                        <OverlayTrigger overlay={<Tooltip>{"Shortlist"}</Tooltip>}>
                                            <button className="action-buttons" id={`dshortlist-${display_list.user_id}`} onClick={handlesshortlist}><img src={shortlist1} className="mx-2" /></button>
                                        </OverlayTrigger>

                                    </Card.Footer>
                                </Card> 
                        </div> : ''}
    </div>
  );
};

export default ProfilePage;