import React from 'react'
import { Card } from 'react-bootstrap';
import Sidemenu from "../sidebar/sidemenu";
import Fail from "../../assets/images/error-img.gif"

function Paymentfail() {
  return (
    <div className="d-flex">
    <div className="col-3 page_left" style={{ width: 'auto' }}>
      <Sidemenu />
    </div>
    <div className="col-9 d-flex flex-column payment-page page_right">
      <Card className='mt-5 col-6 pay_success' >
      <img src={Fail} style={{width:'300px'}}/>
        <h4><b>Payment Failed</b></h4>
      </Card>
    </div>
  </div>

  )
}

export default Paymentfail