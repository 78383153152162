import React, { useState, useEffect } from "react";
import icon from "../../assets/images/lock_icon.png";
import plus from "../../assets/images/img_select1.svg";
import activePlus from '../../assets/images/img_sel.svg';
import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import ImageUploading from "react-images-uploading";
import './addphotos.css'
import $ from 'jquery';
import LottieLoader from '../lottie/lottieloader';


function AddPhotos() {
    const [otherlogin, setotherlogin] = useState(false);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const Auth = process.env.REACT_APP_USER_TOKEN;
    const navigate = useNavigate();
    const location = useLocation();
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
    let [images, setImages] = useState([])
    let [positions, setPositions] = useState(1)
    const [disable, setDisable] = useState(false);
    const [selectedImage, setSelectedImage] = useState('file-ip-1-preview')
    const [disable1, setDisable1] = useState({ 'display': '' });
    const [disable2, setDisable2] = useState({ 'display': '' });
    const [disable3, setDisable3] = useState({ 'display': '' });
    const [disable4, setDisable4] = useState({ 'display': '' });
    const [disable5, setDisable5] = useState({ 'display': '' });
    const regFormData = new FormData();
    const [setPhotos, getPhotos] = useState([]);
    const [deleted_image_ids, setDeleteImage] = useState('')
    const [photosData, setphotosData] = useState('')
    const local_storage = JSON.parse(localStorage.getItem('userDetails'));
    const [imageStatus, setimageStatus] = useState([])
    //console.log(location.state)
    const onPhotosSubmit = (data) => {

        setDisable(true)
        let key;

        if (location.state !== null) {
            regFormData.append('user_id', location.state.user_id);
            key = location.state.api_key;
        } else {
            regFormData.append('user_id', local_storage.user_id)
            key = local_storage.api_key;
        }
        // regFormData.append('userprofile[]', data.userprofile);
        for (let i = 0; i < images.length; i++) {
            regFormData.append(`userprofile[]`, images[i])
        }
        let yo = selectedImage.split("-")[2]
        regFormData.append('img_pos', parseInt(yo) - 1)
        regFormData.append('sel_img_id', '')
        regFormData.append('deleted_image_ids', deleted_image_ids)

        //regFormData.append('api_key', 'cd38819260c30284a96411e1747f585a');
        axios({
            method: 'POST',
            url: `${baseUrl}/Registration/add_photos`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': key,
            },
            data: regFormData

        }).then((res) => {
            setDisable(false)

            if (res.data.status === 1) {

                setTimeout(() => {
                    navigate('/settings', { state: location.state })
                }, 3000);

                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            } else {
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            }

        }).catch((e) => {
            console.log(e.response, 'e')
            setDisable(false)
            if (e.message == 'Request failed with status code 401') {
                setotherlogin(true)
            }
        })
    }

    useEffect(() => {
        if (local_storage !== null) {
            const regFormData = new FormData();
            regFormData.append('user_id', local_storage.user_id);
            axios({
                method: 'POST',
                url: `${baseUrl}/Profiles/user_profile`,
                headers: {
                    "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                    'X-api-key': local_storage.api_key,
                },
                data: regFormData
            }).then((res) => {
                if (res.data.status === 1) {
                    if (res.data.data.selfie_status === '1') {
                        console.log(res.data)
                        setValue({ 'name': res.data.data.display_name, 'image': `${res.data.path}/${res.data.data.selfie_pic}`, 'id': `#${res.data.data.unique_id}` })
                    } else {
                        setValue({ 'name': res.data.data.display_name, 'image': `${res.data.path}/${res.data.data.selfie_pic}`, 'id': `(#${res.data.data.unique_id})` })
                    }
                }

            }).catch((e) => {
                console.log(e)
            })
        }
    }, [])
    //console.log(images, 'imagesimages')

    let count = 0;
    useEffect(() => {
        $(".progress-bar").animate({
            width: "70%"
        }, 2500);
        const getUserFormData = new FormData();
        let key;
        if (location.state !== null) {
            getUserFormData.append('user_id', location.state.user_id);
            key = location.state.api_key;
        } else {
            getUserFormData.append('user_id', local_storage.user_id)
            key = local_storage.api_key;
        }
        axios({
            method: 'POST',
            url: `${baseUrl}/Registration/get_photos`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': key,
            },
            data: getUserFormData
        }).then(async (res) => {
            //console.log(res)

            for (let i = 0; i < res.data.data.images.length; i++) {
                const imgStatus = res.data.data.images[i].img_status;
                // Push the current image status to the array of statuses
                setimageStatus(prevStatuses => [...prevStatuses, imgStatus]);
                //console.log(imageStatus)
            }
            const photoData = res.data.data.photo_status[0].photos_slider_status
            setphotosData(photoData)
            //console.log(photoData)
            if (res.data.status === 1) {
                let fetchedImagesUrl = []
                count = res.data.data.images.length;
                getPhotos(res.data.data.images)
                setImages(res.data.data.images)
                for (let i = 0; i < res.data.data.images.length; i++) {
                    let preview = document.getElementById(`file-ip-${i + 1}-preview`);
                    preview.src = res.data.data.images[i].with_base;
                    preview.style.display = "block";
                    document.getElementById('dbutton').disabled = false;
                    document.getElementById(`file-ip-${i + 2}-preview`).src = activePlus;

                    if (res.data.data.images[i].display_pic == "1") {
                        setSelectedImage(`file-ip-${i + 1}-preview`)
                    }
                    document.getElementById(`file-ip-${i + 1}`).disabled = true;
                    removefun1()
                }
            }
            else {
                getPhotos([])
                setImages([])
            }
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    const showpre = () => {
        document.getElementById(`file-ip-1-preview`).src = plus;
        document.getElementById(`file-ip-2-preview`).src = plus;
        document.getElementById(`file-ip-3-preview`).src = plus;
        document.getElementById(`file-ip-4-preview`).src = plus;
        document.getElementById(`file-ip-5-preview`).src = plus;
        document.getElementById(`file-ip-6-preview`).src = plus;
        document.getElementById(`file-ip-${images.length + 1}-preview`).src = activePlus

        for (var i = 0; i < document.getElementsByClassName("imgRemove").length; i++) {
            document.getElementsByClassName("imgRemove")[i].style.display = "none"
        }
        document.getElementById("file-ip-1").disabled = false;
        document.getElementById("file-ip-2").disabled = false;
        document.getElementById("file-ip-3").disabled = false;
        document.getElementById("file-ip-4").disabled = false;
        document.getElementById("file-ip-5").disabled = false;
        document.getElementById("file-ip-6").disabled = false;

        if (images.length == 0) {
            document.getElementById("dbutton").disabled = true;
        }

        if (images.length == 1) {
            // document.getElementById(`file-ip-1-preview`).src=setPhotos[0].with_base;
            // document.getElementById(`removeButton1`).style.display="block";
            // document.getElementById(`removeButton1`).style.left="80%";
        }
        for (let i = 1; i <= images.length; i++) {
            let hasKey = typeof images[i - 1];

            let src = "";
            if (images[i - 1].hasOwnProperty('pic_id')) {
                src = images[i - 1].with_base;
            } else {
                src = URL.createObjectURL(images[i - 1]);

            }

            let preview = document.getElementById(`file-ip-${i}-preview`);

            setTimeout(() => {
                preview.src = src;
            }, 1000)
            document.getElementById('dbutton').disabled = false;
            document.getElementById(`removeButton${i}`).style.display = "block";
            document.getElementById(`removeButton${i}`).style.left = "80%";
            document.getElementById(`file-ip-${i}`).disabled = true;
            for (var t = 0; t < document.getElementsByTagName("img").length; t++) {
                document.getElementsByTagName("img")[t].classList.remove("border")
            }
            document.getElementById(`file-ip-${i}-preview`).classList.add("border")
        }
        //console.log(setPhotos, 'setPhotos')
    }

    const showSinglePre = (id, file) => {

        let src = URL.createObjectURL(file);
        let preview = document.getElementById(`file-ip-${id}-preview`);
        setTimeout(() => {
            preview.src = src;
            preview.style.display = "block";
        }, 1000)
        if (selectedImage == `file-ip-1-preview`) {
            if (id == 1) {
                preview.classList.add("border")
            }
        }
        if (id <= 5) {
            document.getElementById(`file-ip-${parseInt(id) + 1}-preview`).src = activePlus;
        }
        document.getElementById('dbutton').disabled = false;
        document.getElementById(`removeButton${id}`).style.display = "block";
        document.getElementById(`removeButton${id}`).style.left = "80%";
    }
    /*------------------------*/
    function showPreview(e) {

        //console.log(e.target.id, 'iddd')
        document.getElementById(e.target.id).disabled = true;
        if (e.target.files.length > 0) {
            setImages([...images, e.target.files[0]])
            getPhotos([...setPhotos, e.target.files[0]])
            // setPositions([...positions, {'filename': e.target.files[0].name, 'position': e.target.name}])
            showSinglePre(e.target.name, e.target.files[0]);
        }
    }


    /*-------------------------*/

    function showPreviewOne(e) {

        if (e.target.files.length > 0) {
            setImages([...images, e.target.files[0]])
            let src = URL.createObjectURL(e.target.files[0]);
            let preview = document.getElementById("file-ip-1-preview");
            preview.src = src;
            preview.style.display = "block";
            document.getElementById('file-ip-2-preview').src = activePlus;
            document.getElementById('dbutton').disabled = false;
            document.getElementById("removeButton1").style.display = "block";
            document.getElementById("removeButton1").style.left = "80%";
            //document.getElementById("removeButton1").classList.toggle("remove-button");
        }

    }
    function removefun1() {
        if (count === 0) {
            document.getElementById('dbutton').disabled = true;
            setDisable1({ 'display': 'block' })
            setDisable2({ 'display': 'none' })
            setDisable3({ 'display': 'none' })
            setDisable4({ 'display': 'none' })
            setDisable5({ 'display': 'none' })
            document.getElementById(`removeButton1`).style.left = "80%";
        }
    }
    function removefun() {
        if (images.length === 0 || count === 1) {
            document.getElementById("file-ip-1-preview").src = activePlus;
            document.getElementById('file-ip-2-preview').src = plus;
            document.getElementById("file-ip-3-preview").src = plus;
            document.getElementById("file-ip-4-preview").src = plus;
            document.getElementById("file-ip-5-preview").src = plus;
            document.getElementById("file-ip-6-preview").src = plus;
            document.getElementById('dbutton').disabled = true;

            setDisable1({ 'display': 'block' })
            setDisable2({ 'display': 'none' })
            setDisable3({ 'display': 'none' })
            setDisable4({ 'display': 'none' })
            setDisable5({ 'display': 'none' })
            document.getElementById(`removeButton1`).style.left = "80%";
        }
        if (images.length === 1 || count === 2) {
            document.getElementById("file-ip-2-preview").src = activePlus;
            document.getElementById("file-ip-3-preview").src = plus;
            document.getElementById("file-ip-4-preview").src = plus;
            document.getElementById("file-ip-5-preview").src = plus;
            document.getElementById("file-ip-6-preview").src = plus;
            setDisable1({ 'display': 'block' })
            setDisable2({ 'display': 'block' })
            setDisable3({ 'display': 'none' })
            setDisable4({ 'display': 'none' })
            setDisable5({ 'display': 'none' })
            document.getElementById(`removeButton1`).style.left = "80%";
            document.getElementById(`removeButton2`).style.left = "80%";
        }
        if (images.length === 2) {
            document.getElementById("file-ip-3-preview").src = activePlus;
            document.getElementById("file-ip-4-preview").src = plus;
            document.getElementById("file-ip-5-preview").src = plus;
            document.getElementById("file-ip-6-preview").src = plus;
            setDisable1({ 'display': 'block' })
            setDisable2({ 'display': 'block' })
            setDisable3({ 'display': 'block' })
            setDisable4({ 'display': 'none' })
            setDisable5({ 'display': 'none' })
            document.getElementById(`removeButton1`).style.left = "80%";
            document.getElementById(`removeButton2`).style.left = "80%";
            document.getElementById(`removeButton3`).style.left = "80%";

        }
        if (images.length === 3) {
            document.getElementById("file-ip-4-preview").src = activePlus;
            document.getElementById("file-ip-5-preview").src = plus;
            document.getElementById("file-ip-6-preview").src = plus;
            setDisable1({ 'display': 'block' })
            setDisable2({ 'display': 'block' })
            setDisable3({ 'display': 'block' })
            setDisable4({ 'display': 'block' })
            setDisable5({ 'display': 'none' })
            document.getElementById(`removeButton1`).style.left = "80%";
            document.getElementById(`removeButton2`).style.left = "80%";
            document.getElementById(`removeButton3`).style.left = "80%";
            document.getElementById(`removeButton4`).style.left = "80%";

        }
        if (images.length === 4) {
            document.getElementById("file-ip-5-preview").src = activePlus;
            document.getElementById("file-ip-6-preview").src = plus;
            setDisable1({ 'display': 'block' })
            setDisable2({ 'display': 'block' })
            setDisable3({ 'display': 'block' })
            setDisable4({ 'display': 'block' })
            setDisable5({ 'display': 'block' })
            document.getElementById(`removeButton1`).style.left = "80%";
            document.getElementById(`removeButton2`).style.left = "80%";
            document.getElementById(`removeButton3`).style.left = "80%";
            document.getElementById(`removeButton4`).style.left = "80%";
            document.getElementById(`removeButton5`).style.left = "80%";

        }
        if (images.length === 5) {
            document.getElementById("file-ip-6-preview").src = activePlus;

            document.getElementById("removeButton1").style.display = 'block';
            document.getElementById("removeButton2").style.display = 'block';
            document.getElementById("removeButton3").style.display = 'block';
            document.getElementById("removeButton4").style.display = 'block';
            document.getElementById("removeButton5").style.display = 'block';
            document.getElementById(`removeButton1`).style.left = "80%";
            document.getElementById(`removeButton2`).style.left = "80%";
            document.getElementById(`removeButton3`).style.left = "80%";
            document.getElementById(`removeButton4`).style.left = "80%";
            document.getElementById(`removeButton5`).style.left = "80%";
            document.getElementById(`removeButton6`).style.left = "80%";
        }
    }

    let previouslySelectedImageId = null; // Initialize with null

    function addColor(e) {
        const clickedImage = e.target;

        if (previouslySelectedImageId !== null) {
            // Remove the border from the previously selected image
            const previousImage = document.getElementById(previouslySelectedImageId);
            previousImage.classList.remove("border");
        }

        // Apply the border to the clicked image
        clickedImage.classList.add("border");
        setSelectedImage(clickedImage.id);

        // Update the previously selected image ID
        previouslySelectedImageId = clickedImage.id;
    }


    function myImgRemoveFunction(e) {
        let id = e.target.id.slice(-1)
        document.getElementById(`file-ip-${id}-preview`).style.border = '0px';
        let value = e.target.name
        value = parseInt(value) - 1
        if (value <= setPhotos.length && setPhotos[value].hasOwnProperty('pic_id')) {
            setDeleteImage(...deleted_image_ids, setPhotos[value].pic_id)
        }
        images.splice(value, 1);
        imageStatus.splice(value, 1)
        getPhotos(images)
        setImages(images)
        showpre()
    }

    /* **************************************************************************************** */


    function showPreviewTwo(e) {
        document.getElementById("removeButton1").style.display = "block";
        document.getElementById("removeButton1").style.left = "80%";
        if (images.length === 0) {
            document.getElementById('file-ip-2-preview').src = plus;
            document.getElementById('file-ip-2').style.display = "none";
        }

        if (e.target.files.length > 0) {
            setImages([...images, e.target.files[0]])
            let src = URL.createObjectURL(e.target.files[0]);
            let preview = document.getElementById("file-ip-2-preview");
            preview.src = src;
            preview.style.display = "block";
            document.getElementById('file-ip-3-preview').src = activePlus;
        }
    }
    /* **************************************************************************************************** */
    function showPreviewThree(e) {
        document.getElementById("removeButton2").style.display = "block";
        document.getElementById("removeButton2").style.left = "80%";
        if (images.length === 1) {
            document.getElementById('file-ip-3-preview').src = plus;
            document.getElementById('file-ip-3').style.display = "none";
        }

        if (e.target.files.length > 0) {
            setImages([...images, e.target.files[0]])
            let src = URL.createObjectURL(e.target.files[0]);
            let preview = document.getElementById("file-ip-3-preview");
            preview.src = src;
            preview.style.display = "block";
            document.getElementById('file-ip-4-preview').src = activePlus;
        }
    }
    /* **************************************************************************************************** */
    function showPreviewFour(e) {
        document.getElementById("removeButton3").style.display = "block";
        document.getElementById("removeButton3").style.left = "80%";

        if (images.length === 2) {
            document.getElementById('file-ip-4-preview').src = plus;
            document.getElementById('file-ip-4').style.display = "none";
        }
        if (e.target.files.length > 0) {
            setImages([...images, e.target.files[0]])
            let src = URL.createObjectURL(e.target.files[0]);
            let preview = document.getElementById("file-ip-4-preview");
            preview.src = src;
            preview.style.display = "block";
            document.getElementById('file-ip-5-preview').src = activePlus;
        }
    }
    /* **************************************************************************************** */

    function showPreviewFive(e) {
        document.getElementById("removeButton4").style.display = "block";
        document.getElementById("removeButton4").style.left = "80%";
        if (images.length === 3) {
            document.getElementById('file-ip-5-preview').src = plus;
            document.getElementById('file-ip-5').style.display = "none";
        }
        if (e.target.files.length > 0) {
            setImages([...images, e.target.files[0]])
            let src = URL.createObjectURL(e.target.files[0]);
            let preview = document.getElementById("file-ip-5-preview");
            preview.src = src;
            preview.style.display = "block";

        }

    }


    /* **************************************************************************************************** */
    function showPreviewSix(e) {
        if (images.length === 4) {
            document.getElementById('file-ip-4-preview').src = plus;
            document.getElementById('file-ip-4').style.display = "none";
        }
        if (e.target.files.length > 0) {
            setImages([...images, e.target.files[0]])
            let src = URL.createObjectURL(e.target.files[0]);
            let preview = document.getElementById("file-ip-6-preview");
            preview.src = src;
            preview.style.display = "block";
        }
        //console.log(images, "images")
    }

    if (images.length > 0) {
        document.getElementById('dbutton').disabled = false;
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState({ source: '', status: '' });

    const viewImage = (imageSrc, status) => {
        setModalImageSrc({ source: imageSrc, status: status });
        setIsModalOpen(true);
        //console.log(imageSrc)
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    return (
        <div className="d-flex">
            <Modal show={otherlogin} size="lg" animation={false} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body className="text-center">Sorry! Someone has logged in with your account.Please check and come back</Modal.Body>
                <Modal.Footer>
                    <Button onClick={(e) => navigate('/Login')} variant="danger">
                        ok
                    </Button>
                </Modal.Footer>
            </Modal>
            {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}

            <div className="col-6 photos_bg"></div>
            <div className="col-6 mt-5 content_login">

                <div className="col-xs-12 col-sm-12 progress-container">
                    <div className="progress progress-striped active">
                        <div className="progress-bar progress-bar-success" ></div>
                    </div>
                    {location.state !== null ? <Button variant="danger" onClick={() => navigate("/about", { state: location.state })} className="back_button mt-3"><i className="fa fa-arrow-left">&nbsp; Back</i></Button> : ''}
                </div>
                <div className="text-center">
                    {/* {photosData == 0 && location.state == null ? <><p style={{ color: 'red' }}><b>Note: Your photos under verification</b></p></> : ''}
                    {photosData == 2 ? <><p style={{ color: 'red' }}><b>Note: Admin Rejected your photos, please upload again.</b></p></> : ''}<br></br>
                    <img src={icon} /> */}

                    <h4 className="mt-2">Add Photos</h4>
                    <span>Add at least 1 photo to continue</span>
                    <Form onSubmit={handleSubmit(onPhotosSubmit)}>

                        <div className="image-upload-container">
                            <div className="col-6 d-flex">
                                <div className="image-upload-one">
                                    <div className="center">
                                        <div className="form-input">

                                            <label htmlFor="file-ip-1">
                                                {images[0] && (location.state == null ? local_storage.add_photos_status : location.state.photo_status)
                                                    ? <i className="fa fa-eye view" onClick={() => viewImage(setPhotos.length > 0 ? setPhotos[0].with_base : activePlus, setPhotos[0]?.img_status)} ></i> : ''}
                                                <div style={{ position: "relative", display: "inline-block" }}>
                                                    <img
                                                        id="file-ip-1-preview"
                                                        src={setPhotos.length > 0 ? setPhotos[0].with_base : activePlus}
                                                        className={selectedImage == `file-ip-1-preview` ? `activePlus border` : `activePlus`}
                                                        onClick={(e) => addColor(e)}
                                                    />
                                                    {images[0] && imageStatus[0] === "2" && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                width: "100%",
                                                                height: "84%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                background: "rgb(110 110 110 / 50%)",
                                                                color: "white",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                                borderRadius: "8px"
                                                            }}
                                                        >
                                                            Rejected
                                                        </div>
                                                    )}
                                                    {images[0] && <button type="button" style={{ display: `${disable1.display}` }} id="removeButton1" className="imgRemove" name="1" onClick={(e) => myImgRemoveFunction(e)}></button>}
                                                </div>
                                            </label>
                                            {isModalOpen && (
                                                <div className="modal add_photos">
                                                    <div className="modal-content add_photos">
                                                        <span className="close" onClick={closeModal}>&times;</span>
                                                        <p>{modalImageSrc.status == '0' ? 'Pending' : modalImageSrc.status == '1' ? 'Accepted' : modalImageSrc.status == '2' ? 'Rejected' : ''}</p>
                                                        <img src={modalImageSrc.source} className="modal-image" />
                                                    </div>
                                                </div>
                                            )}
                                            <input {...register("userprofile")} type='file' name="1" id="file-ip-1" accept="image/*" onChange={(e) => showPreview(e)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="image-upload-two">
                                    <div className="center">
                                        <div className="form-input">

                                            <label htmlFor="file-ip-2">
                                                {images[1] && (location.state == null ? local_storage.add_photos_status : location.state.photo_status) ? <i className="fa fa-eye view-1" onClick={() => viewImage(setPhotos.length > 0 ? setPhotos[1].with_base : activePlus, setPhotos[1]?.img_status)}></i> : ''}
                                                <div style={{ position: "relative", display: "inline-block" }}>
                                                    <img id="file-ip-2-preview" src={setPhotos.length > 1 ? setPhotos[1].with_base : plus} className={selectedImage == `file-ip-2-preview` ? `activePlus border` : `activePlus`} onClick={(e) => addColor(e)} />
                                                    {images[1] && imageStatus[1] === "2" && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                width: "100%",
                                                                height: "84%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                background: "rgb(110 110 110 / 50%)",
                                                                color: "white",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                                borderRadius: "8px"
                                                            }}
                                                        >
                                                            Rejected
                                                        </div>
                                                    )}
                                                    {images[1] && <button type="button" style={{ display: `${disable2.display}` }} id="removeButton2" className="imgRemove" name="2" onClick={(e) => myImgRemoveFunction(e)}></button>}
                                                </div>
                                            </label>

                                            <input {...register("userprofile")} name="2" id="file-ip-2" type="file" accept="image/*" onChange={(e) => showPreview(e)} />
                                        </div>

                                    </div>
                                </div>

                                <div className="image-upload-three">
                                    <div className="center">
                                        <div className="form-input">
                                            <label htmlFor="file-ip-3">
                                                {images[2] && (location.state == null ? local_storage.add_photos_status : location.state.photo_status) ? <i className="fa fa-eye view-2" onClick={() => viewImage(setPhotos.length > 0 ? setPhotos[2].with_base : activePlus, setPhotos[2]?.img_status)}></i> : ''}
                                                <div style={{ position: "relative", display: "inline-block" }}>
                                                    <img
                                                        id="file-ip-3-preview"
                                                        src={setPhotos.length > 2 ? setPhotos[2].with_base : plus}
                                                        className={selectedImage === "file-ip-3-preview" ? "activePlus border" : "activePlus"}
                                                        onClick={(e) => addColor(e)}
                                                    />
                                                    {images[2] && imageStatus[2] === "2" && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                width: "100%",
                                                                height: "84%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                background: "rgb(110 110 110 / 50%)",
                                                                color: "white",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                                borderRadius: "8px"
                                                            }}
                                                        >
                                                            Rejected
                                                        </div>
                                                    )}
                                                    {images[2] && <button style={{ display: `${disable3.display}` }} type="button" id="removeButton3" className="imgRemove" name="3" onClick={(e) => myImgRemoveFunction(e)}></button>}
                                                </div>
                                            </label>

                                            <input {...register("userprofile")} type="file" name="3" id="file-ip-3" accept="image/*" onChange={(e) => showPreview(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 d-flex">
                                <div className="image-upload-four">
                                    <div className="center">
                                        <div className="form-input">
                                            <label htmlFor="file-ip-4">
                                                {images[3] && (location.state == null ? local_storage.add_photos_status : location.state.photo_status) ? <i className="fa fa-eye view-3" onClick={() => viewImage(setPhotos.length > 0 ? setPhotos[3].with_base : activePlus, setPhotos[3]?.img_status)} ></i> : ''}
                                                <div style={{ position: "relative", display: "inline-block" }}>
                                                    <img id="file-ip-4-preview" src={setPhotos.length > 3 ? setPhotos[3].with_base : plus} className={selectedImage == `file-ip-4-preview` ? `activePlus border` : `activePlus`} onClick={(e) => addColor(e)} />
                                                    {images[3] && imageStatus[3] === "2" && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                width: "100%",
                                                                height: "84%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                background: "rgb(110 110 110 / 50%)",
                                                                color: "white",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                                borderRadius: "8px"
                                                            }}
                                                        >
                                                            Rejected
                                                        </div>
                                                    )}
                                                    {images[3] && <button type="button" style={{ display: `${disable4.display}` }} id="removeButton4" className="imgRemove" name="4" onClick={(e) => myImgRemoveFunction(e)}></button>}
                                                </div>
                                            </label>
                                            <input {...register("userprofile")} type="file" name="4" id="file-ip-4" accept="image/*" onChange={(e) => showPreview(e)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="image-upload-five">
                                    <div className="center">
                                        <div className="form-input">
                                            <label htmlFor="file-ip-5">
                                                {images[4] && (location.state == null ? local_storage.add_photos_status : location.state.photo_status) ? <i className="fa fa-eye view-4" onClick={() => viewImage(setPhotos.length > 0 ? setPhotos[4].with_base : activePlus, setPhotos[4]?.img_status)} ></i> : ''}
                                                <div style={{ position: "relative", display: "inline-block" }}>
                                                    <img id="file-ip-5-preview" src={setPhotos.length > 4 ? setPhotos[4].with_base : plus} className={selectedImage == `file-ip-5-preview` ? `activePlus border` : `activePlus`} onClick={(e) => addColor(e)} />
                                                    {images[4] && imageStatus[4] === "2" && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                width: "100%",
                                                                height: "84%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                background: "rgb(110 110 110 / 50%)",
                                                                color: "white",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                                borderRadius: "8px"
                                                            }}
                                                        >
                                                            Rejected
                                                        </div>
                                                    )}
                                                    {images[4] && <button type="button" style={{ display: `${disable1.display}` }} id="removeButton5" className="imgRemove" name="5" onClick={(e) => myImgRemoveFunction(e)}></button>}
                                                </div>
                                            </label>
                                            <input {...register("userprofile")} type="file" name="5" id="file-ip-5" accept="image/*" onChange={(e) => showPreview(e)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="image-upload-six">
                                    <div className="center">
                                        <div className="form-input">
                                            <label htmlFor="file-ip-6">
                                                {images[5] && (location.state == null ? local_storage.add_photos_status : location.state.photo_status) ? <i className="fa fa-eye view-5" onClick={() => viewImage(setPhotos.length > 0 ? setPhotos[5].with_base : activePlus, setPhotos[5]?.img_status)} ></i> : ''}
                                                <div style={{ position: "relative", display: "inline-block" }}>
                                                    <img id="file-ip-6-preview" src={setPhotos.length > 5 ? setPhotos[5].with_base : plus} className={selectedImage == `file-ip-6-preview` ? `activePlus border` : `activePlus`} onClick={(e) => addColor(e)} />
                                                    {images[5] && imageStatus[5] === "2" && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                width: "100%",
                                                                height: "84%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                background: "rgb(110 110 110 / 50%)",
                                                                color: "white",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                                borderRadius: "8px"
                                                            }}
                                                        >
                                                            Rejected
                                                        </div>
                                                    )}
                                                    {images[5] && <button type="button" id="removeButton6" className="imgRemove" name="6" onClick={(e) => myImgRemoveFunction(e)}></button>}
                                                </div>
                                            </label>
                                            <input {...register("userprofile")} type="file" name="6" id="file-ip-6" accept="image/*" onChange={(e) => showPreview(e)} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <p style={{fontSize:'12px'}}>Note: Select a picture and set it as your display image with a single tap.</p>

                        <Button type="submit" variant="primary" disabled={true} id='dbutton' className="agree_btn w-75 mt-3"  >
                            CONTINUE
                        </Button></Form>
                </div>

            </div >
        </div >
    )
}

export default AddPhotos;