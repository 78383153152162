import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDP00SiQU6uj00cL2ifPN_7P5VcrlZxPP4",
  authDomain: "frify-6c673.firebaseapp.com",
  databaseURL: "https://frify-6c673-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "frify-6c673",
  storageBucket: "frify-6c673.appspot.com",
  messagingSenderId: "300929316042",
  appId: "1:300929316042:web:0baa0d56ea9d433e9cf033",
  measurementId: "G-BZTK3800YQ"
};

function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      const app = initializeApp(firebaseConfig);

      const messaging = getMessaging(app);
      getToken(messaging, {
        vapidKey:
          "BLzfqTPHz9AkLiM8X-0CnEcfltTxEMCPDQRvbBjweuY2iignJ7mnaXhmvB1ccfv0ocGTq9-s2zevcFCTcr3c3i4",
      }).then((currentToken) => {
        if (currentToken) {
          console.log("currentToken: ", currentToken);
        } else {
          console.log("Can not get token");
        }
      });
    } else {
      console.log("Do not have permission!");
    }
  });
}

requestPermission();