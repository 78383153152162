import React, { useState, useEffect } from "react";
import icon from "../../assets/images/lock_icon.png";
import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { data } from "jquery";
import $ from 'jquery';
import LottieLoader from '../lottie/lottieloader';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
function Moreabout() {
    const [otherlogin, setotherlogin] = useState(false);
    const [states, setStates] = useState([]);
    const [show, setShow] = useState(false)
    const [countrySelected, setCountrySelected] = useState(false)
    const [mstatus, setMstatus] = useState('');
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const Auth = process.env.REACT_APP_USER_TOKEN;
    const location = useLocation();
    //console.log(location)
    const navigate = useNavigate();
    const { register, handleSubmit, resetField, formState: { errors }, setValue } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {},
    });
    const [details, setDetails] = useState([]);
    const [disable, setDisable] = useState(false);
    const [status, setStatus] = useState('');
    const [motherTongue, setmotherTongue] = useState([])
    const [selectedmotherTongue, setSelectedmotherTongue] = useState([])
    const [nation, setnation] = useState([])
    const [selectednation, setSelectednation] = useState([])
    const local_storage = JSON.parse(localStorage.getItem('userDetails'));
    var checkedValue = [];
    const onMoreSubmit = (data) => {
        //console.log(data, 'data');
        //alert("function hit");
        setDisable(true)
        // return false;
        // var checkedValue = document.querySelector('.hobbies:checked').value;
        var inputElements = document.getElementsByClassName('getmore');
        for (var i = 0; inputElements[i]; ++i) {
            if (inputElements[i].checked) {
                checkedValue.push(inputElements[i].value);
                // break;
            }
        }
        let newHobbies = checkedValue.join()
        // return false
        const regFormData = new FormData();
        let key;
        if (location.state !== null) {
            regFormData.append('user_id', location.state.user_id);
            key = location.state.api_key;
        } else {
            regFormData.append('user_id', local_storage.user_id)
            key = local_storage.api_key;
        }

        function toastfunction(data) {
            Toastify({
                text: data,
                duration: 2000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                style: {
                    background: "linear-gradient(to right, #00b09b, #96c93d)",
                },
            }).showToast();

        }
        regFormData.append('marital_status', data.status);
        if (data.status == "") {
            toastfunction("Please select Marital Status");
            setDisable(false);
            return false;
        }
        regFormData.append('children_status', data.childYes);
        //alert(data.childYes);
        if (data.childYes == undefined) {
            toastfunction("Please Provide Children Status");
            setDisable(false);
            return false;
        }
        if (data.childYes == "") {
            toastfunction("Please Provide Children Status");
            setDisable(false);
            return false;
        }
        //alert(data.childYes);
        if (data.childYes == "1") {
            if (data.children == "") {
                toastfunction("Please Provide No Of Children");
                setDisable(false);
                return false;
            }
        }
        regFormData.append('children_count', data.children);
        regFormData.append('mother_tongue_id', data.tongue);
        if (data.tongue == "") {
            toastfunction("Please Select Language");
            setDisable(false);
            return false;
        }
        regFormData.append('nationality_id', selectednation.toString());
        //alert(selectedmotherTongue);
        if (selectednation == "") {
            toastfunction("Please Select Nationality");
            setDisable(false);
            return false;
        }
        regFormData.append('country_id', data.country);
        if (data.country == "") {
            toastfunction("Please Select Living In");
            setDisable(false);
            return false;
        }

        regFormData.append('state_id', data.state);
        if (data.state == "") {
            toastfunction("Please Select State");
            setDisable(false);
            return false;
        }

        regFormData.append('occupation_id', data.occupation);
        if (data.occupation == "") {
            toastfunction("Please Select Occupation");
            setDisable(false);
            return false;
        }
        regFormData.append('user_height', data.height);
        if (data.height == "") {
            toastfunction("Please Select height");
            setDisable(false);
            return false;
        }
        regFormData.append('user_weight', data.weight);
        if (data.weight == "") {
            toastfunction("Please Select weight");
            setDisable(false);
            return false;
        }
        regFormData.append('religion_id', data.religion);
        if (data.religion == "") {
            toastfunction("Please Select religion");
            setDisable(false);
            return false;
        }

        regFormData.append('education_id', data.education);
        regFormData.append('caste', data.caste);

        regFormData.append('smoke', data.smoke);
        if (data.smoke == "") {
            toastfunction("Please Select Smoke Habit Field");
            setDisable(false);
            return false;
        }
        regFormData.append('drink', data.drinks);
        if (data.drinks == "") {
            toastfunction("Please Select Drinking Habit Field");
            setDisable(false);
            return false;
        }

        regFormData.append('diet', data.diet);
        if (data.diet == "") {
            toastfunction("Please Select Diet Field");
            setDisable(false);
            return false;
        }
        regFormData.append('horoscope_id', data.horoscope);
        // if (data.horoscope == "") {
        //     toastfunction("Please Select Horoscope Field");
        //     setDisable(false);
        //     return false;
        // }
        axios({
            method: 'POST',
            url: `${baseUrl}/Registration/set_more_about`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': key,
            },
            data: regFormData
        }).then((res) => {
            // console.log(res, 'ressss')
            setDisable(false)
            if (res.data.status === 1) {
                setTimeout(() => {
                    navigate('/settings', { state: location.state })
                }, 3000);

                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                }).showToast();
            } else {
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                }).showToast();
            }
        }).catch((e) => {
            console.log(e.response, 'e')
            setDisable(false)
            if (e.message == 'Request failed with status code 401') {
                setotherlogin(true)
            }
        })
    }
    const onMultipleSelectChangemt = (event) => {
        // let key = '';
        //   //console.log(motherTongue, "arr")
        //   for(let i=0; i<=motherTongue?.length; i=i+1){
        //     if(motherTongue[i]?.label == 'Dont mind'){
        //       //console.log(motherTongue[i]?.label,'keyy')
        //       key = motherTongue[i].key
        //     }
        //   }
        //   let len = event.length
        //   if(event.length>1 && event[event.length-1]==key ){
        //     for(let i=0; i<len-1; i++){
        //       //console.log(len,'event length')
        //       event.shift()
        //       //console.log(i,'event poped')
        //     }
        //   }else if(event.length>1 && event[0]==key ){
        //     event.shift()
        //   }
        if (event.length > 5) {
            event.pop();
            //  console.log(event,"yyy")
            //setSelectedCountries(eventt)
            //    setTimeout(()=>{
            //  document.getElementById(`multiselect-countries-${lastElement}`).checked = false;
            //  },1000)
            alert("You can select upto 5 languages only")
            return false;
        }
        //console.log(event,'event')
        let coun = [];
        event.map((e) => {
            coun.push(e)
        })
        setSelectedmotherTongue(coun)
        // else{
        //   // let key = '';
        //   // //console.log(motherTongue, "arr")
        //   // for(let i=0; i<=motherTongue?.length; i=i+1){
        //   //   if(motherTongue[i]?.label == 'Dont mind'){
        //   //     //console.log(motherTongue[i]?.label,'keyy')
        //   //     key = motherTongue[i].key
        //   //   }
        //   // }
        //   if(event[event.length-1] == key && event.length>1){
        //     event = event.filter((item) => item==key)
        //   }else if(event[event.length-1] != key && event.length>1){
        //     event = event.filter((item) => item !=key)
        //   }
        // console.log(event,'event')
        // let checkbox = document.querySelectorAll('input[type="checkbox"][name="motherTongue[]"]');
        // //console.log(checkbox,'checkkkk')
        //     let coun=[];
        //     event.map((e) => {
        //       coun.push(e)
        //     })
        //     //console.log(coun,'keyyy')
        //     if(coun.includes(key)){
        //       coun=[key]
        //       // checkbox.forEach((check) => {
        //       //   let lab = check.nextElementSibling;
        //       //       //console.log(lab,'lab')
        //       //   //console.log(check.value,'checkk');
        //       //   if(check.value!=key){
        //       //     check.checked = false
        //       //     //check.classList.add('disableCheckbox')
        //       //     //lab.classList.add('disableCheckbox')
        //       //   }else{
        //       //     //check.classList.remove('disableCheckbox')
        //       //     //lab.classList.remove('disableCheckbox')
        //       //   }
        //       // });
        //     }else if(coun.length==0){
        //       checkbox.forEach((check) => {
        //         let lab = check.nextElementSibling;
        //           check.classList.remove('disableCheckbox')
        //           lab.classList.remove('disableCheckbox')
        //       });
        //     }
        //     else{
        //       coun = coun.filter((item)=>item != key)
        //       // checkbox.forEach((check) => {
        //       //   let lab = check.nextElementSibling;
        //       //   //console.log(check.value,'checkk');
        //       //   if(check.value==key){
        //       //       check.classList.add('disableCheckbox')
        //       //       lab.classList.add('disableCheckbox')
        //       //   }else{
        //       //     check.classList.remove('disableCheckbox')
        //       //     lab.classList.remove('disableCheckbox')
        //       //   }
        //       // });
        //     }
        //     console.log(coun,'keyyyy');
        //     setSelectedmotherTongue(coun)
        // }    // setSelectedCountries(coun)
        // }
    };

    const onMultipleSelectChangenation = (event) => {
        let coun = [];
        // console.log(event, "eee")
        event.map((e) => {
            // console.log(e)
            coun.push(e)
        })
        // console.log(coun);
        setSelectednation(coun);
    };
    //console.log(selectednation,'hey')
    // console.log(details[0].nationality,'details')
    const handleChild = (e) => {
        //console.log(e.target.value, 'EEE')
        if (e.target.value === '1') {
            setShow(true)
        } else {
            setShow(false)
        }
    }
    const handleChange = (e) => {
        if (mstatus !== e.target.value) {
            resetField('childYes')
            resetField('children')
            setShow(false);
        }
        setMstatus(e.target.value);
    }
    const setSingleState = (country_id) => {
        const regFormData = new FormData();
        regFormData.append('country_id', country_id);
        let key;
        if (location.state !== null) {
            key = location.state.api_key;
        } else {
            key = local_storage.api_key;
        }
        axios({
            method: 'POST',
            url: `${baseUrl}/Registration/get_states`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': key,
            },
            data: regFormData
        }).then((res) => {
            //console.log(res, 'ressss')
            setStates(res.data.data)
        }).catch((e) => {
            console.log(e.response, 'e')
            if (e.message == 'Request failed with status code 401') {
                setotherlogin(true)
            }
        })
    }
    const handleState = (e) => {
        // console.log(e.target.value, 'SELECt')
        if (e.target.value !== 1) {
            setSingleState(e.target.value)
            setCountrySelected(true);
        } else {
            document.getElementById('dstate').style.display = 'none';
            setCountrySelected(false);
        }
    }
    //console.log(motherTongue,selectedmotherTongue,'mttt')
    useEffect(() => {
        $(".progress-bar").animate({
            width: "85%"
        }, 2500);
        axios({
            method: 'GET',
            url: `${baseUrl}/Registration/get_more_about`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': Auth,
            }
        }).then((res) => {
            // console.log(res.data.data)
            let dataa = [];
            dataa.push(res.data.data)
            let motherTongueData = [];
            //let ph = {  key: 'placeholder', label: 'Select items...', disabled: true }
            //motherTongueData.push(ph)
            // res.data.data.mother_tongue.map((data, i) => {
            //     if(data.mother_tongue != 'Dont mind'){


            //     let othercoun={};
            //     othercoun.key=data.mother_tongue_id
            //     othercoun.label=data.mother_tongue
            //     othercoun.value = data.mother_tongue
            //     othercoun.disabled = false
            //     //   //console.log(othercoun, 'othercoun')
            //     motherTongueData.push(othercoun);
            //     }
            //     //   newcoun.push({data.country_id:data.country_name});
            //     let newSelectedmt=[];
            //     selectedmotherTongue.map((data1) => {
            //       console.log(data1, '111111111111111')
            //       if (data.mother_tongue_id_id==data1) {
            //         newSelectedmt.push(data1);
            //         setSelectedmotherTongue(newSelectedmt)
            //       }

            //     })
            //   })
            let nationData = [];
            res.data.data.nationality.map((data, i) => {
                let othercoun = {};
                othercoun.key = data.nationality_id
                othercoun.label = data.nationality_name
                othercoun.value = data.nationality_name
                othercoun.disabled = false
                //   //console.log(othercoun, 'othercoun')
                nationData.push(othercoun);
                //   newcoun.push({data.country_id:data.country_name});
                let newSelectedmt = [];
                selectednation.map((data1) => {
                    console.log(data1, '111111111111111')
                    if (data.nationality_id == data1) {
                        newSelectedmt.push(data1);
                        setSelectednation(newSelectedmt)
                    }
                })
            })
            //console.log(motherTongueData,'mtt')
            //setmotherTongue(motherTongueData)
            console.log(motherTongueData, 'mtt')
            // setmotherTongue(motherTongueData)
            setnation(nationData)
            setDetails(dataa)
        }).catch((e) => {
            console.log(e)
        })

        const getUserFormData = new FormData();
        let key;
        if (location.state !== null) {
            getUserFormData.append('user_id', location.state.user_id);
            key = location.state.api_key;
        } else {
            getUserFormData.append('user_id', local_storage.user_id)
            key = local_storage.api_key;
        }
        axios({
            method: 'POST',
            url: `${baseUrl}/Registration/get_more_about_me`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': key,
            },
            data: getUserFormData
        }).then((res) => {
            // console.log(res)
            if (res.data.status === 1) {
                setTimeout(() => {
                    setValue('tongue', res.data.data.mother_tongue_id)
                    setValue('nation', res.data.data.nationality_id)
                    setValue('occupation', res.data.data.occupation_id)
                    setValue('country', res.data.data.country_id)
                    if (res.data.data.country_id != 1) {
                        setCountrySelected(false)
                    } else {
                        setCountrySelected(true)
                    }
                    setValue('state', res.data.data.state_id)
                    setValue('height', res.data.data.user_height)
                    setValue('weight', res.data.data.user_weight)
                    setValue('religion', res.data.data.religion_id)
                    setValue('horoscope', res.data.data.horoscope_id)
                }, 300);
                res.data.data.country_id != '' && setSingleState(res.data.data.country_id)
                setValue('status', res.data.data.marital_status)
                //setValue('tongue', res.data.data.mother_tongue_id)
                setValue('childYes', res.data.data.children_status)
                setValue('children', res.data.data.children_count)
                // setValue('nation', res.data.data.nationality_id)
                // setValue('occupation', res.data.data.occupation_id)
                // setValue('country', res.data.data.country_id)
                // setValue('height', res.data.data.user_height)
                // setValue('weight', res.data.data.user_weight)
                setValue('education', res.data.data.education_id)
                setValue('religion', res.data.data.religion_id)
                setValue('caste', res.data.data.caste)
                setValue('smoke', res.data.data.smoke)
                setValue('drinks', res.data.data.drink)
                setValue('diet', res.data.data.diet)
                setValue('horoscope', res.data.data.horoscope_id)
                if (res.data.data.children_status != 0) {
                    setShow(true)
                } else {
                    setShow(false)
                }
                if (res.data.data.mother_tongue_id != "") {
                    setSelectedmotherTongue(res.data.data.mother_tongue_id.split(','));
                }
                if (res.data.data.nationality_id != "") {
                    setSelectednation(res.data.data.nationality_id.split(','));
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }, [])
    // function handleStatusClick(e) {
    //     console.log(e.target.value)
    //     setStatus(e.target.value)
    // }
    //console.log(motherTongue,'mt')
    console.log(details, 'mttt')
    return (
        <div className="d-flex">
            <Modal show={otherlogin} size="lg" animation={false} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body className="text-center">Sorry! Someone has logged in with your account.Please check and come back</Modal.Body>
                <Modal.Footer>
                    <Button onClick={(e) => navigate('/Login')} variant="danger">
                        ok
                    </Button>
                </Modal.Footer>
            </Modal>
            {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}
            <div className="col-6 moreabout_page"></div>
            <div className="col-6 mt-5 content_login" style={{ height: '100vh', overflowY: 'auto' }}>
                <div className="col-xs-12 col-sm-12 progress-container">
                    <div className="progress progress-striped active">
                        <div className="progress-bar progress-bar-success" ></div>
                    </div>
                    {location.state !== null ? <Button variant="danger" onClick={() => navigate("/addphotos", { state: location.state })} className="back_button mt-3"><i className="fa fa-arrow-left">&nbsp; Back</i></Button> : ''}
                </div>
                <div className="text-center">
                    <img src={icon} />
                </div>
                <Form className="mt-4 px-5" >
                    <h3 className="text-center mb-4">More About Me</h3>
                    <label>Status</label>
                    <div className="form-check px-0" id="activeweights" >
                        {details && details.length > 0 ? details[0].marital_status.map((data, i) => {
                            // console.log(data)
                            return (
                                <label className="custom-checkbox">
                                    <input {...register("status")} className="getmore custom-checkbox-input" value={data.id} type="radio" onClick={(e) => handleChange(e)} />
                                    <span className="custom-checkbox-text">{data.name}</span>
                                </label>
                            )
                        }) : ''}
                        <br></br>
                        <span className='error_msg'> {errors.status?.type === 'required' && "Please  select martial status"}</span>
                    </div>
                    {status !== '1' && <> <label className="mt-3">Children</label>
                        <div className="form-check px-0 d-flex" id="activeweights" >
                            <div className="col-4">
                                <label className="custom-checkbox">
                                    <input type="hidden" value="False" />
                                    <input {...register("childYes")} onClick={(e) => handleChild(e)} className="getmore custom-checkbox-input" value="1" type="radio" />
                                    <span className="custom-checkbox-text">Yes</span>
                                </label>
                                <label className="custom-checkbox">
                                    <input type="hidden" value="False" />
                                    <input {...register("childYes")} onClick={(e) => handleChild(e)} className="getmore custom-checkbox-input" value="0" type="radio" />
                                    <span className="custom-checkbox-text">No</span>
                                </label>
                            </div>
                            <span className='error_msg'> {errors.childYes?.type === 'required' && "Please select children"}</span>
                            {show ? <div className="col-4"><Form.Select {...register("children")} aria-label="Default select example" className="looking_dropdown col-2">
                                <option value="">How many</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </Form.Select></div> : ''}
                        </div>
                        <span className='error_msg'> {errors.children?.type === 'required' && "Please  select How many Children"}</span> </>}
                    <Form.Select  {...register("tongue")} className="looking_dropdown" required>
                        <option value="" disabled >Language</option>
                        {details && details.length > 0 ? details[0].mother_tongue.map((data, i) => {
                            // console.log(data)
                            if (data.mother_tongue_id != '57') {
                                return (
                                    <option value={data.mother_tongue_id} >{data.mother_tongue}</option>
                                )
                            }
                        }) : ''}
                    </Form.Select>

                    {/* <div className="mb-2 mt-2">
                     {motherTongue&&motherTongue.length>0?<DropdownMultiselect
                        selected={selectedmotherTongue} handleOnChange={(e) => onMultipleSelectChangemt(e)}
                        className="looking_mother_tongue" options={motherTongue} name="motherTongue"  placeholder="Language" />:''}
                     
                        </div> */}

                    {/* <span className='error_msg'> {errors.nation?.type === 'required' && "Please  select Mother Tongue"}</span> */}
                    <div className="mt-3">
                        {nation && nation.length > 0 ? <DropdownMultiselect
                            selected={selectednation} handleOnChange={(e) => onMultipleSelectChangenation(e)}
                            className="looking_nation" options={nation} name="nation" placeholder="Nationality" /> : ''}
                    </div>
                    <span className='error_msg'> {errors.nation?.type === 'required' && "Please  select nationality"}</span>
                    <Form.Select {...register("country")} onChange={(e) => handleState(e)} aria-label="Default select example" className="looking_dropdown mt-3" required>
                        <option value="" disabled >Living in</option>
                        {details && details.length > 0 ? details[0].country.map((data, i) => {
                            // console.log(data)
                            return (
                                <option key={i} value={data.country_id}>{data.country_name}</option>
                            )
                        }) : ''}
                    </Form.Select>
                    {states && countrySelected ? <Form.Select {...register("state")} aria-label="Default select example" className="looking_dropdown" required>
                        <option value="" disabled >State</option>
                        {states && states.length > 0 ? states.map((data, i) => {
                            //console.log(data)
                            return (
                                <option key={i} value={data.state_id}>{data.state_name}</option>
                            )
                        }) : ''}
                    </Form.Select> : ""}
                    <span className='error_msg'> {errors.country?.type === 'required' && "Please  select country"}</span>
                    <span className='error_msg'> {errors.state?.type === 'required' && "Please  select state"}</span>

                    <Form.Select {...register("occupation")} aria-label="Default select example" className="looking_dropdown" required>
                        <option value="" disabled >Occupation</option>
                        {details && details.length > 0 ? details[0].occupation.map((data, i) => {
                            // console.log(data)
                            return (
                                <option key={i} value={data.occupation_id}>{data.occupation_name}</option>
                            )
                        }) : ''}
                    </Form.Select>
                    <span className='error_msg'> {errors.occupation?.type === 'required' && "Please  select occupation"}</span>
                    {/* <Form.Select {...register("ethnicity")} aria-label="Default select example" className="looking_dropdown" required>
                        <option value="" disabled >Ethnicity</option>
                        {details && details.length > 0 ? details[0].ethnicity.map((data, i) => {
                            // console.log(data)
                            return (
                                <option key={i} value={data.ethnicity_id}>{data.ethnicity_name}</option>
                            )
                        }) : ''}
                    </Form.Select> */}
                    {/* <Form.Select {...register("country", {required: "true"})} onChange={(e) => handleState(e)} aria-label="Default select example" className="looking_dropdown" required>
                        <option value="" disabled >Country</option>
                        {details&&details.length>0? details[0].country.map((data, i) => {
                            // console.log(data)
                            return (
                                <option key={i} value={data.country_id}>{data.country_name}</option>
                            )
                        }):''}
                    </Form.Select>
                    {states&&countrySelected? <Form.Select {...register("state", {required: "true"})} aria-label="Default select example" className="looking_dropdown" required>
                        <option value="" disabled >State</option>
                        {states&&states.length>0? states.map((data, i) => {
                            //console.log(data)
                            return (
                                <option key={i} value={data.state_id}>{data.state_name}</option>
                            )
                        }):''}
                    </Form.Select>:""}
                    <span className='error_msg'> {errors.country?.type==='required'&&"Please  select country"}</span>
                    <span className='error_msg'> {errors.state?.type==='required'&&"Please  select state"}</span>
                    */}
                    <div className="d-flex justify-content-between">
                        <div className="col-5">
                            <label className="mt-3">My Height</label>
                            <Form.Select {...register("height")} aria-label="Default select example" className="looking_dropdown mt-1" required>
                                <option value="" disabled >Height</option>
                                {details && details.length > 0 ? details[0].heights.map((data, i) => {
                                    // console.log(data)
                                    return (
                                        <option key={i} value={data.id}>{data.height}{data.unit}</option>
                                    )
                                }) : ''}
                            </Form.Select>
                            <span className='error_msg'> {errors.height?.type === 'required' && "Please select height"}</span>
                        </div>
                        <div className="col-5">
                            <label className="mt-3">My Weight</label>
                            <Form.Select {...register("weight")} aria-label="Default select example" className="looking_dropdown mt-1" required>feet
                                <option value="" disabled >Weight</option>
                                {details && details.length > 0 ? details[0].weights.map((data, i) => {
                                    // console.log(data)
                                    return (
                                        <option key={i} value={data.id}>{data.weight}</option>
                                    )
                                }) : ''}
                            </Form.Select>
                            <span className='error_msg'> {errors.weight?.type === 'required' && "Please select weight"}</span>
                        </div>
                    </div>
                    <label className="mt-3">Education (optional)</label>
                    <div className="form-check px-0" id="activeweights" >
                        {details && details.length > 0 ? details[0].studies.map((data, i) => {
                            // console.log(data)
                            return (
                                <label key={i} className="custom-checkbox">
                                    <input {...register("education")} className="getmore custom-checkbox-input" value={data.study_id} type="radio" />
                                    <span className="custom-checkbox-text">{data.study_name}</span>
                                </label>
                            )
                        }) : ''}
                    </div>
                    <Form.Select {...register("religion")} aria-label="Default select example" className="looking_dropdown mt-3" required>
                        <option value="" disabled >Religion</option>
                        {details && details.length > 0 ? details[0].religion.map((data, i) => {
                            // console.log(data)
                            return (
                                <option key={i} value={data.religion_id}>{data.religion_name}</option>
                            )
                        }) : ''}
                    </Form.Select>
                    <span className='error_msg'> {errors.religion?.type === 'required' && "Please  select religion"}</span>
                    <Form.Control {...register("caste")} type="text" placeholder="Caste (optional)" className="caste_field" />
                    {/* <div className="d-flex justify-content-between">
                        <div className="col-3">
                            <label className="mt-3">My Height</label>
                            <Form.Select {...register("height")} aria-label="Default select example" className="looking_dropdown mt-1" required>
                                <option value="" disabled >Height</option>
                                {details&&details.length>0? details[0].heights.map((data, i) => {
                                    // console.log(data)
                                    return (
                                        <option key={i} value={data.id}>{data.height}{data.unit}</option>
                                    )
                                }):''}
                            </Form.Select></div>
                        <div className="col-3">
                            <label className="mt-3">My Weight</label>
                            <Form.Select {...register("weight")} aria-label="Default select example" className="looking_dropdown mt-1" required>feet
                                <option value="" disabled >Weight</option>
                                {details&&details.length>0? details[0].weights.map((data, i) => {
                                    // console.log(data)
                                    return (
                                        <option key={i} value={data.id}>{data.weight}{data.unit}</option>
                                    )
                                }):''}
                            </Form.Select></div>
                    </div> */}
                    <label className="mt-3">Smokes</label>
                    <div className="form-check px-0" id="activeweights" >
                        {details && details.length > 0 ? details[0].smoke.map((data, i) => {
                            // console.log(data)
                            return (
                                <label key={i} className="custom-checkbox">
                                    <input {...register("smoke")} className="getmore custom-checkbox-input" name='smoke' value={data.id} type="radio" />
                                    <span className="custom-checkbox-text">{data.name}</span>
                                </label>
                            )
                        }) : ''}<br></br>
                        <span className='error_msg'> {errors.smoke?.type === 'required' && "Please select your smoking habit"}</span>
                    </div>
                    <label className="mt-3">Drinks</label>
                    <div className="form-check px-0" id="activeweights" >
                        {details && details.length > 0 ? details[0].drink.map((data, i) => {
                            // console.log(data)
                            return (
                                <label className="custom-checkbox">
                                    <input {...register("drinks")} className="getmore custom-checkbox-input" value={data.id} type="radio" />
                                    <span className="custom-checkbox-text">{data.name}</span>
                                </label>
                            )
                        }) : ''}<br></br>
                        <span className='error_msg'> {errors.drinks?.type === 'required' && "Please select your alcoholic habit"}</span>
                    </div>
                    <label className="mt-3">My Diet</label>
                    <div className="form-check px-0" id="activeweights" >
                        {details && details.length > 0 ? details[0].diet.map((data, i) => {
                            // console.log(data)
                            return (
                                <label key={i} className="custom-checkbox">
                                    <input {...register("diet")} className="getmore custom-checkbox-input" value={data.id} type="radio" />
                                    <span className="custom-checkbox-text">{data.name}</span>
                                </label>
                            )
                        }) : ''}<br></br>
                        <span className='error_msg'> {errors.diet?.type === 'required' && "Please select diet"}</span>
                    </div>
                    <Form.Select {...register("horoscope")} aria-label="Default select example" className="looking_dropdown mt-3">
                        <option value="" disabled >Horoscope (optional)</option>
                        {details && details.length > 0 ? details[0].horoscope.map((data, i) => {
                            // console.log(data)
                            return (
                                <option key={i} value={data.horoscope_id}>{data.horoscope_name}</option>
                            )
                        }) : ''}
                    </Form.Select>
                    <Button variant="dark" type="submit" className="d-block m-auto mt-5 mb-3 w-100 btn_disabled" onClick={handleSubmit(onMoreSubmit)}>
                        CONTINUE
                    </Button>
                </Form>
            </div>
        </div>
    )
}
export default Moreabout;