import React, { useState, useEffect } from "react";
import Sidemenu from "../sidebar/sidemenu";
import { useNavigate, useLocation } from 'react-router-dom';
import './payment.css';
import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button, Card } from 'react-bootstrap';
import profileShow from '../../assets/images/profile_show.png';
import like from '../../assets/images/like.png';
import shortlist from '../../assets/images/shortlist.png';
import interest from '../../assets/images/interested.png';
import skip from '../../assets/images/skip.png';
import User from '../../assets/images/user.png';
import Delete from '../../assets/images/delete.png';
import CardHeader from "react-bootstrap/esm/CardHeader";
import axios from "axios";
import Paypal from "./Paypal";


function Payment() {
    const [show, setShow] = useState(true);
    const location = useLocation();
    const [payment, setPayment] = useState([]);
    const [subscription, setsubscription] = useState([]);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const Auth = process.env.REACT_APP_USER_TOKEN;
    const [checkout, setCheckOut] = useState(false);
    const [planid, setplanid] = useState('');
    const [currency, setCurrency] = useState([]);
    const local_storage = JSON.parse(localStorage.getItem('userDetails'));
    //console.log(planid)
    //alert(planid)

    useEffect(() => {
        axios({
            method: 'GET',
            url: `${baseUrl}/Profiles/subscriptions`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': Auth,
            }
        }).then((res) => {
            console.log(res.data.data)
            setPayment(res.data.data)
        }).catch((e) => {
            console.log(e)
        })

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/currency_list`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': Auth,
            }
        }).then((res) => {
            console.log(res.data.data)
            setCurrency(res.data.data)
        }).catch((e) => {
            console.log(e)
        })

        const payData = new FormData();
        payData.append('user_id', local_storage.user_id);
        axios({
            method: 'POST',
            url: `${baseUrl}/Payments/subscription_details`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            }, data: payData
        }).then((res) => {
            // console.log(res.data.data, 'pay')
            setsubscription(res.data.data)
        }).catch((e) => {
            console.log(e)
        })
    }, [])
    // console.log(payment)
    // console.log(subscription)
    const [selectedCurrency, setSelectedCurrency] = useState({
        currency_code: 'USD',
        currency_rate: 1.0, // 1.0 for USD
    });

    const handleCurrencyChange = (event) => {
        const selectedCurrencyId = event.target.value;
        const selectedCurrencyData = currency.find(
            (item) => item.id === selectedCurrencyId
        );

        if (selectedCurrencyData) {
            setSelectedCurrency({
                currency_code: selectedCurrencyData.currency_code,
                currency_rate: selectedCurrencyData.currency_rate,
            });
        }
    };
    return (
        <div className="d-flex">
            <div className="col-3 page_left" style={{ width: 'auto' }}>
                <Sidemenu />
            </div>
            <div className="col-9 d-flex flex-column payment-page page_right">
                <div className="d-flex flex-row bg-white top-bar shadow">
                    <p>Payments</p>
                    <div className="d-flex w-25 align-items-baseline">
                        <label> Currency:</label>&nbsp; <Form.Select aria-label="Default select example" onChange={handleCurrencyChange}>

                            {currency.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.currency_code}
                                </option>
                            ))}
                        </Form.Select>
                        </div>
                   

                    {/* <div className="menu-nav">

                        <div className="dropdown-container" tabindex="-1">
                            <div className="three-dots"></div>
                            <div className="dropdown">
                                <a href="#"><div>Clear Messages</div></a>
                                <a href="#"><div>Mark All as Read</div></a>
                                <a href="#"><div className="cancel">Cancel</div></a>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="d-flex p-0 m-0" style={{ width: '100%' }}>
                    <div className="user_info col-6 mt-4 mb-3 px-5">
                        {/* <div>
                            Selected Currency Code: {selectedCurrency.currency_code}
                        </div>
                        <div>
                            Selected Currency Rate: {selectedCurrency.currency_rate}
                        </div> */}
                        {subscription ? <Card className="payment-top-card mb-3 p-4">
                            <div className="d-flex justify-content-between">
                                <div><b>Current Plan</b></div>
                                <div>{subscription.plan_name}</div>
                            </div>
                            <div className="d-flex justify-content-between mt-4">
                                <div><b>Time Period</b></div>
                                <div>{subscription.purchase_date} - {subscription.exp_date}</div>
                            </div>
                            <div className="d-flex justify-content-around mt-4">
                                <Button className="unsub_btn">UNSUBSCRIBE</Button>
                                <Button className="unsub_btn">UPGRADE</Button>
                            </div>
                        </Card> : ''}
                        <Card className="payment-bottom-card mb-3">
                            <CardHeader className="justify-content-center">
                                <p>Get access to chat , calls and video calls</p>
                            </CardHeader>
                            <div className="d-flex">
                                {payment.map((item) => <Card.Body>

                                    <Button class="text-card shadow" onClick={() => setplanid(item.plan_price)} className="plan_btn p-0 col-12">
                                        <p className="text-white p-3 bg-button" style={{fontSize: '22px', fontWeight:'600'}}>{item.plan_name}</p>
                                        <p className="mt-5">{item.till_months} months</p>
                                        <p style={{fontSize:'24px', fontWeight:'bold'}}>
                                            <span>{selectedCurrency.currency_code}</span> {''}
                                            {(
                                                parseFloat(selectedCurrency.currency_rate) *
                                                parseFloat(item.plan_price)
                                            ).toFixed(2)}
                                        </p>
                                        <Button className="btn bg-button mb-4 mt-4">Buy Now</Button>
                                    </Button>
                                </Card.Body>)}</div>
                            {planid ? <Card.Footer>
                                {checkout ? (
                                    <Paypal plans={planid} />
                                ) : (
                                    <button className="continue-btn"
                                        onClick={() => {
                                            setCheckOut(true);
                                        }}
                                    >
                                        CONTINUE
                                    </button>
                                )}
                            </Card.Footer> : ''}
                        </Card>
                    </div>
                   
                </div>
            </div>
        </div>
    )
}

export default Payment;