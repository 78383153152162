import React, { useState, useEffect } from "react";
import icon from "../../assets/images/lock_icon.png";
import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import './selfie.css'
import Webcam from "react-webcam";
import lottie from '../../assets/images/23504-visa-photo-loading.json'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import LottieLoader from '../lottie/lottieloader';
import axios from "axios";

function Selfie() {
  const [disable, setDisable] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const [details, setDetails] = useState([]);
  const [otherlogin, setotherlogin] = useState(false);


  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location)
  const local_storage = JSON.parse(localStorage.getItem('userDetails'));
 console.log(local_storage)
  const [pending, setPending] = useState(false)
  const [approved, setApproved] = useState(false)
  const [reject, setReject] = useState(false)
  const [id, setId] = useState('')
  // if (location.state !== null) {
  //   localStorage.setItem("userDetails", JSON.stringify(location.state))
  // }

  // useEffect(() => {
  //   const getUserFormData=new FormData();
  //   getUserFormData.append('user_id', local_storage.user_id)
  //   axios({
  //     method: 'POST',
  //     url: `${baseUrl}/Registration/set_selfie_verification_status`,
  //     headers: {
  //       "Content-type": "multipart/form-data; charset=utf-8; boundary="+Math.random().toString().substr(2),
  //       'X-api-key': local_storage.api_key,
  //     },
  //     data: getUserFormData
  //   }).then((res) => {
  //     console.log(res.data.data)
  //     let dataa=[];
  //     dataa.push(res.data.data)
  //     setDetails(dataa)
  //   }).catch((e) => {
  //     console.log(e)
  //   })

  // }, [])
  useEffect(() => {

    const getUserFormData = new FormData();
    getUserFormData.append('user_id', local_storage.user_id)
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/get_selfie_verification_status`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: getUserFormData
    }).then((res) => {
      // console.log(res.data.status)
      if (res.data.data.selfie_status == '0') {
        setPending(true)
      } else if (res.data.data.selfie_status == '1') {
        setId(res.data.data.unique_id)
        setApproved(true)
      } else if (res.data.data.selfie_status == '2') {
        setReject(true)
      }

    }).catch((e) => {
      console.log(e)
      if (e.message == 'Request failed with status code 401') {
        setotherlogin(true)
      }
    })
  }, [])
  return (
    <div className="d-flex">
      <Modal show={otherlogin} size="lg" animation={false} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="text-center">Sorry! Someone has logged in with your account.Please check and come back</Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => navigate('/Login')} variant="danger">
            ok
          </Button>
        </Modal.Footer>
      </Modal>
      {disable ? <div className='AddPetloadingImage'><LottieLoader></LottieLoader></div> : ''}
      <div className="col-6 selfiePic">
      </div>

      {approved ?
        <div className="col-6 mt-5 content_login">
          <div className="text-center">
            <img src={icon} />
            <h4 className="mt-5">Selfie Verification</h4>
          </div>
          <div className="text-center">
            <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>

            <Player
              autoplay
              loop
              src={lottie}
              style={{ height: '250px', width: '250px' }}
            >
              {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
            </Player>
            <h5 className="mt-3">Your profile is verified</h5>
            <span>Your ID <span style={{ color: 'red' }}>#{id}</span></span>
          </div>

          <Button onClick={(e) => navigate('/settings', { state: location.state })} variant="dark" type="button" className="d-block m-auto mt-5 w-50 btn_disabled">
            OK CONTINUE
          </Button>
        </div>
        : pending ?
          <div className="col-6 mt-5 content">
            <div className="text-center">
              <img src={icon} />
              <h4 className="mt-5">Selfie Verification</h4>
            </div>
            <div className="text-center">
              <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>

              <Player
                autoplay
                loop
                src={lottie}
                style={{ height: '250px', width: '250px' }}
              >
                {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
              </Player>
              <h6 className="mt-3 w-75 d-block m-auto">Once your selfie is verified by our admin, your uploaded photos will be visible to all users on our dating application.</h6>
              </div>
            <Button onClick={(e) => navigate('/settings')} variant="dark" type="button" className="d-block m-auto mt-4 w-50 btn_disabled">
              OK CONTINUE
            </Button>
          </div>
          : reject ?
            <div className="col-6 mt-5 content">
              <div className="text-center">
                <img src={icon} />
                <h4 className="mt-5">Selfie Verification</h4>
              </div>
              <div className="text-center">
                <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>

                <Player
                  autoplay
                  loop
                  src={lottie}
                  style={{ height: '250px', width: '250px' }}
                >
                  {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                </Player>
                <h5 className="mt-3 w-75">Your account wasn't verified because it doesn't meet the criteria for verification. you can re-submit the photo for verification</h5>
              </div>
              <Button onClick={(e) => navigate('/selfie', { state: location.state })} variant="dark" type="button" className="d-block m-auto mt-5 w-75 btn_disabled">
                OK THANK YOU
              </Button>
            </div> : ''
      }



    </div>
  )
}

export default Selfie;