import { Navigate, useLocation, useNavigate } from "react-router-dom";

const Private = ({ child }) => {
    const location = useLocation();
    const history = useNavigate();
    const Auth = localStorage.getItem('gAuth');
    let path = location.pathname;
    let fullpath = "https://bu3is.krify.com/matchmade4u_web/#" + path;
    if(Auth==null){
        localStorage.setItem('profileview',fullpath);
        history('/Login');
    }
    return Auth == "true" ? child : <Navigate to="/" />
}

export default Private