import React, { useState, useEffect, useRef } from "react";
import Sidemenu from "../sidebar/sidemenu";
import { useNavigate, useLocation } from 'react-router-dom';
import './matches.css';
import { Form, Button, Card, ModalHeader, Carousel, OverlayTrigger, Tooltip  } from 'react-bootstrap';
import like from '../../assets/images/outlike.png';
import liked from '../../assets/images/outliked.png';
import CardHeader from "react-bootstrap/esm/CardHeader";
import axios from "axios";
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import Toastify from 'toastify-js';
import male from '../../assets/images/avatarm.png';
import female from '../../assets/images/avatarf.png';
import male1 from '../../assets/images/men_s.svg';
import female1 from '../../assets/images/women_s.svg';
import "toastify-js/src/toastify.css";
import map from '../../assets/images/location.png';
import msg from '../../assets/images/msg-icon.svg';
import videoicon from '../../assets/images/video-icon.svg';
import audio from '../../assets/images/call-icon.svg';
import Peer from 'peerjs';
import VideoCall from './videoroom.js'
import AgoraRTC from 'agora-rtc-sdk-ng';
import { RWebShare } from "react-web-share";
import mute from '../../assets/images/mute.svg';
import unmute from '../../assets/images/unmute.svg';
import end from '../../assets/images/endcall.svg';
import { getDatabase, ref as fref, update, onValue } from "firebase/database";
import decline from '../../assets/images/endcall.svg'
import accept from '../../assets/images/accept.svg'
import ring from '../../assets/images/callring.mp3'
import ring1 from '../../assets/images/callringing.mp3'
import NoData from '../../assets/images/no_data.gif';
import LottieLoader3 from '../lottie/lottieloader3';
import deleteicon from '../../assets/images/delete-icon.jpg';
import shortlist from '../../assets/images/outshortlist1.png';
import interest from '../../assets/images/outinterest.png';
import skip from '../../assets/images/skip.png';
import User from '../../assets/images/user.png';
import Delete from '../../assets/images/delete.png';
import { FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import clipboardCopy from 'clipboard-copy';

function Matches() {
    const [closeaction, setcloseaction] = useState('true');

    const close = () => {
        setcloseaction('false');  
        setIddata('');
    }

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const Auth = process.env.REACT_APP_USER_TOKEN;
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const location = useLocation();
    const [value, setValue] = useState({ 'profiles': [], 'path': '' })
    const [modalShow, setModalShow] = React.useState(false);
    const [id, setid] = useState('')
    const [blockshow, setblockshow] = useState(false)
    const [reportshow, setreportshow] = useState(false)
    const [questions, setQuestons] = useState([]);
    const [userselectedQ, setUserselectedQ] = useState({ 'profile_id': '', 'question': 1 });
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [count, setCount] = React.useState(0);
    const local_storage = JSON.parse(localStorage.getItem('userDetails'));
    const [videoshow, setvideoshow] = useState(false)
    const [audioshow, setaudioshow] = useState(false)
    const [subshow, setsubShow] = useState(false);
    const subClose = () => setsubShow(false);
    const [subshow1, setsubShow1] = useState(false);
    const subClose1 = () => setsubShow1(false);
    const [busyshow, setbusyShow] = useState(false);
    const subBusy = () => setbusyShow(false);
    //const handleShow = () => setsubShow(true);
    const [peerId, setPeerId] = useState('');
    const [video, setvideo] = useState(0)
    const [videocall, setvideocall] = useState(false)
    const [remotePeerIdValue, setRemotePeerIdValue] = useState('');
    const remoteVideoRef = useRef(null);
    const currentUserVideoRef = useRef(null);
    const peerInstance = useRef(null);
    const ref = useRef();
    //const gender = JSON.parse(localStorage.getItem('gender'));
    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const date = new Date().toLocaleDateString();
    const time = new Date().toLocaleTimeString();
    const [reloadPage, setReloadPage] = useState(sessionStorage.getItem('notificationData'));
    const [display_list, setdisplay] = useState({
        about_me: "", age: "", country_name: "", gender: "",
        height: "",
        height_unit: "",
        images: [{ 'image': '' }],
        like_status: "",
        locked_status: "",
        mother_tongue: "",
        name: "",
        nationality_name: "",
        occupation_name: "",
        religion_name: "",
        report_user_list_status: "",
        request_list_id: "",
        request_status: "",
        selfie_status: "",
        shortlist_status: "",
        state_name: "",
        study_name: null,
        unique_id: "",
        user_id: "",
        weight: "",
        weight_unit: "",
        referral_link: "",
        chat_id: "",
        subscription_status: "",
        call_status: "",
        photos_slider_status: "",
        display_name: ""
    });
    const [length, setlength] = useState(0)
    const [callshow, setcallshow] = useState(false);
    const [NotifyData, setNotifydata] = useState([]);
    const latest_msg = JSON.parse(localStorage.getItem('msg_input'));
    const [Mute, setMute] = useState(false)
    const [usersData, setUsersData] = useState('')
    const [crtc, setrtc] = useState({
        localAudioTrack: null, localVideoTrack: null,
        client: null,
    })
    let rtc = {
        localAudioTrack: null,
        localVideoTrack: null,
        client: null,
    };
    //console.log(display_list.subscription_status)
    const fdb = getDatabase();

    function makePageTransparent() {
        var overlay = document.getElementById('overlay');
        overlay.style.display = 'block';
    }

    function makePagenotTransparent() {
        var overlay = document.getElementById('overlay');
        overlay.style.display = 'none';
    }


    const createChat = () => {
        let query = fref(fdb, `Frify_test_users/${local_storage.user_id}/conversations`);
        onValue(query, (snapshot) => {
            // console.log(snapshot.val(), 'SANP')
            let converstionsList = snapshot.val()
            // converstionsList.map((item)=>{
            // })
            const checkUsername = obj => obj.id === display_list.chat_id;

            let isConvoIdExistes = converstionsList?.length && converstionsList.some(checkUsername)
            if (isConvoIdExistes && converstionsList?.length) {
                navigate('/chat')
            } else {
                const postData = {
                    "Latest_message": {
                        "date": `${date} ${time}`,
                        "isDelivered": '',
                        "isRead": '',
                        "msg": latest_msg,
                        "sender_id": local_storage.user_id
                    },
                    "blockedByOthers": '',
                    "blockedByYouStatus": '',
                    "id": `${display_list.chat_id}`,
                    "other_user_id": `${display_list.user_id}`,
                    "other_user_name": `${display_list.display_name}`,
                    "other_user_pic": display_list.images && display_list.images.length > 0 ? `${display_list.images[0].image}` : '', // Check if images array exists and has at least one element,
                    "YourTypingStatus": '',
                };
                const updates = {};
                // const newPostKey = push(child(fref(fdb), 'Frify_test_users/' + local_storage.user_id + '/conversations')).key;
                // console.log(newPostKey,'test')

                updates['Frify_test_users/' + local_storage.user_id + '/conversations/' + length] = postData;
                // updates['/user-posts/' + uid + '/' + 0] = postData;
                navigate('/chat')
                return update(fref(fdb), updates);
            }
        })

        // display_list.chat_id

        // return false

        //let message = document.getElementById('msg_input').value
        // set(fref(fdb, 'Frify_test_users/' + local_storage.user_id + '/conversations'), {
        //     "Latest_message": {
        //         "date": `${date} ${time}`,
        //         "isDelivered": '',
        //         "isRead": '',
        //         "msg": '',
        //         "sender_id": local_storage.user_id
        //     },
        //     "blockedByOthers": '',
        //     "blockedByYouStatus": '',
        //     "id": `conversation_${display_list.user_id}_${local_storage.user_id}`,
        //     "other_user_id": `${display_list.user_id}`,
        //     "other_user_name": `${display_list.name}`,
        //     "other_user_pic": `${display_list.images[0].image}.`,
        //     "YourTypingStatus": '',

        // }).then((res) => {
        //     console.log(res)
        // })
        //     .catch((error) => {
        //         console.log(error)
        //     });


        //     const db = getDatabase();

        // A post entry.


        // Get a key for a new Post.
        // const newPostKey = push(child(ref(db), 'posts')).key;

        // Write the new post's data simultaneously in the posts list and the user's post list.


    }

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
    }

    const handleModalShow = () => {
        const audioElement = document.getElementById('notificationAudio');
        if (audioElement) {
            audioElement.play();
        }
    };

    const handleModalHide = () => {
        const audioElement = document.getElementById('notificationAudio');
        if (audioElement) {
            audioElement.pause();
        }
    };

    //console.log(display_list, "resssss")
    let path = 0;

    useEffect(() => {
        //console.log('i got called','hellooooo')
        const peer = new Peer();
        // runOneSignal();
        // console.log(runOneSignal())
        getNotifyData();
        // console.log(NotifyData, 'push')
        // peer.on('open', (id) => {
        //     setPeerId(id)
        // });

        // peer.on('call', (call) => {
        //     var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        //     getUserMedia({ video: true }, (mediaStream) => {
        //         currentUserVideoRef.current.srcObject = mediaStream;
        //         currentUserVideoRef.current.play();
        //         call.answer(mediaStream)
        //         call.on('stream', function (remoteStream) {
        //             remoteVideoRef.current.srcObject = remoteStream
        //             remoteVideoRef.current.play();
        //         });
        //     });
        // })

        // peerInstance.current = peer;
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id);
        regFormData.append('min', 0)
        regFormData.append('max', 10)
        regFormData.append('type', 0);

        //console.log(lat)
        makePageTransparent();
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/my_matches`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData

        }).then((res) => {
            const UserData = res.data.status
            //console.log(res)
            if (UserData === 0) {
                setUsersData(UserData);
                makePagenotTransparent();
            }

            if (res.data.status === 1) {
                //console.log(res.data.path)
                if(res.data.data.length>0){
                    const proFormData = new FormData();
                    proFormData.append('user_id', local_storage.user_id);
                    proFormData.append('profile_id',res.data.data[0].user_id);
                    axios({
                        method: 'POST',
                        url: `${baseUrl}/Profiles/profile_view`,
                        headers: {
                            "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                            'X-api-key': local_storage.api_key,
                        },
                        data: proFormData
            
                    }).then((response) => {
                        if (response.data.status === 1) {
                            setTimeout(() => {
                                setdisplay({
                                    about_me: response.data.data.about_me,
                                    age: response.data.data.age,
                                    country_name: response.data.data.country_name,
                                    gender: response.data.data.gender,
                                    height: response.data.data.height,
                                    height_unit: response.data.data.height_unit,
                                    images: response.data.data.images,
                                    like_status: response.data.data.like_status,
                                    locked_status: response.data.data.locked_status,
                                    mother_tongue: response.data.data.mother_tongue,
                                    name: response.data.data.name,
                                    display_name: response.data.data.display_name,
                                    nationality_name: response.data.data.nationality_name.length>1? response.data.data.nationality_name[0]+','+response.data.data.nationality_name[1]:response.data.data.nationality_name[0],
                                    occupation_name: response.data.data.occupation_name,
                                    religion_name: response.data.data.religion_name,
                                    report_user_list_status: response.data.data.report_user_list_status,
                                    request_list_id: response.data.data.report_list_id,
                                    request_status: response.data.data.request_status,
                                    selfie_status: response.data.data.selfie_status,
                                    shortlist_status: response.data.data.shortlist_status,
                                    state_name: response.data.data.state_name,
                                    study_name: response.data.data.study_name,
                                    unique_id: response.data.data.unique_id,
                                    user_id: response.data.data.user_id,
                                    weight: response.data.data.weight,
                                    weight_unit: response.data.data.weight_unit,
                                    photos_slider_status: response.data.data.photos_slider_status,
                                    drink: response.data.data.drink,
                                    smoke: response.data.data.smoke,
                                    diet: response.data.data.diet,
                                })
                                // if (response.data.data.locked_status=='1'&& local_storage.subscription_status=='1'){
                                //     setDisplayProfile(true)
                                // }
                                // if(response.data.data.locked_status=='1'&&local_storage.subscription_status=='0'){
                                //     setDisplayProfile(false)
                                // }
                                // if(response.data.data.locked_status=='0'&&local_storage.subscription_status=='0'){
                                //     setDisplayProfile(true)
                                // } if(response.data.data.locked_status=='0'&&local_storage.subscription_status=='1'){
                                //     setDisplayProfile(true)
                                // }
                                //makePagenotTransparent();
                            }, 1000);
                        }
                    }).catch((error) => {
                        console.log(error);
                        //makePagenotTransparent();
                    })
                }

                const proFormData = new FormData();
                proFormData.append('user_id', local_storage.user_id);
                let profile_list = []
                path = res.data.data.length
                if (res.data.data.length >= 1) {
                    if (res.data.data.length >= 1) {
                        for (let d in res.data.data) {
                            profile_list.push(res.data.data[d])

                        }

                    }
                    setTimeout(() => {
                        setValue({ 'profiles': profile_list, 'path': res.data.path });
                        makePagenotTransparent();
                    }, 1000);
                }
                //setValue({'name':res.data.data[0].name,'country':res.data.data[0].country_name,'state':res.data.data[0].state_name,'image':`${res.data.path}/${res.data.data[0].image}`,})
            }
        }).catch((error) => {
            console.log(error);
            makePagenotTransparent();
        })

        axios({
            method: 'GET',
            url: `${baseUrl}/Profiles/report_list`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': Auth,
            }
        }).then((res) => {
            // console.log(res.data.data)
            setQuestons(res.data.data)
        }).catch((e) => {
            console.log(e)
        })

        try {
            let query = fref(fdb, `Frify_test_users/${local_storage.user_id}/conversations`);
            onValue(query, (snapshot) => {
                // console.log(snapshot.val().length)
                setlength(snapshot.val().length)

            })
        }
        catch (e) {
            console.log(e)
        }

    }, [reloadPage])

    useEffect(() => {
        // Function to be called when the Session Storage item changes
        const checkSessionStorageChange = () => {
            const storedValue = sessionStorage.getItem('notificationData');
            // Perform the desired action or call a function based on the stored value
            if (storedValue != reloadPage) {
                setReloadPage(storedValue)
                //console.log('Session Storage value changed:', storedValue);
            }
        };

        // Set up the interval to periodically check the Session Storage value
        const intervalId = setInterval(checkSessionStorageChange, 1000); // Change the interval duration as needed

        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    //console.log(reloadPage,'helloo')
    //   useEffect(() => {
    //     // Check the reload flag and reload the page if needed
    //     if(reloadPage!=sessionStorage.getItem('notificationDatad')){
    //         console.log('reload page','helloo')
    //         window.location.reload();
    //     }


    //   }, [reloadPage]);

    // const notificationCheck=()=>{
    //     sessionStorage.setItem('notificationDatad','hellonew')

    //     // const storageEvent = new Event('storage');
    //     // window.dispatchEvent(storageEvent);
    // }
    const getNotifyData = () => {
        let pushData = JSON.parse(sessionStorage.getItem('notificationData'))
        if (pushData) {
            setcallshow(true)
            setNotifydata(pushData)

        } else {
            setcallshow(false)

        }
        //console.log(pushData, 'pushdataaaa')
    }
    const call = (remotePeerId) => {
        var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        getUserMedia({ video: true, }, (mediaStream) => {

            currentUserVideoRef.current.srcObject = mediaStream;
            currentUserVideoRef.current.play();

            const call = peerInstance.current.call(remotePeerId, mediaStream)

            call.on('stream', (remoteStream) => {
                remoteVideoRef.current.srcObject = remoteStream
                remoteVideoRef.current.play();
            });
        });
    }

    rtc.client = AgoraRTC.createClient({
        mode: 'rtc',
        codec: "vp8"
    });
    //old
    // const localvideo = async (data, mute) => {
    //     await rtc.client.join(data.appID, data.Channel_name, data.Token, null);

    //     //settracks({audio:AgoraRTC.createMicrophoneAudioTrack(),video:AgoraRTC.createCameraVideoTrack()})
    //     rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();

    //     rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();


    //     await rtc.client.publish([rtc.localVideoTrack, rtc.localAudioTrack]);
    //     let localPlayerContainer = document.getElementById(`video-${local_storage.user_id}`)
    //     //let localPlayerContainerr=document.getElementById(`video-${display_list.user_id}`)
    //     rtc.localVideoTrack.play(localPlayerContainer);
    //     rtc.localAudioTrack.setEnabled(!mute)
    //     console.log(rtc.localAudioTrack, 'rtc.localAudioTrack')
    //     //rtc.localVideoTrack.play(localPlayerContainerr);
    //     console.log(Mute, 'mute')
    // }
    //new
    const localvideo = async (data, type) => {
        // console.log(data, 'DATAAA')
        await rtc.client.join(data.appID, data.Channel_name, data.Token, null);
        if (type == 'audio') {
            //settracks({audio:AgoraRTC.createMicrophoneAudioTrack(),video:AgoraRTC.createCameraVideoTrack()})
            let audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            //setrtc({...rtc, localAudioTrack:audioTrack})
            //let videoTrack = await AgoraRTC.createCameraVideoTrack();

            setrtc({ ...rtc, localVideoTrack: null, localAudioTrack: audioTrack })

            await rtc.client.publish([audioTrack]);
            let localPlayerContainer = document.getElementById(`video-${local_storage.user_id}`)
            // let localPlayerContainer = document.getElementById('videoatg')

            //let localPlayerContainerr=document.getElementById(`video-${display_list.user_id}`)
            //videoTrack.play(localPlayerContainer);
            //audioTrack.setEnabled(!mute)
            // console.log(rtc.localAudioTrack, 'rtc.localAudioTrack')
            //rtc.localVideoTrack.play(localPlayerContainerr);
            // console.log(Mute, 'mute')
            // const elems = Array.from(document.querySelectorAll("#video- div"))
            // elems.pop()
            // elems.map(node => node.parentNode.removeChild(node))
        } else if (type == 'video') {
            //settracks({audio:AgoraRTC.createMicrophoneAudioTrack(),video:AgoraRTC.createCameraVideoTrack()})
            let audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            //setrtc({...rtc, localAudioTrack:audioTrack})
            let videoTrack = await AgoraRTC.createCameraVideoTrack();

            setrtc({ ...rtc, localVideoTrack: videoTrack, localAudioTrack: audioTrack })

            await rtc.client.publish([videoTrack, audioTrack]);
            let localPlayerContainer = document.getElementById(`video-${local_storage.user_id}`)
            // let localPlayerContainer = document.getElementById('videoatg')

            //let localPlayerContainerr=document.getElementById(`video-${display_list.user_id}`)
            videoTrack.play(localPlayerContainer);
            //audioTrack.setEnabled(!mute)
            // console.log(rtc.localAudioTrack, 'rtc.localAudioTrack')
            //rtc.localVideoTrack.play(localPlayerContainerr);
            // console.log(Mute, 'mute')
            // const elems = Array.from(document.querySelectorAll("#video- div"))
            // elems.pop()
            // elems.map(node => node.parentNode.removeChild(node))
        }

    }
    // console.log(rtc, 'rtc')
    function remotevideo() {

        rtc.client.on("user-published", async (user, mediaType) => {
            await rtc.client.subscribe(user, mediaType);
            //rtc.localAudioTrack.setEnabled(mute)
            // console.log(user, mediaType, '1')

            if (mediaType === "video") {
                // Get the RemoteVideoTrack object in the AgoraRTCRemoteUser object.
                const remoteVideoTrack = user.videoTrack;
                if (NotifyData.other_user) {
                    let remotePlayerContainerNotify = document.getElementById(`video-${NotifyData.other_user.user_id}`)
                    remoteVideoTrack.play(remotePlayerContainerNotify);
                } else {
                    let remotePlayerContainer = document.getElementById(`video-${display_list.user_id}`)
                    remoteVideoTrack.play(remotePlayerContainer);
                }
                // console.log(user, mediaType, '2')
                // const elems = Array.from(document.querySelectorAll(`video-${display_list.user_id}div`))
                // elems.pop()
                // elems.map(node => node.parentNode.removeChild(node))
            }
            if (mediaType === "audio") {
                // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
                const remoteAudioTrack = user.audioTrack;
                // Play the remote audio track. No need to pass any DOM element.
                remoteAudioTrack.play();
                //console.log(user, mediaType, '3')

            }
        })

    }
    // function remotevideooff() {

    //     rtc.client.off("user-published", async (user, mediaType) => {
    //         await rtc.client.subscribe(user, mediaType);


    //         if (mediaType === "video") {
    //             // Get the RemoteVideoTrack object in the AgoraRTCRemoteUser object.
    //             const remoteVideoTrack = user.videoTrack;
    //             let remotePlayerContainer = document.getElementById(`video-${display_list.user_id}`)
    //             remoteVideoTrack.play(remotePlayerContainer);
    //         }
    //         if (mediaType === "audio") {
    //             // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
    //             const remoteAudioTrack = user.audioTrack;
    //             // Play the remote audio track. No need to pass any DOM element.
    //             remoteAudioTrack.play();

    //         }
    //     })

    // }
    const [userCallStatus, setUserCallStatus] = useState('3')
    //console.log(userCallStatus, 'userCallStatus')
    var interval;
    const call_status_api_web = (pid) => {
        const callData = new FormData();
        callData.append('user_id', local_storage.user_id);

        callData.append('profile_id', pid);

        axios({
            method: 'POST',
            url: `${baseUrl}/Audio_video_calls/call_status_api_web`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: callData
        }).then((res) => {
            //console.log(res, 'call data');
            setUserCallStatus(res.data.call_status)
            if (NotifyData.type == 8) {
                let audio = document.getElementById("audio");
                audio.pause()
            }
            // setTimeout(() => {
            //     if (res.data.call_status == 2) {
            //         endcall(1)
            //     }
            // }, 10000)

        }).catch((e) => {
            console.log(e);
        })
    }

    //call_status_api_web();
    // console.log(userCallStatus, 'test')
    const video_call = (val) => {
        let type;
        interval = setInterval(() => call_status_api_web(display_list.user_id), 5000)
        setvideo(val)
        if (display_list.call_status == 2 || display_list.call_status == 1) {
            setbusyShow(true)
        }
        else {
            let audio = document.getElementById("audio");
            if (display_list.call_status == 0) {
                audio.play();
            } else {
                audio.pause();
            }

            if (val == 1) {
                type = 'audio'
                setaudioshow(true)
                //alert('hi')

                setTimeout(() => {
                    let pushData = JSON.parse(sessionStorage.getItem('notificationData'))
                    //console.log(pushData)
                    if (pushData?.type != 8 || pushData == null) {
                        endcall(1)
                        window.location.reload(true);
                        sessionStorage.removeItem("notificationData");
                    }

                }, 30000);


            } if (val == 2) {
                type = 'video'
                setvideoshow(true)
                setTimeout(() => {
                    let pushData = JSON.parse(sessionStorage.getItem('notificationData'))
                    if (pushData?.type != 8 || pushData == null) {
                        endcall(2)
                        window.location.reload(true);
                        sessionStorage.removeItem("notificationData");
                    }

                }, 30000);
            }

            // if (val == 3 && NotifyData.type == 5) {
            //     setvideoshow(true)
            // } if (val == 3 && NotifyData.type == 12) {
            //     setaudioshow(true)
            // }

            const regFormData = new FormData();
            regFormData.append('user_id', local_storage.user_id)
            regFormData.append('receiver_id', display_list.user_id)

            // regFormData.append('video', true)
            if (val == 1 || NotifyData?.type == 12) {
                regFormData.append('video', false);
            } else {
                regFormData.append('video', true);
            }
            //regFormData.append('video', false)

            axios({
                method: 'POST',
                url: `${baseUrl}/Audio_video_calls/create_link`,
                headers: {
                    "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                    'X-api-key': local_storage.api_key,
                },
                data: regFormData
            }).then((res) => {
                //  console.log(res.data)
                //setvideo({ 'appID': res.data.appID, 'Token': res.data.Token, 'Channel_name': res.data.Channel_name })
                localvideo(res.data, type)
                remotevideo()

                //audio.pause();
                //rtc.client.init(app);
                // var localStream = AgoraRTC.createStream({ audio: true, video: true })
                // localStream.init(res.data.appID);  
                // let local=document.getElementById(`video-${local_storage.user_id}`)
                // let remote=document.getElementById(`video-${display_list.user_id}`)
                // localStream.play(local);
                // rtc.client.join(res.data.Token, res.data.Channel_name, res.data.receiver_id);
                // rtc.client.publish(localStream);
                //localvideo()
                //  remotevideo()
            }).catch((error) => {
                console.log(error)
            })
        }


    }
    // console.log(video)

    const endcall = (val) => {

        if (val == 1) {
            setaudioshow(false)
            sessionStorage.removeItem("notificationData");
            //window.top.location.reload(true)
            window.location.reload(true);
        } if (val == 2) {
            setvideoshow(false)
            sessionStorage.removeItem("notificationData");
            window.location.reload(true);
        } if (val == 3 && NotifyData?.type == 5) {
            setvideoshow(true)
            localvideo(NotifyData, 'video')
            remotevideo()
            const audioElement = document.getElementById('notificationAudio');
            if (audioElement) {
                audioElement.pause();
            }
        } if (val == 3 && NotifyData?.type == 12) {
            setaudioshow(true)
            //  console.log(NotifyData, 'nii')
            localvideo(NotifyData, 'audio')
            remotevideo()
            const audioElement = document.getElementById('notificationAudio');
            if (audioElement) {
                audioElement.pause();
            }
        } if (val == 4) {
            setcallshow(false)
            sessionStorage.removeItem("notificationData");
            window.location.reload(true);
        }

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        if (NotifyData.other_user) {
            regFormData.append('receiver_id', NotifyData?.other_user.user_id)
        } else {
            regFormData.append('receiver_id', display_list.user_id)
        }
        if (val == 1 || val == 2) {
            regFormData.append('status_id', 1)
            setcallshow(false)
        } if (val == 3) {
            regFormData.append('status_id', 3)
        } if (val == 4) {
            regFormData.append('status_id', 2)
        }
        if (val == 1) {
            regFormData.append('video', false);
        } else {
            regFormData.append('video', true);
        }
        // regFormData.append('video', true)

        //regFormData.append('video', false)

        axios({
            method: 'POST',
            url: `${baseUrl}/Audio_video_calls/end_call`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then(async (res) => {
            //  console.log(rtc, 'rtc')

            //rtc.localAudioTrack.close()
            //rtc.localVideoTrack.close()
            //rtc.localAudioTrack.stop()
            //rtc.localVideoTrack.stop()
            //rtc.client.unpublish()
            // const elems = Array.from(document.querySelectorAll(`video-5 div`))
            // elems.pop()
            // elems.map(node => node.parentNode.removeChild(node))
            //document.getElementById(`video-${local_storage.user_id}`).remove()
            //document.getElementById(`video-${display_list.user_id}`).remove()
            //await rtc.client.leave();
            //console.log(rtc.client,'leave')

            //window.location.reload();
            // rtc.localAudioTrack.setEnabled(mute)
            // rtc.localVideoTrack.setEnabled(mute)




            // rtc.localAudioTrack.stop()
            //rtc.localVideoTrack.stop()
            //rtc.localAudioTrack.close()
            // rtc.localVideoTrack.close()
            // rtc.leave();
            // console.log(res.data)

        }).catch((error) => {
            console.log(error)
        })
    }
    // console.log(NotifyData.type, 'typee')
    if (NotifyData?.type == 12 || NotifyData?.type == 5) {
        interval = setInterval(() => call_status_api_web(NotifyData?.other_user.user_id), 5000)

    }

    if (userCallStatus == '0' && (display_list.user_id != '' || NotifyData?.other_user.user_id)) {
        clearInterval(interval)
        if (NotifyData?.type == 12) {
            endcall(1)
        } else if (NotifyData?.type == 5) {
            endcall(2)
        }
        if (video == 1 || video == 2) {
            endcall(video)
        }
    }
    const handleskip = event => {
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        // console.log(new_id)

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/skip`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                list_profiles = list_profiles.filter((item) => item.key !== new_id)
                // console.log(list_profiles)
                document.getElementById(`${new_id}`).classList.add("display")
                document.getElementById(`item-${new_id}`).classList.add("display2")
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    const handleinterest = event => {
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        // console.log(new_id)

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/send_request`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                list_profiles = list_profiles.filter((item) => item.key !== new_id)
                // console.log(list_profiles)
                document.getElementById(`${new_id}`).classList.add("display")
                document.getElementById(`item-${new_id}`).classList.add("display2")
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    const handlelike = event => {
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        // console.log(new_id)

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/user_likes`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                if (res.data.message === "profile liked") {
                    //document.getElementById(`likes-${new_id}`).src=`${liked}`;
                    //document.getElementById(`likes-${new_id}`).disabled=true;
                    document.getElementById(`dlikes-${new_id}`).src = `${liked}`;
                } else {
                    //document.getElementById(`likes-${new_id}`).src=`${like}`;
                    document.getElementById(`dlikes-${new_id}`).src = `${like}`;
                }
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    const handlesshortlist = event => {
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        // console.log(new_id)

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/shortlist`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                list_profiles = list_profiles.filter((item) => item.key !== new_id)
                // console.log(list_profiles)
                document.getElementById(`${new_id}`).classList.add("display")
                document.getElementById(`item-${new_id}`).classList.add("display2")
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    //console.log(value)
    const [displayProfile, setDisplayProfile] = useState(true)
    const [iddata, setIddata] = useState('');

    const cardDisplay = event => {
        setcloseaction('true');
        makePageTransparent();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        //document.getElementById('three-dots').style.display = 'block'
        let id = event.currentTarget.id;
        //alert(id);
        setIddata(id);
        // console.log(id)
        const proFormData = new FormData();
        proFormData.append('user_id', local_storage.user_id);
        proFormData.append('profile_id', id)
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/profile_view`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: proFormData

        }).then((response) => {

            if (response.data.status === 1) {
                // console.log(response.data.data.about_me)
                setTimeout(() => {
                    setdisplay({
                        about_me: response.data.data.about_me,
                        age: response.data.data.age,
                        country_name: response.data.data.country_name,
                        gender: response.data.data.gender,
                        height: response.data.data.height,
                        height_unit: response.data.data.height_unit,
                        images: response.data.data.images,
                        like_status: response.data.data.like_status,
                        locked_status: response.data.data.locked_status,
                        mother_tongue: response.data.data.mother_tongue,
                        name: response.data.data.name,
                        display_name: response.data.data.display_name,
                        nationality_name: response.data.data.nationality_name.length > 1 ? response.data.data.nationality_name[0] + ',' + response.data.data.nationality_name[1] : response.data.data.nationality_name[0],
                        occupation_name: response.data.data.occupation_name,
                        religion_name: response.data.data.religion_name,
                        report_user_list_status: response.data.data.report_user_list_status,
                        request_list_id: response.data.data.report_list_id,
                        request_status: response.data.data.request_status,
                        selfie_status: response.data.data.selfie_status,
                        shortlist_status: response.data.data.shortlist_status,
                        state_name: response.data.data.state_name,
                        study_name: response.data.data.study_name,
                        unique_id: response.data.data.unique_id,
                        user_id: response.data.data.user_id,
                        weight: response.data.data.weight,
                        weight_unit: response.data.data.weight_unit,
                        referral_link: response.data.data.referral_link,
                        chat_id: response.data.data.chat_id,
                        subscription_status: response.data.data.subscription_status,
                        call_status: response.data.data.call_status,
                        photos_slider_status: response.data.data.photos_slider_status,
                        drink: response.data.data.drink,
                        smoke: response.data.data.smoke,
                        diet: response.data.data.diet,
                    })
                    if (response.data.data.locked_status == '1' && local_storage.subscription_status == '1') {
                        setDisplayProfile(true)
                    }
                    if (response.data.data.locked_status == '1' && local_storage.subscription_status == '0') {
                        setDisplayProfile(false)
                    }
                    if (response.data.data.locked_status == '0' && local_storage.subscription_status == '0') {
                        setDisplayProfile(true)
                    }
                    if (response.data.data.locked_status == '0' && local_storage.subscription_status == '1') {
                        setDisplayProfile(true)
                    }
                    makePagenotTransparent();
                }, 1000);
            }
        }).catch((error) => {
            console.log(error);
            makePagenotTransparent();
        })
    }

    // console.log(value.profiles.length)
    var list_profiles = value.profiles.map((item) =>
        <button className="card-button" key={item.user_id} onClick={cardDisplay} id={`${item.user_id}`} style={{ outline: 'none' }}>
            <Card className="matches_card mb-3 w-50" style={item.user_id == iddata ? { backgroundColor: '#ffdddd' } : {}}>
                <CardHeader>
                    <img src={`${item.selfie_status === '1' && item.photos_slider_status == '1' ? value.path + '/' + item.image : item.gender == '1' ? male : female}`} style={{ width: '80px', height: '80px', borderRadius: '50px', background: '#50555c', objectFit: 'contain' }} />
                </CardHeader>
                <Card.Body className="px-1">
                    <Card.Text className="loc_request">
                        <h6 className="profile_name">{item.name}, {item.age}</h6><br></br>
                        <p className="user_location"><img src={map} className="px-1" />{item.state_name != '' ? item.state_name + ',' : ''} {item.country_name}.</p>
                    </Card.Text>
                </Card.Body>

            </Card>
        </button>
    )
    const handleBlock = () => {
        //console.log(event.currentTarget.id)
        setblockshow(true)
    }
    const handleBlockclose = () => {
        //console.log(event.currentTarget.id)
        setblockshow(false)
    }
    const videoclose = () => {
        setvideoshow(false)
    }
    const audioclose = () => {
        setaudioshow(false)
    }
    const handleReport = () => {
        setreportshow(true)
    }
    const handleReportclose = () => {
        setreportshow(false)
    }
    function handlSelect(event) {
        let userId = event.currentTarget.id.split('-')[1]
        setUserselectedQ({ 'profile_id': userId, 'question': parseInt(event.target.value) })
    }
    const handleKeyPress = (evt) => {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        var keyCode = key;
        // console.log(keyCode, 'keyCode')
        key = String.fromCharCode(key);
        if (key.length == 0) return;
        var regex = /^[A-Za-z.,\b]+$/;
        if (count >= 150) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
            document.getElementById('150count').style.color = 'red';
            document.getElementById('150count').value = '150/150';
        } else {
            document.getElementById('150count').style.color = 'grey';
            if (count > 2) {

                if (keyCode == 188 || keyCode == 190 || keyCode == 32) {
                    return;
                }
            } else {
                if (!regex.test(key) || count >= 150) {
                    theEvent.returnValue = false;
                    if (theEvent.preventDefault) theEvent.preventDefault();
                }
            }
        }
    }
    const aboutInputs = (e) => {
        setCount(e.target.value.length)
        if (count < 151) {

            document.getElementById('150count').style.color = 'grey';

        }


    }
    //console.log(userselectedQ.question)
    const onReportSubmit = (data) => {
        //setDisable(true)
        //return false;
        // console.log(data);
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id);
        regFormData.append('profile_id', display_list.user_id);
        regFormData.append('report_id', userselectedQ.question);
        regFormData.append('description', data.description);
        //regFormData.append('api_key', 'cd38819260c30284a96411e1747f585a');
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/report_send`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData

        }).then((res) => {
            // console.log(res, 'ressss')
            // setDisable(false)
            if (res.data.status === 1) {
                // setTimeout(() => {
                //     navigate('/matchedprofiles', { state: location.state })
                // }, 3000);
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            } else {
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            }

        }).catch((e) => {
            console.log(e.response, 'e')
            // setDisable(false)
        })
    }
    const handleBlockapi = event => {
        //console.log(event.currentTarget.id)
        let userId = event.currentTarget.id.split('-')[1]
        //console.log(userId)

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id);
        regFormData.append('profile_id', userId)

        //console.log(lat)
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/block_user`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData

        }).then((res) => {
            if (res.data.status === 1) {
                let l_profiles = value.profiles.filter((item) => item.user_id !== userId)
                // console.log(l_profiles)
                setValue({ 'profiles': l_profiles, 'path': value.path })
                setdisplay({
                    user_id: ''
                })
                //document.getElementById(`${id}`).classList.add('display');
                //document.getElementById(`item${id}`).classList.add('display2');
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            } else {
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            }

        }).catch((error) => {
            console.log(error)
        })
        setblockshow(false)
    }
    //console.log(videocall)
    const aboutInput = (e) => {
        setCount(e.target.value.length)
    }
    const muteCall = () => {
        setMute(false)
        crtc.localAudioTrack.setEnabled(true)
        //localvideo(video)
    }
    const unmuteCall = () => {
        setMute(true)
        crtc.localAudioTrack.setEnabled(false)
        // localvideo(video)
    }

    // console.log(NotifyData?.type, 'typeee')
    // console.log(NotifyData, 'ooooo')
    // console.log(NotifyData?.other_user, 'ooooo')
    window.onbeforeunload = function () {
        //endcall(1)
        return endcall(1);
    }
    if (NotifyData.type == 8) {
        let audio = document.getElementById("audio");
        audio.pause()
    }
    let CallingStatus = "connected";
    const [shareprofile, setshare] = useState(false)
    const handleopenshare = () => {
        urlShortner()
        setTimeout(() => {
            setshare(true)
        }, 1000);

    }
    const handlecloseshare = () => {
        setshare(false)
    }
    const [shorturl, setshorturl] = useState('')
    const urlShortner = async () => {
        let longurl = `https://bu3is.krify.com/matchmade4u_web/#/profile/${local_storage.user_id}/${display_list.user_id}`
        setshorturl(longurl)
    }
    //console.log(shorturl)
    const handleClose = (event) =>{
        event.preventDefault(); // Prevent the default link behavior (e.g., navigating to a new page)
        
      }
      const [linkToCopy, setLinkToCopy] = useState('');
    useEffect(() => {
        // Assuming display_list.user_id becomes available asynchronously
        if (display_list && display_list.user_id) {
            const newLink = `https://bu3is.krify.com/matchmade4u_web/#/profile/${local_storage.user_id}/${display_list.user_id}`;
            setLinkToCopy(newLink);
        }
    }, [display_list]);
    const copyLink = () => {
        clipboardCopy(linkToCopy)
            .then(() => {
                Toastify({
                    text: "Link copied",
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
                // alert('Link copied to clipboard');
            })
            .catch((error) => {
                console.error('Error copying link:', error);
            });
    };
    return (
        <div className="d-flex">
             {shareprofile && shorturl != '' &&
                <div className="modal modal_blur" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header p-0 ">
                                <h5></h5>
                                <h5 className="modal-title">Share Profile</h5>
                                <button type="button" className="close share_close" onClick={handlecloseshare}>
                                    <span aria-hidden="true">&times;</span>
                                    
                                </button>
                               
                            </div>
                            <div className="text-center mb-3 mt-3">
                              
                                <WhatsappShareButton url={shorturl}>
                                    <WhatsappIcon size={32} round={true} className="mr-3"></WhatsappIcon>
                                </WhatsappShareButton>&nbsp;
                                <FacebookShareButton url={`${baseUrl}/profile/${local_storage.user_id}/${display_list.user_id}`}>
                                    <FacebookIcon size={32} round={true} ></FacebookIcon>
                                </FacebookShareButton>&nbsp;
                                <OverlayTrigger overlay={<Tooltip>{"Copy link"}</Tooltip>}>
                                    <i className="fa fa-copy" onClick={copyLink} ></i>
                                </OverlayTrigger>
                            </div>

                        </div>
                    </div>
                </div>

            }
            <div className="col-3 page_left" style={{ width: 'auto' }}>
                <Sidemenu />
            </div>
            {/* <Modal show={videoshow} onHide={videoclose}> */}
            <>
                {/* {values.map((v, idx) => (
        <Button key={idx} className="me-2 mb-2" onClick={() => handleShow(v)}>
          Full screen
          {typeof v === 'string' && `below ${v.split('-')[0]}`}
        </Button>
      ))} */}

                <Modal show={subshow} onHide={subClose}>
                    <Modal.Body className="text-center">Your Profile is not Subscribed</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => navigate('/payment')} className="btn_disabled">
                            Take Subscription
                        </Button>
                        <Button variant="secondary" onClick={subClose} className="btn_disabled">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={subshow1} onHide={subClose1}>
                    <Modal.Body className="text-center">Profile not subscribed</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={subClose1} className="btn_disabled">
                            ok
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={busyshow} onHide={subBusy}>
                    <Modal.Body className="text-center">Busy with someone else</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={subBusy} className="btn_disabled">
                            ok
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={videoshow} fullscreen={fullscreen} onHide={videoclose}>
                    <ModalHeader className="call_head"><p><b>{display_list.display_name}</b></p></ModalHeader>
                    <Modal.Body>
                        <div id='video_check'>
                            <div className="video_user" ref={ref} id={`video-${local_storage.user_id}`} style={{ width: '300px', height: '250px' }}><p></p>
                            </div>
                            {NotifyData.other_user ? <div ref={ref} id={`video-${NotifyData.other_user.user_id}`} style={{ height: '450px', }}>
                            </div> : <div ref={ref} id={`video-${display_list.user_id}`} style={{ height: '450px' }}>
                            </div>}
                        </div></Modal.Body>
                    <Modal.Footer className="video_footer">

                        <div className="text-center">

                            {Mute ? <Button variant="outline-light" >
                                <img src={mute} onClick={(e) => { muteCall() }} />
                            </Button> :
                                <Button variant="outline-light" >
                                    <img src={unmute} onClick={(e) => { unmuteCall() }} />
                                </Button>}
                            <Button variant="outline-light" onClick={() => endcall(2)} >
                                <img src={end} style={{ width: '50px' }} />
                            </Button>
                        </div>

                    </Modal.Footer>
                </Modal>
                <Modal show={audioshow} fullscreen={fullscreen} onHide={audioclose}>
                    <ModalHeader className="call_head"><p>{NotifyData.other_user ? <p><b>{NotifyData.other_user.display_name}</b></p> : <p><b>{display_list.display_name}</b></p>}</p></ModalHeader>
                    <Modal.Body> <div>
                        {NotifyData.other_user ? <Card.Img className="call_img" variant="top" src={`${NotifyData.other_user.selfie_status == '1' && NotifyData.other_user.photos_slider_status == '1' ? `https://bu3is.krify.com/matchmade/assets/album/${NotifyData.other_user.selfie_pic}` : NotifyData.other_user.gender == '1' ? male1 : female1}`} /> : <Card.Img className="call_img" variant="top" src={`${display_list.selfie_status == '1' && display_list.photos_slider_status == '1' ? value.path + '/' + display_list.images[0].image : display_list.gender == '1' ? male1 : female1}`} />}

                        {/* {NotifyData.other_user ? <Card.Img className="call_img" variant="top" src={`https://bu3is.krify.com/matchmade/assets/album/${NotifyData.other_user.selfie_pic}`} /> : <Card.Img className="call_img" variant="top" src={`${display_list.selfie_status == '1' && display_list.photos_slider_status == '1' ? value.path + '/' + display_list.images[0].image : display_list.gender == '1' ? male1 : female1}`} />} */}

                        {NotifyData.type != 8 && NotifyData.type != 12 && <p className="mt-4 cal_st">Calling...</p>}
                        {NotifyData.type == 8 && <p className="mt-4 cal_st">{CallingStatus}</p>}
                        {NotifyData.type == 12 && <p className="mt-4 cal_st">{CallingStatus}</p>}
                    </div></Modal.Body>
                    <Modal.Footer>

                        <div className="text-center">

                            {Mute ? <Button variant="outline-light" onClick={(e) => { muteCall() }}>
                                <img src={mute} />
                            </Button> :
                                <Button variant="outline-light" onClick={(e) => { unmuteCall() }}>
                                    <img src={unmute} />
                                </Button>}
                            <Button variant="outline-light" onClick={() => endcall(1)} >
                                <img src={end} style={{ width: '50px' }} />
                            </Button>
                        </div>

                    </Modal.Footer>
                </Modal>
            </>

            {/* </Modal> */}
            <Modal show={blockshow} onHide={handleBlockclose}>
                <Modal.Header closeButton className="justify-content-end" style={{ boderBottom: '0px' }}></Modal.Header>
                <Modal.Footer>

                    <div className="text-center">
                        <h5>Block User</h5>
                        <p>Are you sure you want to block {display_list.display_name} from viewing your profile or contacting you?</p>
                    </div>

                    <Button onClick={handleBlockapi} id={`block-${display_list.user_id}`} style={{ backgroundColor: 'red', color: 'white', border: '0px' }}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={handleBlockclose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={reportshow} onHide={handleReportclose}>
                <Modal.Header closeButton className="justify-content-end" style={{ boderBottom: '0px' }}></Modal.Header>
                <Modal.Footer>

                    <div className="text-center">
                        <h5>Report User</h5>
                        <p>Kindly let us know the reason you are reporting this profile.</p>
                    </div>
                    <div className="d-flex flex-column w-100">
                        <Form className="mt-3" onSubmit={handleSubmit(onReportSubmit)}>
                            <Form.Select aria-label="Default select example" onChange={handlSelect} id={`report-${id}`}>
                                {/* <option value=''>Open this select menu</option> */}
                                {questions.map((data, i) => {
                                    return (
                                        <option className={`${userselectedQ.question === data.report_id ? 'yes' : 'noo'}`} key={i} value={data.report_msg_id}>{data.report_message}</option>
                                    )
                                })}
                            </Form.Select>
                            {/* <span className='error_msg'> {errors.question?.type === 'required' && "Please select  "}</span> */}

                            <textarea
                                {...register("description", { required: true, minLength: 30, maxLength: 150 })}
                                onChange={aboutInputs}
                                onKeyPress={handleKeyPress}
                                className="form-control mt-4 about_textarea"
                                id="exampleFormControlTextarea1"
                                rows="6" placeholder="Please explain in detail"
                            />
                            <p id="150count" style={{ 'float': 'right' }}>{count}/150</p>
                            <span className='error_msg'> {errors.description?.type === 'required' && "Please explain "}</span>
                            {/* <span className='error_msg'> {errors.description?.type === 'minLength' && " Description should be 15 to 150 characters"}</span>
                            <span className='error_msg'> {errors.description?.type === 'maxLength' && " Description should be 15 to 150 characters"}</span> */}
                            <span style={{color:'red', fontSize:'14px'}}>Note: Please enter atleast 30 characters</span>
                            <Button variant="dark" type="submit" className="d-block m-auto mt-3 w-25 btn_disabled">
                                SUBMIT
                            </Button>
                        </Form>
                    </div>

                    {/* <Button  id={`report-${id}`} style={{backgroundColor:'red',color:'white',border:'0px'}}>
                    Submit
                </Button> */}

                </Modal.Footer>
            </Modal>
            {NotifyData.other_user && (NotifyData.type == 5 || NotifyData.type == 12) ? <Modal show={callshow} animation={false} onShow={handleModalShow} onHide={handleModalHide}>
                <Modal.Body>
                    {/* Add an audio element */}
                    <audio id="notificationAudio" autoPlay>
                        <source src={ring1} type="audio/mpeg" />
                        {/* Your browser does not support the audio element. */}
                    </audio>

                    <Card className="payment-bottom-card mb-3 text-center">
                        <Card.Body>
                            {/* <img
                                src={`https://bu3is.krify.com/matchmade/assets/album/${NotifyData.other_user.selfie_pic}`}
                                style={{ width: '80px', height: '80px', borderRadius: '50px' }}
                            /> */}
                            <img src={`${NotifyData.other_user.selfie_status === '1' && NotifyData.other_user.photos_slider_status == '1' ? value.path + '/' + NotifyData.other_user.selfie_pic : NotifyData.other_user.gender == '1' ? male : female}`} style={{ width: '80px', height: '80px', borderRadius: '50px', background: '#50555c', objectFit: 'contain' }} />
                            <p>
                                {NotifyData.title} from {NotifyData.other_user.display_name}
                            </p>
                        </Card.Body>
                        <Card.Footer className='d-flex justify-content-around'>
                            <button className='modal_btns' onClick={() => endcall(3)}>
                                <img src={accept} style={{ width: '50px' }} />
                            </button>
                            <button className='modal_btns' onClick={() => endcall(4)}>
                                <img src={decline} style={{ width: '50px' }} />
                            </button>
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>
                : ''}
            <div className="col-9 d-flex flex-column matches-page page_right">
                <div className="d-flex flex-row bg-white top-bar shadow">
                    <p style={{ fontWeight: 500 }}>My Matches</p>
                    <Modal
                        show={show}
                        onHide={() => setShow(false)}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Share your Profile
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
  
                            <RWebShare
                                data={{
                                    text: "Like humans, flamingos make friends for life",
                                    url: `${display_list.referral_link}`,
                                    title: "Flamingos",
                                }}
                                onClick={() => console.log("shared successfully!")}
                            >
                                <button>Share 🔗</button>
                            </RWebShare>

                        </Modal.Body>
                    </Modal>
                    <div className="menu-nav">
                        <div className="dropdown-container" tabindex="-1">
                            <div className="three-dots display_matches" id="three-dots" ></div>
                            <div className="dropdown">
                                <a onClick={handleBlock}><div>Block</div></a>
                                {display_list.report_user_list_status == '' ? <a onClick={handleReport}><div>Report</div></a> : ''}
                                <a onClick={handleopenshare}><div>Share this profile</div></a>
                                {/* <a><RWebShare
                                    data={{
                                        text: "Like humans, flamingos make friends for life",
                                        url: "https://i9zx7.test-app.link/UdA5IqAtqsb",
                                        title: "Flamingos",
                                    }}
                                    onClick={() => console.log("shared successfully!")}
                                >
                                    <a>Share this profile</a>
                                </RWebShare></a> */}
                                <a href="#" onClick={handleClose}><div className="cancel">Close</div></a>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="d-flex p-0 m-0">
                    <div className="user_info col-10 mt-4 mb-3 px-5">
                        <ul className="d-flex flex-column">{list_profiles}</ul>
                        {usersData == '0' ? <div class="d-flex justify-content-center align-items-center mt-5" >
                            <img src={NoData} />
                        </div> : ''}
                    </div>
                    <div>
                        {display_list.user_id !== '' ?
                            <div className="" style={{ right: '0', position: 'fixed' }} id={`item-${display_list.user_id}`}>
                                {closeaction == 'true' ?
                                    <Card className="right-card" id="right-card" style={{
                                        width: '401px', height: 'calc(100vh - 73px)',
                                        overflowY: 'auto', overflowX: 'hidden'
                                    }}>
                                        {display_list.images.length > 1  
                                            ?
                                            <div style={{ backgroundColor: '#c4c4c4' }}>
                                                <span style={{ visibility: 'hidden' }}> aa </span>
                                                <span className="close_button float-end px-2" onClick={close} >
                                                    <img src={deleteicon} class="close-button" alt="" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                                                </span>
                                                <div>
                                                    <Carousel>
                                                        {display_list.images.map((particular_image, imageindex) =>
                                                            <Carousel.Item key={imageindex} style={{ width: '400px', height: '300px', overflow: 'hidden', borderRadius: '0px', backgroundColor: '#c4c4c4' }}>
                                                                <Card.Img variant="top" src={`${display_list.selfie_status == '1'  ? value.path + '/' + display_list.images[imageindex].image : display_list.gender == '1' ? male1 : female1}`} style={{ width: '100%', height: '100%', objectFit: 'contain', top: '0' }} />
                                                            </Carousel.Item>
                                                        )}
                                                    </Carousel>
                                                </div>
                                            </div>

                                            :
                                            <div style={{ color: "red", backgroundColor: '#c4c4c4' }}>
                                                <span className="close_button float-end px-2" onClick={close} >
                                                    <img src={deleteicon} class="close-button" alt="" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                                                </span>
                                                <Card.Img variant="top" src={`${display_list.selfie_status == '1' && display_list.images.length == '1' ? value.path + '/' + display_list.images[0].image : display_list.gender == '1' ? male1 : female1}`} style={{ width: '400px', height: '300px', top: '0', backgroundColor: '#c4c4c4', objectFit: 'contain' }} />
                                            </div>
                                        }
                                        <Card.Body>
                                            <Card.Title><p style={{ fontWeight: 500 }}>{display_list.display_name},  {display_list.age}, {display_list.gender == 1 ? "Male" : "Female"} <span style={{ color: 'red' }}>(#{display_list.unique_id})</span></p>
                                                <p><img src={map} className="px-1" />{display_list.state_name == '' ? '' : display_list.state_name + ','} {display_list.country_name}.</p>
                                                {display_list.request_status == '4' ? <span style={{ color: 'red' }}>{display_list.display_name} sent you a request</span> : ''}
                                                {displayProfile ? <> <p style={{ fontWeight: 500 }}>About me</p>
                                                    <p>{display_list.about_me}</p></> : ''}
                                            </Card.Title>

                                            {displayProfile ? <Card.Text>
                                                <div className="profile_data">
                                                    <div><span>Languages</span></div>
                                                    <div><span>{display_list.mother_tongue}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Nationality</span></div>
                                                    <div><span>{display_list.nationality_name}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Country</span></div>
                                                    <div><span>{display_list.country_name}</span></div>
                                                </div>
                                                {display_list.state_name != '' ? <div className="profile_data">
                                                    <div><span>State</span></div>
                                                    <div><span>{display_list.state_name}</span></div>
                                                </div> : ''}
                                                <div className="profile_data">
                                                    <div><span>Occupation</span></div>
                                                    <div><span>{display_list.occupation_name}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Height</span></div>
                                                    <div><span>{display_list.height} {display_list.height_unit}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Weight</span></div>
                                                    <div><span>{display_list.weight}</span></div>
                                                </div>
                                                {display_list.study_name &&
                                                    <div className="profile_data">
                                                        <div><span>Education</span></div>
                                                        <div><span>{display_list.study_name}</span></div>
                                                    </div>}
                                                <div className="profile_data">
                                                    <div><span>Religion</span></div>
                                                    <div><span>{display_list.religion_name}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Smoke</span></div>
                                                    <div><span>{display_list.smoke}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Drink</span></div>
                                                    <div><span>{display_list.drink}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Diet</span></div>
                                                    <div><span>{display_list.diet}</span></div>
                                                </div>
                                                <hr className="m-0 p-0" style={{ color: "#ff0000", backgroundColor: "#ff0000" }}></hr>
                                            </Card.Text> : <>
                                                <div className="pl_bg">
                                                    <p>This Profile is locked. subscribe to view the profile</p>
                                                </div>
                                                <Button variant="primary" type="submit" className="register_button w-100 mt-5" onClick={() => navigate('/payment')}>
                                                    SUBSCRIBE
                                                </Button>
                                            </>}
                                        </Card.Body>
                                        {displayProfile ? <Card.Footer className="d-flex justify-content-evenly mb-3">
                                            <button className="action-buttons"><img src={msg} className="mx-2" onClick={createChat} /></button>

                                            <button className="action-buttons" onClick={(e) => video_call(1)}><img src={audio} className="mx-2" /></button>
                                            <audio id="audio" src={ring}></audio>
                                            <button className="action-buttons" onClick={(e) => video_call(2)}><img src={videoicon} className="mx-2" />
                                                {videocall ? <VideoCall app_id={video.appID} channel={video.Channel_name} token={video.Token} /> : ''}
                                            </button>

                                        </Card.Footer> : ''}
                                    </Card> : ""}
                            </div> : ''}
                    </div>
                </div>
            </div>
            <div id="overlay">
                <div className='AddloadingImage'>
                    <LottieLoader3 />
                </div>
            </div>
        </div>
    )
}

export default Matches;