import axios from "axios";
import React, { useState, useEffect } from "react";

function Termsuse() {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const defaultAuth = process.env.REACT_APP_USER_TOKEN
const [content,setContent] = useState('')
    //console.log(typeof(defaultAuth))
    useEffect(() => {
        axios({
            method: 'GET',
            url: `${baseUrl}/Policies/terms_use`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': defaultAuth,
            }
        }).then((res)=>{
            // console.log(res.data.data[0].description)
            setContent(res.data.data[0].description)
        }).catch((e)=>{
            console.log(e.response)
        })
    })
    //console.log(content)
    return (
        <div className="d-flex privacy_page">
        <div className="col-md-12 col-sm-12 mt-5 content">
        <h3 className="text-center mb-5">Terms Use</h3>
       <p className="terms_content">{content}</p>
       </div>
   </div>
    )
}

export default Termsuse;