import React from "react";
import img1 from "../../assets/images/footer_logo.png";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faXTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate();

    return (
        <div className="footer">
            <div className="foot_1">
                <div>
                    <img src={img1} />
                </div>
                <div>
                    <ul className="footer_bottom">
                        <div className="d-flex mt-3">
                            <Nav.Link onClick={() => navigate('/')} className="px-4 text-white">Home</Nav.Link>
                            <Nav.Link onClick={() => navigate('/aboutpage')} className="px-4 text-white">About</Nav.Link>
                            <Nav.Link onClick={() => navigate('/membership')} href="#" className="px-4 text-white">Membership</Nav.Link>
                        </div>
                        <div className="mt-4 mb-3">
                            <Link to='#' className="footer_links" rel="noopener noreferrer">Terms</Link>
                            <Link to='#' className="footer_links" rel="noopener noreferrer">Privacy</Link>
                            <Link to='#' className="footer_links" rel="noopener noreferrer">Cookies</Link>
                        </div>
                    </ul>
                </div>
                <div>
                    <FontAwesomeIcon icon={faLinkedinIn} className="footer-icons" />
                    <FontAwesomeIcon icon={faFacebookF} className="footer-icons" />
                    <FontAwesomeIcon icon={faXTwitter} className="footer-icons" />
                    <FontAwesomeIcon icon={faInstagram} className="footer-icons" />
                </div>
            </div>
            <div className="foot_2">
                <p className="text-center text-white">© Copyright {new Date().getFullYear()} frify. All Rights Reserved.</p>
            </div>
        </div>
    )
}

export default Footer;