import React from 'react'
import Header from "./header";
import Footer from "../footer/footer";
import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import logo from "../../assets/images/sitelogo.png";
import { useNavigate } from 'react-router-dom';

function Aboutpage() {
  const navigate = useNavigate();

  return (
    <div>
      <Navbar expand="lg" className="home_data">
        <Container fluid>
          <Navbar.Brand href="/" className="col-6">
            <img
              src={logo}
              style={{ display: 'block', margin: 'auto' }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-lg-0 menu col-6"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Nav.Link onClick={() => navigate('/')} className="px-4 text-black">Home</Nav.Link>
              <Nav.Link onClick={() => navigate('/aboutpage')} className="px-4 active text-black">About</Nav.Link>
              <Nav.Link onClick={() => navigate('/membership')} href="#" className="px-4 text-black">Membership</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className='aboutpage_bg d-flex'>
        <div className='col-6'></div>
        <div className='col-6 p-5'><h2 className='text-center mb-5'>About Us</h2>
          <p>
            Time devours all things but love. . . </p>

          <p>Frify takes great pride in giving you a safe platform  to connect with amazing matches in your area and beyond </p>


          <p>We will help you harness the intoxicating power of love to create happiness that endures a lifetime</p>

          <p>Forget the days of awkward online
            Dating and become part of a community where you can create real magic online and IRL</p>

          <p> We have guidelines in place to protect each and every one of our members. Your safety is our priority. Our team is always here to be at your service</p>

          <p>Dating is a way to walk the path you were destined to. It can lead to adventures , love , your soul mate. The possibilities are endless …</p>

          <p className='blink-soft'>So, stop waiting for love and take a chance at Frify and make it happen !
          </p>
        </div>

      </div>
      <Footer />
    </div>

  )
}

export default Aboutpage;