import React, { useEffect } from "react";
import { Form, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import img from '../../assets/images/sitelogo.png'
import { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import $ from 'jquery';
import 'react-phone-number-input/style.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import SocialButton from "./SocialButton";
import AppleSignin from 'react-apple-signin-auth';
import LottieLoader from '../lottie/lottieloader';
import './login.css'
import LottieLoader3 from '../lottie/lottieloader3';
import { isChrome } from 'react-device-detect';
import OneSignal from 'react-onesignal';
import runOneSignal from "./onesignal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Login() {

  function toastfunction() {
    navigate('/');
    Toastify({
      text: "Please Select Gender Preferences",
      duration: 2000,
      newWindow: true,
      close: true,
      gravity: "top", // `top` or `bottom`
      position: "right", // `left`, `center` or `right`
      stopOnFocus: true, // Prevents dismissing of toast on hover
      style: {
        background: "linear-gradient(to right, #00b09b, #96c93d)",
      },
      onClick: function () { } // Callback after click
    }).showToast();
  }
  //console.log(device_id)
  const shouldShowAppleSignIn = !isChrome; // Hide if Chrome, show for other browsers/platforms
  const [country, setcountry] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [disable, setDisable] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2 } = useForm();
  const [passwordShown, setPasswordShown] = useState(false);
  const [value, setValue] = useState(1)
  const [loc, setLocation] = useState({ 'lat': '', 'lng': '' });
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = window.location.href;

  //local device_id 
  const device_id = JSON.parse(localStorage.getItem('userId'))
  const getId = async () => {
    let device_id = await OneSignal.getUserId();
    console.log(device_id, 'device_id')
    if (device_id) {
      return device_id
    }
  }

  function makePageTransparent() {
    var overlay = document.getElementById('overlay');
    overlay.style.display = 'block';
  }

  function makePagenotTransparent() {
    var overlay = document.getElementById('overlay');
    overlay.style.display = 'none';
  }
  const prefer_gender = location.state

  const handleGoogleLogin = (user) => {

    const regFormData = new FormData();
    regFormData.append('social_type', '1');
    regFormData.append('reg_type', '1');
    regFormData.append('platform', '3');
    regFormData.append('gender', location.gender);
    regFormData.append('prefer_gender', location.looking);
    regFormData.append('name', user.profile.name);
    regFormData.append('email', user.profile.email);
    regFormData.append('social_id', user.profile.id);

    axios({
      method: 'POST',
      url: `${baseUrl}/Registration`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': Auth,
      },
      data: regFormData

    }).then((res) => {
      //console.log(res,'google')
      //console.log(res.data.data[0].selfie_screen_status)
      localStorage.setItem('gAuth', "true");

      if (res.data.data[0].email != user.profile.email) {

        setTimeout(() => {
          navigate('/google_password', { state: res.data.data[0] })
        }, 2000);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        setTimeout(() => {
          navigate('/matchedprofiles', { state: res.data.data[0] })
        }, 2000);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      console.log(e, 'e')
    })
    //console.log(user);

  };

  const handleFacebookLogin = (user) => {

    const regFormData = new FormData();
    regFormData.append('social_type', '2');
    regFormData.append('reg_type', '1');
    regFormData.append('platform', '3');
    regFormData.append('gender', location.gender);
    regFormData.append('prefer_gender', location.looking);
    regFormData.append('name', user.profile.name);
    regFormData.append('email', user.profile.email);
    regFormData.append('social_id', user.profile.id);

    axios({
      method: 'POST',
      url: `${baseUrl}/Registration`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': Auth,
      },
      data: regFormData

    }).then((res) => {
      //  console.log(res.data, 'ressss')

      if (res.data.status === 1) {
        setTimeout(() => {
          navigate('/facebook_password', { state: res.data.data[0] })
        }, 2000);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      console.log(e, 'e')
    })

    //console.log(user.profile);
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  //Registartion API
  const onSignupSubmit = async (submitdata) => {

    if (selectedOption === null) {
      Toastify({
        text: "Please select country code",
        duration: 2000,
        close: true,
        gravity: "top", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        onClick: function () { }
      }).showToast();
      return false;
    }
    //server device_id
    //let device_id = await OneSignal.getUserId();

    setDisable(true)
    //  console.log(submitdata);
    const regFormData = new FormData();
    regFormData.append('name', submitdata.name);
    //regFormData.append('reg_type', '1');
    //regFormData.append('email', submitdata.email);
    regFormData.append('mobile', submitdata.mobile);
    regFormData.append('password', submitdata.password);
    regFormData.append('platform', '3');
    regFormData.append('device_id', 12121212);
    regFormData.append('country_code', selectedOption.code);
    regFormData.append('gender', submitdata.gender);
    //regFormData.append('prefer_gender', location.state.looking);
    //console.log(location.state.gender,location.state.looking)
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': Auth,
      },
      data: regFormData

    }).then((res) => {
      localStorage.setItem("userDetails", JSON.stringify(res.data.data));
      console.log(res.data.data, 'ressss')
      setDisable(false)

      if (res.data.status === 1) {
        // res.data.data[0].gender = location.state.gender;
        // res.data.data[0].looking = location.state.looking
        // localStorage.setItem('prefer_gender', location.state.looking)
        localStorage.setItem('gAuth', "true");
        setTimeout(() => {
          navigate('/otp', { state: res.data.data[0] })
        }, 3000);
       
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      console.log(e, 'e')
      setDisable(false);
    })
  }

  //Country Code
  //console.log(selectedOption)
  const handleSelect = (e) => {
    setSelectedOption(e);
    // console.log(e, 'handleSelect')
  };
  const [toggle, setToggle] = useState(1);
  const toggleTabs = (val) => {

    setToggle(val)
    //  console.log(val)
    if (val === 1) {
      document.getElementById('login-button').style.backgroundColor = "#ff1a8c";
      document.getElementById('login-button').style.color = "#ffffff";
      document.getElementById('signup-button').style.backgroundColor = "#ffffff";
      document.getElementById('signup-button').style.color = "#ff1a8c";
    } else {
      document.getElementById('signup-button').style.backgroundColor = "#ff1a8c";
      document.getElementById('signup-button').style.color = "#ffffff";
      document.getElementById('login-button').style.backgroundColor = "#ffffff";
      document.getElementById('login-button').style.color = "#ff1a8c";
    }
  }

  // console.log(location)

  const handlePasswordChange = (e) => {
    if (checkStrength(e.target.value) == false) {
      $('#sign-up').attr('disabled', true);
    }
  }

  const checkStrength = (password) => {
    var strength = 0;


    //If password contains both lower and uppercase characters, increase strength value.
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      strength += 1;
      $('.active_val').addClass('text-success pass_val');
      $('.low-upper-case i').removeClass('fa-file_text').addClass('');
      $('#popover-password-top').addClass('hide');


    } else {
      $('.active_val').removeClass('text-success pass_val');
      $('.low-upper-case i').addClass('fa-file_text_fail').removeClass('');
      $('#popover-password-top').removeClass('hide');
    }

    //If it has numbers and characters, increase strength value.
    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
      strength += 1;
      $('.active_val1').addClass('text-success pass_val');
      $('.one-number i').removeClass('fa-file_text').addClass('k');
      $('#popover-password-top').addClass('hide');

    } else {
      $('.active_val1').removeClass('text-success pass_val');
      $('.one-number i').addClass('fa-file_text').removeClass('');
      $('#popover-password-top').removeClass('hide');
    }

    //If it has one special character, increase strength value.
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      strength += 1;
      $('.active_val2').addClass('text-success pass_val');
      $('.one-special-char i').removeClass('fa-file_text').addClass('');
      $('#popover-password-top').addClass('hide');

    } else {
      $('.active_val2').removeClass('text-success pass_val');
      $('.one-special-char i').addClass('fa-file_text').removeClass('');
      $('#popover-password-top').removeClass('hide');
    }

    if (password.length > 7) {
      strength += 1;
      $('.active_val3').addClass('text-success pass_val');
      $('.eight-character i').removeClass('fa-file_text').addClass('');
      $('#popover-password-top').addClass('hide');

    } else {
      $('.active_val3').removeClass('text-success pass_val');
      $('.eight-character i').addClass('fa-file_text').removeClass('');
      $('#popover-password-top').removeClass('hide');
    }

    // If value is less than 2
    if (strength < 2) {
      $('#result').removeClass()
      $('#password-strength').addClass('progress-bar-danger');

      $('#result').addClass('text-danger').text('Very Week');
      $('#password-strength').css('width', '10%');
    } else if (strength == 2) {
      $('#result').addClass('good');
      $('#password-strength').removeClass('progress-bar-danger');
      $('#password-strength').addClass('progress-bar-warning');
      $('#result').addClass('text-warning').text('Week')
      $('#password-strength').css('width', '60%');
      return 'Week'
    } else if (strength == 4) {
      $('#result').removeClass()
      $('#result').addClass('strong');
      $('#password-strength').removeClass('progress-bar-warning');
      $('#password-strength').addClass('progress-bar-success');
      $('#result').addClass('text-success pass_val').text('Strength');
      $('#password-strength').css('width', '100%');

      return 'Strong'
    }
  }
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  useEffect(() => {
    setTimeout(() => {
      sessionStorage.setItem('data', true)
    }, 10000);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          //console.log(latitude, longitude, '3')
        },
        (error) => {
          console.error('Error getting locaation:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser ');
    }

    if (location.state !== null) {
      setToggle(2);
      document.getElementById('signup-button').style.backgroundColor = "#ff1a8c";
      document.getElementById('signup-button').style.color = "#ffffff";
      document.getElementById('login-button').style.backgroundColor = "#ffffff";
      document.getElementById('login-button').style.color = "#ff1a8c";
    } else {
      document.getElementById('login-button').style.backgroundColor = "#ff1a8c";
      document.getElementById('login-button').style.color = "#ffffff";
      document.getElementById('signup-button').style.backgroundColor = "#ffffff";
      document.getElementById('signup-button').style.color = "#ff1a8c";
    }
    $(document).ready(function () {

      $('#password').keyup(function () {
        var password = $('#password').val();
        if (checkStrength(password) == false) {
          $('#sign-up').attr('disabled', true);
        }
      });
      $('#confirm-password').blur(function () {
        if ($('#password').val() !== $('#confirm-password').val()) {
          $('#popover-cpassword').removeClass('hide');
          $('#sign-up').attr('disabled', true);
        } else {
          $('#popover-cpassword').addClass('hide');
        }
      });
    });

    axios({
      // mode: 'no-cors',
      method: 'GET',
      url: `${baseUrl}/Registration/country_code`,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': Auth,
      }
    }).then((response) => {
      // console.log(response.data.data)
      // console.log(response.data.countries_nationalities);
      setcountry(response.data.data)
    }).catch((e) => {
      console.log(e, 'E')

    })
  }, [latitude, longitude]);

  let Flagurl = 'https://frify.com/backend/assets/flags/';
  let newdata = country.map((item, i) => {
    return {
      value: item.country_name,
      text: `${item.country_name + ' + ' + item.code}`,
      code: item.code,
      icon: <img src={Flagurl + item.country_flag} width='18px' />
    };
  });

  //Login API
  const onLoginSubmit = async (submitdata) => {
    // console.log(loc);
    setDisable(true)
    //server device_id
    //let device_id = await OneSignal.getUserId()

    const loginFormData = new FormData();
    loginFormData.append('latitude', latitude);
    loginFormData.append('longitude', longitude);
    loginFormData.append('email_mobile', submitdata.email_mobile);
    loginFormData.append('password', submitdata.password);
    loginFormData.append('device_id', 12121212);
    loginFormData.append('platform', '3');
    //console.log(loginFormData);

    axios({
      method: 'POST',
      url: `${baseUrl}/Login`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': Auth,
      },
      data: loginFormData
    }).then((res) => {
      setDisable(false);
      if (res.data.status === 1 && res.data.data[0].mobile_verified === '0') {
        setTimeout(() => {
          localStorage.setItem('gAuth', "true");
          navigate('/Otp', { state: res.data.data[0] })
        }, 2500);
      } else if (res.data.status === 1) {
        localStorage.setItem("userDetails", JSON.stringify(res.data.data[0]));
        setTimeout(() => {
          localStorage.setItem('gAuth', "true");
          navigate('/matchedprofiles', { state: res.data.data[0] });
        }, 2500);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { }
        }).showToast();
      } else if (res.data.status === 0) {
        // console.log('document')
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { }
        }).showToast();
      }
    }).catch((e) => {
      console.log(e, 'e')
      setDisable(false)

    })

  }
  const responseGoogle = (response) => {
    //  console.log(response);
  }
  return (

    <div className="d-flex" style={{ height: '100vh' }}>
      <div id="overlay">
        <div className='AddloadingImage'>
          <LottieLoader3 />
        </div>
      </div>
      {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}
     
      <div className="col-6 reg_bg">

        {/* <div className="social_buttons text-center mt-3">
          <img src={img} /><br></br>
          {shouldShowAppleSignIn && (
            <AppleSignin
              authOptions={{
                clientId: '79K8849A4G',
                scope: 'com.mm4u',
                redirectURI: `${baseUrl}`,
                state: '',
                nonce: 'nonce',
                usePopup: true,
              }}
              uiType="light"
              className="apple-auth-btn"
              buttonExtraChildren="Sign in with Apple"
            />
          )}

          <SocialButton
            className="google_social_button mt-3 w-50"
            provider="google"
            appId="770511414431-bskv7im9751mgv578vt5s4n0n53ur9h9.apps.googleusercontent.com"
            redirect="http://localhost:3000"
            //UxMode='redirect'
            onLoginSuccess={handleGoogleLogin}
            onLoginFailure={handleSocialLoginFailure}
          //CookiePolicy={'single_host_origin'}
          >
            <i className="fab fa-google google_fafa"></i> Sign in with Google
          </SocialButton> <br></br>
          <SocialButton
            className="facebook_social_button"
            provider="facebook"
            appId="750760296146788"
            onLoginSuccess={handleFacebookLogin}
            onLoginFailure={handleSocialLoginFailure}
          >
            <i className="fab fa-facebook facebook_fafa"></i> Continue with Facebook
          </SocialButton>

        </div> */}
      </div>

      <div className="col-6 mt-5 content_login">
        <img src={img} className="d-block m-auto" />
        <ul className="nav nav-pills mb-3 mt-3 justify-content-center" id="pills-tab" role="tablist">
          <div className="bg-white p-2 d-flex  justify-content-center" style={{
            borderRadius: '40px',
            boxShadow: '0px 0px 3px #c3baba'
          }} >
            <li className="nav-item" role="presentation">
              <button onClick={(e) => toggleTabs(1)} id="login-button" className="nav-link px-5" type="button" >Login</button>
            </li>
            <li className="nav-item" role="presentation">
              <button onClick={(e) => toggleTabs(2)} className="nav-link signup px-5" id="signup-button" type="button" >Sign Up</button>
            </li>
          </div>
        </ul>
        <div>
          {toggle === 1 ?
            <div id="login-form">

              <div>

                <Form className="mt-5" onSubmit={handleSubmit2(onLoginSubmit)}>
                  <Form.Group className="mb-3" >
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control {...register2("email_mobile", { required: true })} type="text" placeholder="Enter your mobile number" className="p-3" style={{ borderRadius: '30px' }} />
                    <span className='error_msg'> {errors2.email_mobile?.type === 'required' && "Please enter mobile number "}</span>
                  </Form.Group>

                  <Form.Group className="mb-3" >
                    <Form.Label>Password </Form.Label>
                    <Form.Control {...register2("password", { required: true })} type={passwordShown ? "text" : "password"} placeholder="Enter your Password" autoComplete="off" className="p-3" style={{ borderRadius: '30px' }} />
                    <span className='error_msg'> {errors2.password?.type === 'required' && "Please enter password "}</span>
                    <span className="pass_text" onClick={togglePassword}>
                      {passwordShown ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </span>
                  </Form.Group>

                  <Form.Group className="mb-3 d-flex justify-content-between" >
                    {/* <Form.Check type="checkbox" label="Remember me" /> */}
                    <Link to='/forgotpassword' className="for_pass" rel="noopener noreferrer">Forgot Password?</Link>
                  </Form.Group>

                  <Button variant="primary" type="submit" className="login_button">
                    Login
                  </Button><br></br>
                  <p className="error-msg" id="invalid-login"></p>
                  {/* <p className="mt-3 text-center">Don't have an account? <span onClick={(e) =>   toggleTabs(2) } className="login_buttons">Sign up</span></p> */}
                  <div>
                  </div>
                </Form>
              </div>
            </div> :
            <div id="signup-form">
          

              <Form className="mt-3" onSubmit={handleSubmit(onSignupSubmit)} >

                <Form.Group className="mb-3 mt-3" >
                  <Form.Label>Name</Form.Label>
                  <Form.Control {...register("name", { required: true, minLength: 3, maxLength: 20 })} type="text" placeholder="Enter name" className="inputp" style={{ borderRadius: '30px' }}/>
                  <span className='error_msg'> {errors.name?.type === 'required' && "Please enter name "}</span>
                  <span className='error_msg'> {errors.name?.type === 'minLength' && "Name should be min 3 and max 20 characters"}</span>
                  <span className='error_msg'> {errors.name?.type === 'maxLength' && "Name should be min 3 and max 20 characters"}</span>
                </Form.Group>

                <div>
                  <Form.Group className="mb-3" >
                    <Form.Label>Mobile Number</Form.Label>
                    <div className="country_code">
                      <Select
                        className="select_country col-3"
                        components={{ IndicatorSeparator: () => null }}
                        placeholder={<p className="select_placeholder"><i className="fa fa-globe"></i></p>}
                        value={selectedOption}
                        options={newdata}
                        onChange={handleSelect}
                        defaultCountryCode="+91"
                        // menuIsOpen={true}
                        getOptionLabel={e => (
                          <div style={{ display: 'flex', alignItems: 'center' }} >
                            {e.icon}
                            <span style={{ marginLeft: 5 }}> +{e.code} </span>
                          </div>
                        )}
                      />
                      {/* {errors.code?.type === 'required' && <span className="error_msg">Please select country code</span>} */}
                      <Form.Control {...register("mobile", { required: true, minLength: 10, maxLength: 15 })} type="number" placeholder="Enter your mobile number" className="col-9 inputp number_field" style={{ borderRadius: '30px' }}/>
                    </div>
                    {/* {errors.code?.type === 'required' && <span className="error_msg country_code">Please select country code</span>}  */}
                    {errors.mobile?.type === 'required' && <span className="error_msg">Please enter Mobile number</span>}
                    {/* <span className='error_msg'> {errors.mobile?.type === 'required' && "Phone Number is Required"}</span> */}
                    <span className='error_msg'> {errors.mobile?.type === 'maxLength' && "mobile number should be 10-15 numbers"}</span>
                    <span className='error_msg'> {errors.mobile?.type === 'minLength' && "mobile number should be 10-15 numbers"}</span>

                  </Form.Group>
                </div>
                {/* <Form.Group className="mb-3" >
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} type="text" placeholder="Enter your email" />
                  <span className='error_msg'> {errors.email?.type === 'required' && "Please enter email address"}</span>
                  <span className='error_msg'>{errors.email?.type === 'pattern' && "Please enter valid E-mail address"}</span>
                </Form.Group> */}

                <Form.Group >
                  <Form.Label>Password</Form.Label>
                  <Form.Control {...register("password", { required: true, minLength: 8, maxLength: 16, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ })} type={passwordShown ? "text" : "password"} placeholder="Enter your Password" id="password" onChange={handlePasswordChange} className="inputp" style={{ borderRadius: '30px' }}/>
                  <span className="pass_text" onClick={togglePassword}>
                      {passwordShown ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </span>
                  <span className='error_msg'> {errors.password?.type === 'required' && "Please enter password"}</span>
                  <span className='error_msg'> {errors.password?.type === 'minLength' && " Password should be min 8 and max 16 characters with atleast 1 upper, 1 lower, 1 numeric and 1 special character"}</span>
                  <span className='error_msg'> {errors.password?.type === 'maxLength' && "  Password should be min 8 and max 16 characters with atleast 1 upper, 1 lower, 1 numeric and 1 special character"}</span>
                  <span className='error_msg'> {errors.password?.type === 'pattern' && " Password should be min 8 and max 16 characters with atleast 1 upper, 1 lower, 1 numeric and 1 special character"}</span>

                  <ul className="list-unstyled password_val">
                    <li className="active_val3"><span className="eight-character"><i className="fa fa-file_text" aria-hidden="true"></i></span>&nbsp; 8+ Character</li>
                    <li className="active_val2"><span className="one-special-char"><i className="fa fa-file_text" aria-hidden="true"></i></span> &nbsp;# Special </li>
                    <li className="active_val"><span className="low-upper-case"><i className="fa fa-file_text" aria-hidden="true"></i></span>&nbsp; Aa Alphabet</li>
                    <li className="active_val1"><span className="one-number"><i className="fa fa-file_text" aria-hidden="true"></i></span> &nbsp;1 number</li>
                  </ul>
                </Form.Group>

                <Form.Group className="mb-3" >
                <label className="mb-2">Gender</label><br>
                </br><span className="gender_1">
                <input for="check1" {...register("gender", { required: true })} type="radio" value="1"></input>
                <label id="check1">Men</label></span>

              <span className="gender_2">
                <input {...register("gender", { required: true })} type="radio" value="2" ></input><label>Women</label>
              </span>
              </Form.Group>

                <Button variant="primary" type="submit" className="register_button mb-5" >
                  Sign Up
                </Button><br></br>
                {/* <p className="text-center mt-3">Already have an account? <span onClick={(e) => toggleTabs(1)} className="login_buttons">Login</span></p> */}
              </Form>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Login;

