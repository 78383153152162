import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';


const Paypal = (props) => {
  const paypal = useRef();
  const [Details, setDetails] = useState('');
  const planId = props.plans;
  const navigate = useNavigate();

  // console.log(planId)
  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                amount: {
                  currency_code: "USD",
                  value: planId,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
         // console.log(order);
          setDetails(order);
        //  console.log(Details)
          sessionStorage.setItem('order',JSON.stringify(order))
          if(order.status === "COMPLETED"){
            navigate('/Paymentsuccess')
          }else {
            navigate('/Paymentfail')
          }
        },
        onError: (err) => {
          console.log(err);
        },
      })
      .render(paypal.current);
  }, []);
  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
}
export default Paypal;

