// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase,ref,onValue } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDP00SiQU6uj00cL2ifPN_7P5VcrlZxPP4",
  authDomain: "frify-6c673.firebaseapp.com",
  databaseURL: "https://frify-6c673-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "frify-6c673",
  storageBucket: "frify-6c673.appspot.com",
  messagingSenderId: "300929316042",
  appId: "1:300929316042:web:0baa0d56ea9d433e9cf033",
  measurementId: "G-BZTK3800YQ"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const db = getDatabase(firebase);

export {db};
