import React, { useState, useEffect } from "react";
import Sidemenu from "../sidebar/sidemenu";
import { useLocation } from 'react-router-dom';
import './chat.css';
import { Card } from 'react-bootstrap';
import CardHeader from "react-bootstrap/esm/CardHeader";
import Newmsg from './Newchat';
import './messaging_init_in_sw';
import { ref, onValue } from "firebase/database";
import { db } from './firebase';
import axios from "axios";
import NoData from '../../assets/images/no_data.gif';
//import { auth } from './firebase.js';

function Chat() {
    const [show, setShow] = useState(true);
    let [useritemid, setUseritemid] = useState('');
    const [chatlist, setchatlist] = useState([]);
    const [otheruid, setotheruid] = useState('');
    // const [user] = useAuthState(auth)
    const local_storage = JSON.parse(localStorage.getItem('userDetails'));
    const [value, setValue] = useState({ 'profiles': [], 'path': '' });
    const [path, setpath] = useState('')
    const baseUrl = process.env.REACT_APP_BASE_URL;
    //console.log(path);
    const [display_list, setdisplay] = useState({
        about_me: "", age: "", country_name: "", gender: "",
        height: "",
        height_unit: "",
        images: [{ 'image': '' }],
        like_status: "",
        locked_status: "",
        mother_tongue: "",
        name: "",
        nationality_name: "",
        occupation_name: "",
        religion_name: "",
        report_user_list_status: "",
        request_list_id: "",
        request_status: "",
        selfie_status: "",
        shortlist_status: "",
        state_name: "",
        study_name: null,
        unique_id: "",
        user_id: "",
        weight: "",
        weight_unit: "",
        referral_link: ""
    })

    const location = useLocation();
    const [id, setid] = useState('');

    const latest_msg = JSON.parse(localStorage.getItem('msg_input'));
    const image_path = process.env.IMAGE_PATH;

    useEffect(() => {
        try {
            let query = ref(db, `Frify_test_users/${local_storage.user_id}/conversations`);
            onValue(query, (snapshot) => {
                setotheruid(snapshot.val()[0].other_user_id);
                //alert(query);
                let data = snapshot.val();
                setchatlist(data);
                //console.log(snapshot.val()[0]);
                //alert(snapshot.val().length);
                if(snapshot.val().length>0){
                    setid(snapshot.val()[0].id);
                    setUseritemid(snapshot.val()[0].other_user_id);
                }
                //console.log("The Total Snapshot of chats are", snapshot.val());
                let length = snapshot.val().length;
                if (length = "undefined") {
                    //console.log("undefien");
                }
                else {
                    for (let i = 0; i < length; i++) {
                        //console.log(snapshot.val()[i].Latest_message.msg);
                        //console.log(snapshot.val()[i].id);
                    }
                }
            })
        }
        catch (e) {
            console.log(e);
        }
        const proFormData = new FormData();
        proFormData.append('user_id', local_storage.user_id);
        proFormData.append('profile_id', otheruid);
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/profile_view`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: proFormData
        }).then((res) => {
            //console.log("The response is of the datat: : ",res);
            setpath(res.data.path);
            //console.log(res.data.path);
        }).catch((error) => {
            console.log(error);
        })
    }, [otheruid]);

    

    var chat_list = chatlist.map((item) =>
        <button className="card-button" onClick={(e) => {
            setid(item.id, item.other_user_id);
            setUseritemid(item.other_user_id);
        }}>
            <Card className="chat_card mb-3" style={item.other_user_id == useritemid ? { backgroundColor: '#ffdddd' } : {}}>
                <CardHeader>
                    {/* <img src={`https://bu3is.krify.com/matchmade/assets/album/${item.other_user_pic}`} className="chat_pic" /> */}
                    <div class="avatar_1"> {item.other_user_name.toUpperCase().substring(0, 1)} </div>
                </CardHeader>
                <Card.Body className="p-1 d-flex align-items-center">
                    <Card.Text>
                        <h6 className="profile_name m-0 p-0"> {item.other_user_name} </h6>
                        <p className="user_location m-0 p-0">
                            {item.Latest_message.msg}  </p>
                    </Card.Text>
                </Card.Body>
                <Card.Footer style={{ fontSize: '12px' }}>
                    {item.Latest_message.date}
                </Card.Footer>
            </Card>
        </button>
    )
console.log(chat_list)
    setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, 1000);

    return (
        <div className="d-flex">
            <div className="col-3 page_left" style={{ width: 'auto' }}>
                <Sidemenu />
            </div>
            <div className="col-9 d-flex flex-column chat-page page_right">
                <div className="d-flex flex-row bg-white top-bar shadow">
                    <p> <b>Chats</b> </p>
                </div>
                <div className="d-flex p-0 m-0">
                    <div className="user_info col-7 mt-4 mb-3 px-5">
                        <p>{chat_list.length == '0' ? <div className="d-flex justify-content-center align-items-center mt-5 w-100 h-100" > <img src={NoData} /></div> : ''}</p>
                        <p>{chat_list}</p>
                    </div>
                    <div className="col-5" id="right-card" style={{ display: 'block' }}>
                        <div>
                            {Boolean(id) ? <Newmsg convid={id} /> : ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chat;