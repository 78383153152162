import React, {useEffect} from 'react';
import { Card } from 'react-bootstrap';
import success from '../../assets/images/success.gif'
import Sidemenu from "../sidebar/sidemenu";
import axios from 'axios';

function Paymentsuccess() {
  const paydetails = JSON.parse(sessionStorage.getItem('order'));
  const local_storage=JSON.parse(localStorage.getItem('userDetails'));
  const baseUrl=process.env.REACT_APP_BASE_URL;
  // console.log(local_storage)
  // console.log(paydetails)

  useEffect(() => {
    const payData=new FormData();
    payData.append('user_id', local_storage.user_id);
    payData.append('txn_id', paydetails.id);
    payData.append('payment_gross', paydetails.purchase_units[0].amount.value);
    payData.append('payment_status', 'COMPLETE');
    payData.append('currency', paydetails.purchase_units[0].amount.currency_code);
    payData.append('plan_id', 3);
    payData.append('first_name', paydetails.payer.name.given_name);
    payData.append('last_name', paydetails.payer.name.surname);
    payData.append('email', paydetails.payer.email_address);
    payData.append('platform', 3);
    axios({
        method: 'POST',
        url: `${baseUrl}/Payments/payment_success`,
        headers: {
            "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
            'X-api-key': local_storage.api_key,
        },
        data: payData
    }).then((res) => {
       // console.log(res)
    }).catch((e) => {
        console.log(e)
    })
}, [])

  return (
    <div>

      <div className="d-flex">
        <div className="col-3 page_left" style={{ width: 'auto' }}>
          <Sidemenu />
        </div>
        <div className="col-9 d-flex flex-column payment-page page_right">
          <Card className='mt-5 col-6 pay_success' >
            <img src={success} />
            <h4><b>Payment Success</b></h4>
            <p><b>Transaction Id</b>: {paydetails.id}</p>
            <p><b>Amount Paid</b>: {paydetails.purchase_units[0].amount.value}</p>
          </Card>
        </div>
      </div>

    </div>
  )
}

export default Paymentsuccess;