import React, { useState, useEffect } from "react";
import icon from "../../assets/images/lock_icon.png";
import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import axios from "axios";
import './selfie.css'
import Webcam from "react-webcam";
import selfie from '../../assets/images/selfie_cam.png';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import LottieLoader from '../lottie/lottieloader';

function Selfie() {
  const [otherlogin, setotherlogin] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false)
  const [image, setImage] = useState('');
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const location = useLocation();
  //console.log(location)
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const local_storage = JSON.parse(localStorage.getItem('userDetails'));
console.log(local_storage)
  const { register, handleSubmit, formState: { errors } } = useForm();

  const WebcamComponent = () => <Webcam />;

  const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user"
  };

  const webcamRef = React.useRef(null);

  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc)
      // document.getElementById('take-selfie').style.display='none';
    },

    [webcamRef]

  );

  function showPreviewOne(e) {
    // console.log(e,'e.target.files')

    if (e.target.files.length > 0) {
      setImage([...image, e.target.files[0]])
      let src = URL.createObjectURL(e.target.files[0]);
      // let preview = document.getElementById("file-ip-1-preview");
      // preview.src = src;
      // preview.style.display = "block";
    }

  }
  //console.log(image);


  // Create Blob file from URL
  function blobCreationFromURL(inputURI) {

    var binaryVal;

    // mime extension extraction
    var inputMIME = inputURI.split(',')[0].split(':')[1].split(';')[0];

    // Extract remaining part of URL and convert it to binary value
    if (inputURI.split(',')[0].indexOf('base64') >= 0)
      binaryVal = atob(inputURI.split(',')[1]);

    // Decoding of base64 encoded string
    else
      binaryVal = unescape(inputURI.split(',')[1]);

    // Computation of new string in which hexadecimal
    // escape sequences are replaced by the character 
    // it represents

    // Store the bytes of the string to a typed array
    var blobArray = [];
    for (var index = 0; index < binaryVal.length; index++) {
      blobArray.push(binaryVal.charCodeAt(index));
    }

    return new Blob([blobArray], {
      type: inputMIME
    });
  }



  // Create formdata object and append the object
  // file to the name 'Blob file'
  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  //Usage example:
  var file = dataURLtoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=', 'hello.txt');
  //console.log(file);

  const onSelfieSubmit = (data) => {
    // console.log(data);
    setDisable(true)

    //var blobObject = blobCreationFromURL(image);
    var blobObject = dataURLtoFile(image, "image.png")
    //return false;
    const selfieData = new FormData();
    selfieData.append('user_id', local_storage.user_id);

    selfieData.append('image', blobObject)

    //  console.log(blobObject);  

    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/selfie_verification`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: selfieData
    }).then((res) => {
       console.log(res)
      setDisable(true)

      if (res.data.status === 1) {
        console.log(res)
        //localStorage.setItem("userDetails", JSON.stringify(userDetails))
        setTimeout(() => {
          navigate('/selfieverification', { state: location.state })
        }, 3000);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      console.log(e.response, 'res')
      setDisable(false)
      if (e.message == 'Request failed with status code 401') {
        setotherlogin(true)
      }

    })

  }
  const handleSelfie = (e) => {
    //console.log(e.target.value, 'EEE')
    // if (e.target.value === '1') {
    setShow(true)

    // }else{
    // setShow(false)

    // }
  }
  const capture_func = (e) => {
    e.preventDefault();
    capture();
    document.getElementById('take-selfie').style.display = "none !important";
  }
  return (
    <div className="d-flex">
      <Modal show={otherlogin} size="lg" animation={false} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="text-center">Sorry! Someone has logged in with your account.Please check and come back</Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => navigate('/Login')} variant="danger">
            ok
          </Button>
        </Modal.Footer>
      </Modal>
      {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}

      <div className="col-6 selfiePic">

      </div>

      <div className="col-6 mt-5 content_login">
        <Button variant="danger" onClick={() => navigate("/Hobbies", { state: location.state })} className="back_button mt-3"><i className="fa fa-arrow-left">&nbsp; Back</i></Button>
        <div className="text-center">
          <img src={icon} />
          <h4 className="mt-3">Selfie Verification</h4>
        </div>
        <Form className="text-center mt-3" onSubmit={handleSubmit(onSelfieSubmit)}>
          {show ? '' : <><img src={selfie} height="300px" /><br></br></>

          }

          {show ? <div><div className="webcam-img">
            {image == '' ?
              <Modal show={show} fullscreen="xxl-down" onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                  <Webcam
                    audio={false}
                    height={470}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={1300}
                    // videoConstraints={videoConstraints}
                    // {...register(image)}
                    onChange={(e) => showPreviewOne(e)}
                    style={{ transform: 'scaleX(-1)' }}
                  />

                </Modal.Body>
                <Modal.Footer>
                  <button onClick={capture_func}
                    className="webcam-btn btn btn-danger">Capture</button>
                </Modal.Footer>
              </Modal>
              : <img src={image} style={{ transform: 'scaleX(-1)' }}/>}
          </div>
            {image != '' ?
              <div>
                <button onClick={(e) => {
                  e.preventDefault();
                  setImage('')
                }}
                  className="webcam-btn btn bg-btn mt-3">
                  Retake Image</button>
                {/* <Button variant="dark" type="submit" className="d-block m-auto mt-3 w-50 btn_disabled">
            SUBMIT
          </Button> */}
              </div>
              :
              <button onClick={(e) => {
                e.preventDefault();
                capture();
              }}
                className="webcam-btn btn btn-danger">Capture</button>
            }</div> : ''}

          {image != '' ? <Button variant="dark" type="submit" className="d-block m-auto mt-3 w-50 btn_disabled">
            SUBMIT
          </Button> :
            <Button variant="dark" id="take-selfie" onClick={(e) => handleSelfie(e)} value="1" className=" m-auto mt-3 w-50 btn_disabled">
              TAKE SELFIE
            </Button>}


        </Form>
      </div>
    </div>
  )
}


export default Selfie;