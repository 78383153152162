import React, { useState, useEffect } from "react";
import {
  CDBSidebar, CDBSidebarContent, CDBSidebarFooter, CDBSidebarHeader, CDBSidebarMenu,
  CDBSidebarMenuItem
} from 'cdbreact';
import { Button, Modal } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './sidemenu.css';
import profiles from "../../assets/images/sideicons/matched_profiles.svg";
import matchrequests from "../../assets/images/sideicons/match_requests.svg";
import listed from "../../assets/images/sideicons/short_listed.svg";
import mutual from "../../assets/images/sideicons/mutual_match.svg";
import requests from "../../assets/images/sideicons/requests.svg";
import chat from "../../assets/images/sideicons/chat.svg";
import likes from "../../assets/images/sideicons/likes.svg";
import viewers from "../../assets/images/sideicons/viewers.svg";
import notification from "../../assets/images/sideicons/notifications.svg";
import settings from "../../assets/images/sideicons/settings.svg";
import profiles_s from "../../assets/images/sideicons/matched_profiles_s.svg";
import matchrequests_s from "../../assets/images/sideicons/match_requests_s.svg";
import listed_s from "../../assets/images/sideicons/short_listed_s.svg";
import mutual_s from "../../assets/images/sideicons/mutual_match_s.svg";
import requests_s from "../../assets/images/sideicons/requests_s.svg";
import chat_s from "../../assets/images/sideicons/chat_s.svg";
import likes_s from "../../assets/images/sideicons/likes_s.svg";
import viewers_s from "../../assets/images/sideicons/viewers_s.svg";
import notification_s from "../../assets/images/sideicons/notifications_s.svg";
import settings_s from "../../assets/images/sideicons/settings_s.svg";
import logout from "../../assets/images/sideicons/logout.svg";
import match from "../../assets/images/sideicons/match.svg";
import match_s from "../../assets/images/sideicons/match_s.svg";
import skip from "../../assets/images/sideicons/skip.svg";
import skip_s from "../../assets/images/sideicons/skip_s.svg";
import payment from "../../assets/images/sideicons/payment.svg";
import payment_s from "../../assets/images/sideicons/payment_s.svg";
import edit from "../../assets/images/sideicons/editpreference.svg";
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import male1 from '../../assets/images/avatarm.png';
import female1 from '../../assets/images/avatarf.png';
import LottieLoader3 from '../lottie/lottieloader3';
import { getDatabase, ref, update } from "firebase/database";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Sidemenu = () => {
  //const gender = JSON.parse(localStorage.getItem('gender'));
  const [count, setCount] = useState('')
  const [countdata, setcountdata] = useState('')
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  //const [status, setStatus] = useState();
  const [show, setShow] = useState(false);
  const [otherlogin, setotherlogin] = useState(false);
  const [profiledata, setprofiledata] = useState('')
  const [selfiepic, setselfiepic] = useState('')
  const handleClose = () => setShow(false);
  const handleOtherClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const location = useLocation();
  const isNavLinkDisabled = true;

  function makePageTransparent() {
    var overlay = document.getElementById('overlay');
    overlay.style.display = 'block';
  }

  function makePagenotTransparent() {
    var overlay = document.getElementById('overlay');
    overlay.style.display = 'none';
  }

  //console.log(location.state)
  const [value, setValue] = useState({ 'name': '', 'image': '', 'id': '', 'api_key': '' })
  // axios.post(`${baseUrl}/Registration/set_selfie_verification_status`, { user_id: location.state.user_id, status: 1, status_message: 'image not clear' },
  //   { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } })
  //   .then(res => {

  //   })

  //   useEffect(() => {
  //     axios({
  //         method: 'POST',
  //         url: `${baseUrl}/Registration/set_selfie_verification_status`,
  //         headers: {
  //             "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
  //             'X-api-key': location.state.api_key,
  //         }
  //     }).then((res) => {
  //          console.log(res.data.data)
  //         // let dataa = [];
  //         // dataa.push(res.data.data)
  //         setStatus(res)
  //     }).catch((e) => {
  //         console.log(e)
  //     })

  // }, [])
  const local_storage = JSON.parse(localStorage.getItem('userDetails'));
  //console.log(local_storage.add_photos_status)
  
  const preload = () => {
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/user_profile`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData
    }).then((res) => {
      console.log(res.data, 'pic')
      setprofiledata(res.data.data)
      setselfiepic(res.data.data.selfie_pic)
      setcountdata(res.data.data.unread_count)
      //console.log(selfiepic)
      if (res.data.status === 1) {
        localStorage.setItem("locked_status", JSON.stringify(res.data.data.locked_status))
        localStorage.setItem("gender", JSON.stringify(res.data.data.gender))
        if (res.data.data.selfie_status === '1') {
          // console.log(res.data)
          setValue({ 'name': res.data.data.name, 'image': `${res.data.path}/${res.data.data.selfie_pic}`, 'id': `#${res.data.data.unique_id}`, 'api_key': res.data.data.api_key })


        } else {
          setValue({ 'name': res.data.data.name, 'image': `${res.data.path}/${res.data.data.selfie_pic}`, 'id': `(#${res.data.data.unique_id})`, 'api_key': res.data.data.api_key })

        }
      }
    }).catch((e) => {
      if (e.message == 'Request failed with status code 401') {
        setotherlogin(true)
        console.log(e.message)
      }
    })
  }

  useEffect(() => {
    preload();
  }, [])
  const pages = [
    { status: profiledata.add_photos_status === '1' ? 'completed' : 'pending' },
    { status: profiledata.about_me_status === '1' ? 'completed' : 'pending' },
    { status: profiledata.more_about_status === '1' ? 'completed' : 'pending' },
    { status: profiledata.my_hobbies_status === '1' ? 'completed' : 'pending' },
    { status: profiledata.selfie_screen_status === '1' ? 'completed' : 'pending' },
    { status: profiledata.display_name_status === '1' ? 'completed' : 'pending' },
    { status: profiledata.looking_status === '1' ? 'completed' : 'pending' },
    { status: profiledata.security_question_status === '1' ? 'completed' : 'pending' },
    
  ];
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    // Calculate the total progress based on the status of each page
    const totalProgress = (pages.filter(page => page.status === 'completed').length / pages.length) * 100;

    setProgress(totalProgress);
  }, [pages]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });


  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    // Add the event listener when the component mounts
    window.addEventListener('mousemove', handleMouseMove);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [])

  // const db = getDatabase();
  // const userRef = ref(db, 'Frify_test_users/' + local_storage.user_id);
  // if (mousePosition.x <= 10 || mousePosition.y <= 50) {
  //   const updates = {
  //     onlineStatus: "false"
  //   };
  //   update(userRef, updates)
  //     .then(() => {
  //       //console.log("Online status updated successfully");
  //     })
  //     .catch((error) => {
  //       console.error("Error updating online status: ", error);
  //     });
  // }
  // else {
  //   const updates = {
  //     onlineStatus: "true"
  //   };
  //   update(userRef, updates)
  //     .then(() => {
  //       //console.log("Online status updated successfully");
  //     })
  //     .catch((error) => {
  //       console.error("Error updating online status: ", error);
  //     });
  // }

  const onLogout = (e) => {
    makePageTransparent();
    e.preventDefault();

    const db = getDatabase();
    const userRef = ref(db, 'Frify_test_users/' + local_storage.user_id);
    const updates = {
      onlineStatus: "false"
    };
    update(userRef, updates)
      .then(() => {
        console.log("Online status updated successfully");
      })
      .catch((error) => {
        console.error("Error updating online status: ", error);
      });

    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);

    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/logout`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData

    }).then((res) => {
      if (res.data.status === 1) {
        setTimeout(() => {
          navigate('/login', { state: location.state });
          localStorage.removeItem('userDetails');
          localStorage.removeItem('userId');
          localStorage.removeItem('gender');
          localStorage.removeItem('new_num');
          localStorage.removeItem('prefer_gender');
          localStorage.removeItem('msg_input');
          localStorage.setItem('gAuth', false);
        }, 2000);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        //alert(res.data.message)
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      //  console.log(e.response, 'e')
    })
  };

  const Prefence = () => {
    if (local_storage.security_question_status === '0') {
      setTimeout(() => {
        localStorage.setItem('gAuth', "true");
        navigate('/Security')
      }, 2500);
    } else if (local_storage.agree_terms === '0') {
      setTimeout(() => {
        localStorage.setItem('gAuth', "true");
        navigate('/Welcome')
      }, 2500);
    } else if (local_storage.display_name_status === '0') {
      setTimeout(() => {
        localStorage.setItem('gAuth', "true");
        navigate('/Displayname')
      }, 2500);
    } else if (local_storage.looking_status === '0') {
      setTimeout(() => {
        localStorage.setItem('gAuth', "true");
        navigate('/Lookingfor')
      }, 2500);
    } else if (local_storage.about_me_status === '0') {
      setTimeout(() => {
        localStorage.setItem('gAuth', "true");
        navigate('/About')
      }, 2500);
    } else if (local_storage.add_photos_status === '0') {
      setTimeout(() => {
        localStorage.setItem('gAuth', "true");
        navigate('/addphotos')
      }, 2500);
    } else if (local_storage.more_about_status === '0') {
      setTimeout(() => {
        localStorage.setItem('gAuth', "true");
        navigate('/Moreabout')
      }, 2500);
    } else if (local_storage.my_hobbies_status === '0') {
      setTimeout(() => {
        localStorage.setItem('gAuth', "true");
        navigate('/Hobbies')
      }, 2500);
    } else if (local_storage.selfie_screen_status === '0') {
      setTimeout(() => {
        localStorage.setItem('gAuth', "true");
        navigate('/selfie')
      }, 2500);
    }
  }
const handleProgressCircleClick =()=>{
navigate('/settings');
};
  return (
    <div style={{ display: 'flex', overflow: 'scroll initial', height: '100vh' }}>
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader>

          {/* <i class="fa fa-user"></i> */}
          {profiledata ? <>  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', cursor:'pointer' }} onClick={handleProgressCircleClick}>
         
          <div style={{ width: '100px', height: '100px', cursor:'pointer', position: 'relative',backgroundColor:'#fff',borderRadius:'50%'}}  >
          <CircularProgressbar
            value={progress}
            //text={`${progress.toFixed(2)}%`}
              styles={buildStyles({
              textColor: '#000',
              pathColor: '#007bff',
              trailColor: '#f0f0f0',
            })}
          />
        </div>
      <span style={{fontSize:'12px'}} className="mt-2"> {`${progress}%`} Profile Completed</span>
        <img src={`${local_storage.selfie_status == '1' ? value.image : local_storage.gender == '1' ? male1 : female1}`} style={{
          position: 'absolute',
          top: '12.7%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80px', // Adjust the width and height of the image as needed
          height: '80px',
          objectFit:'contain',
          backgroundColor:'#fff',
          borderRadius:'50%',
        }} />
      </div>
      {/* <div>
        {pages.map((page, index) => (
          <div key={index}>
            <h3>{page.name}</h3>
            {<p>Status: {page.status}</p> }
            {<button onClick={() => markPageAsCompleted(index)}>Mark as Completed</button>}
          </div>
        ))}
      </div> */}
      <hr className="m-2"></hr>
            <div className="d-flex flex-column mt-2">
              <span className="display-name">{local_storage.name}</span>
              {value.id != '(#undefined)'? <Link to="/selfieverification" className="unique_id"><span style={{ fontSize: '14px' }}>{value.id}</span> </Link> : ''}

            </div></> : ''}
        </CDBSidebarHeader>
        {/* <Button onClick={()=>Prefence()}>Edit Preferences</Button> */}
        <hr className="m-0"></hr>
        <CDBSidebarMenu className="side_menu">
          {/* <NavLink exact to="/security" activeClassName="active" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={edit} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect edit" style={{ width: '30px !important' }} /> Edit Preferences 
            </CDBSidebarMenuItem>
          </NavLink> */}
          <NavLink exact to="/matchedprofiles" activeClassName="active" className="side_li mt-3">
            <CDBSidebarMenuItem className="side_li_a"><img src={profiles} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /> <img src={profiles_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Matched Profiles</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/matchrequests" activeClassName="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={matchrequests} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={matchrequests_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Requests Received</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/shortlist" activeClassName="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={listed} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={listed_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Shortlisted </CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/mutualmatch" activeClassName="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={mutual} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={mutual_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Mutual Matches</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/requests" activeClassName="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={requests} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={requests_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Requested</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/matches" activeClassName="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={match} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={match_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />My Matches</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/newskipped" activeClassName="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={skip} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={skip_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Skipped Profiles</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/chat" activeClassName="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={chat} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={chat_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Chat</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/likes" activeClassName="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={likes} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={likes_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Likes</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/viewers" activeClassName="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={viewers} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={viewers_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Views</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/notifications" activeClassName="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={notification} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={notification_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Notifications {countdata != 0 ? <span className="count_data">{countdata}</span> : ''}</CDBSidebarMenuItem>
          </NavLink>
          {/* <div className="side_li">
            <CDBSidebarMenuItem className="side_li_a">
              <img src={payment} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" />
              <img src={payment_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Payment</CDBSidebarMenuItem>
          </div> */}

          {/* <NavLink exact to="/payment" activeClassName="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={payment} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" />
              <img src={payment_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Payment</CDBSidebarMenuItem>
          </NavLink> */}
          {/* <NavLink exact to="/payment" activeClassName="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={payment} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" />
              <img src={payment_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Payment</CDBSidebarMenuItem>
          </NavLink>  */}
          <NavLink exact to="/contactus" activeClassName="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a" ><img src={settings} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={settings_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Contact Us</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/settings" activeClassName="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a" ><img src={settings} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={settings_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Settings</CDBSidebarMenuItem>
          </NavLink>

          <Button variant="primary" onClick={handleShow} className="logout_btn">

            <CDBSidebarMenuItem className="log_btn"><img src={logout} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon" />Logout</CDBSidebarMenuItem>
          </Button>

          <Modal show={show} size="lg" onHide={handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton className="clse_btn">

            </Modal.Header>
            <Modal.Body className="text-center">Are you sure want to logout</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
              <Button onClick={(e) => onLogout(e)} variant="danger">
                Yes
              </Button>
            </Modal.Footer>
            <div id="overlay">
              <div className='AddloadingImage'>
                <LottieLoader3 />
              </div>
            </div>
          </Modal>
          <Modal show={otherlogin} size="lg" onHide={handleOtherClose} animation={false} aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Body className="text-center">Sorry! Someone has logged in with your account.Please check and come back</Modal.Body>
            <Modal.Footer>
              <Button onClick={(e) => navigate('/Login')} variant="danger">
                ok
              </Button>
            </Modal.Footer>
          </Modal>
        </CDBSidebarMenu>
      </CDBSidebar>
    </div>

  )
}

export default Sidemenu;