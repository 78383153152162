import React, { useState, useEffect } from "react";
import icon from "../../assets/images/lock_icon.png";
import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { useForm } from "react-hook-form";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import $, { event } from 'jquery';
import LottieLoader from '../lottie/lottieloader';

function About() {
  const [otherlogin, setotherlogin] = useState(false);
  const [textarea] = useState(0);
  const navigate = useNavigate();
  const [count, setCount] = React.useState(0);
  const location = useLocation();
  const [disable, setDisable] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [getAboutme, setAboutme] = useState("");
  const local_storage = JSON.parse(localStorage.getItem('userDetails'));

  const onAboutSubmit = (data) => {
    setDisable(true)

    let input = data.description.split(' ');
    let modified_input = input.filter((item) => item != '')
    let descrip = modified_input.join(' ');

    const regFormData = new FormData();
    let key;
    if (location.state !== null) {
      regFormData.append('user_id', location.state.user_id);
      key = location.state.api_key;
    } else {
      regFormData.append('user_id', local_storage.user_id)
      key = local_storage.api_key;
    }
    regFormData.append('description', descrip);
    regFormData.append('api_key', 'cd38819260c30284a96411e1747f585a');
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/aboutme`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': key,
      },
      data: regFormData

    }).then((res) => {
      // console.log(res, 'ressss')
      setDisable(false)

      if (res.data.status === 1) {
       
          setTimeout(() => {
            navigate('/settings', { state: location.state })
          }, 3000);
        
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      console.log(e.response, 'e')
      setDisable(false)
      if (e.message == 'Request failed with status code 401') {
        setotherlogin(true)
    }

    })
  }
  useEffect(() => {
    $(".progress-bar").animate({
      width: "56%"
    }, 2500);
    const getUserFormData = new FormData();
    let key;
    if (location.state !== null) {
      getUserFormData.append('user_id', location.state.user_id);
      key = location.state.api_key;
    } else {
      getUserFormData.append('user_id', local_storage.user_id)
      key = local_storage.api_key;
    }
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/get_about_me`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': key,
      },
      data: getUserFormData
    }).then((res) => {
      if (res.data.status === 1) {
        setValue('description', res.data.data.about_me)
        setCount(res.data.data.about_me.length)
      }
    }).catch((error) => {
      console.log(error)
    })
  }, [])

  const handleKeyPress = (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[A-Za-z.,\b]+$/;
    if (count >= 150) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
      document.getElementById('150count').style.color = 'red';
      document.getElementById('150count').value = '150/150';
    } else {
      document.getElementById('150count').style.color = 'grey';
      if (count > 0) {

        if (keyCode == 188 || keyCode == 190 || keyCode == 32) {
          return;
        }
      } else {
        if (!regex.test(key) || count >= 150) {
          theEvent.returnValue = false;
          if (theEvent.preventDefault) theEvent.preventDefault();
        }
      }
    }
  }
  const aboutInput = (e) => {
    let input = e.target.value.split(' ');
    let c = 0;
    for (let i = 0; i < input.length; i++) {
      c = c + input[i].length
    }
    //console.log(input,c)
    
    if (c < 151) {
      setCount(c);
      document.getElementById('150count').style.color = 'grey';
    }
    else{
      
      console.log(e.target.id);
      alert('Please Enter less than 150 Charecters');
      document.getElementById(e.target.id).value = ''
      setCount(0);
      //document.getElementById('exampleFormControlTextarea1').value = ''
    }
  }

  return (
    <div className="d-flex">
      <Modal show={otherlogin} size="lg"  animation={false} aria-labelledby="contained-modal-title-vcenter"
  centered>
<Modal.Body className="text-center">Sorry! Someone has logged in with your account.Please check and come back</Modal.Body>
<Modal.Footer>
<Button onClick={(e) => navigate('/Login')} variant="danger">
      ok
    </Button>
</Modal.Footer>
</Modal>
      {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}

      <div className="col-6 about_page"></div>
      <div className="col-6 mt-5 content_login">
        <div className="col-xs-12 col-sm-12 progress-container">
          <div className="progress progress-striped active">
            <div className="progress-bar progress-bar-success"></div>
          </div>
          {location.state !== null ? <Button variant="danger" onClick={() => navigate("/lookingfor", { state: location.state })} className="back_button mt-3"><i className="fa fa-arrow-left">&nbsp; Back</i></Button> : ''}
        </div>
        <div className="text-center">
          <img src={icon} />
          <Form className="mt-2 px-5" onSubmit={handleSubmit(onAboutSubmit)}>
            <h3>About me</h3>
            <p id="150count" style={{ 'float': 'right' }}>{count}/150</p>
            <textarea
              className="form-control mt-4 about_textarea"
              id="exampleFormControlTextarea1"
              rows="10" placeholder="Tell about yourself"
              {...register("description", { required: true})}
              onChange={aboutInput}
              onKeyPress={handleKeyPress}
            />
            <p className="mt-2 text-danger" style={{ fontSize: '14px' }}>Note: Please enter atleast 30 characters</p>
            <span className='error_msg'> {errors.description?.type === 'required' && "Please enter about yourself "}</span>
           
            <Button variant="dark" type="submit" className="d-block m-auto mt-3 mb-3 w-100 btn_disabled" disabled={count > 29 ? '' : 'disabled'}>
              CONTINUE
            </Button>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default About;