import React from "react";
import img_1 from "../assets/images/card_img1.svg";
import img_2 from "../assets/images/card_img2.svg";
import img_3 from "../assets/images/card_img3.svg";
import img_4 from "../assets/images/card_img4.svg";
import img_5 from "../assets/images/phone1.png";
import img_6 from "../assets/images/phone2.png";
import img_7 from "../assets/images/phone3.png";
import Header from "./header/header";
import Footer from "./footer/footer";

const landing = () => {
    return (
        <div>
            <Header />
           
            <Footer />
        </div>

    )
}

export default landing;