import React, { useState, useEffect, useRef } from 'react'
import { getDatabase, ref, onValue, set, child, push, update, get } from "firebase/database";
import axios from "axios";
import { Input } from '@material-ui/core'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import send_btn from '../../assets/images/send_btn.svg';
import male1 from '../../assets/images/men_s.svg';
import female1 from '../../assets/images/women_s.svg';
//import EmojiPicker from 'emoji-picker-react';
import { db } from './firebase'; 
import SendMessage from './SendMessage';
import SignOut from './Signout';

function NewChat(props) {
    //console.log(props)
    const conId = props.convid;
    const ouid = props.convid;
    const local_storage = JSON.parse(localStorage.getItem('userDetails'));
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const scroll = useRef();
    const bottomRef = useRef(null);
    const [messages, setMessages] = useState([]);
    //console.log(messages, "MEEEEE");
    const [img, setimg] = useState({ 'path': '', 'image': '' });
    const [name, setname] = useState();
    const date = new Date().toLocaleDateString();
    const time = new Date().toLocaleTimeString();
    const [userId, setuserId] = useState('');
    const [online, setonline] = useState(false);
    const [chatid, setchatid] = useState('');
    const [msg, setMsg] = useState('');
    const image_path = process.env.IMAGE_PATH;
    const [count, setCount] = React.useState(0);
    const [userpath, setuserpath] = useState('');

    //code to be updated
    const [picturestatus, setpicturestatus] = useState('');
    const [selfie_pic_status, setselfie_pic_status] = useState('');
    const [gender, setGender] = useState('');
    //code to be updated

//console.log(local_storage.device_id,'ddd')

    const db = getDatabase();
    useEffect(() => {
        if (conId) {
            try {
                let query = ref(db, `Frify_test_users/${conId}/Messages/`);
                onValue(query, (snapshot) => {
                    setMessages(snapshot.val());
                })
                //alert(conId);
                const starCountRef = ref(db, `Frify_test_users/${conId}/Messages/`);
                onValue(starCountRef, (snapshot) => {
                    const data = snapshot.val();
                    //console.log(data);
                    //console.log(starCountRef);
                });
            }
            catch (e) {
                console.log(e)
            }

            let litem = conId.split('_');
            //alert(conId+"conid");
            //alert(litem+"litem");
            let uid = litem[1]
            let pid = litem[2]

            const proFormData = new FormData();
            proFormData.append('user_id', local_storage.user_id);
            //alert(uid+'uid');

            if (uid === local_storage.user_id) {
                proFormData.append('profile_id', pid)
            } else {
                proFormData.append('profile_id', uid)
            }
            axios({
                method: 'POST',
                url: `${baseUrl}/Profiles/profile_view`,
                headers: {
                    "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                    'X-api-key': local_storage.api_key,
                },
                data: proFormData

            }).then((res) => {
                console.log("The one and only main response is: [] ", res);
                setimg((prevState) => ({
                    path: res.data.path,
                    image:
                        picturestatus === '1' && selfie_pic_status === '1'
                            ? res.data.data.images[0].image
                            : gender === '1'
                                ? male1
                                : female1,
                }));
                setname(res.data.data.name);
                setuserId(res.data.data.user_id);
                setchatid(res.data.data.chat_id);
                //code to be updated
                setpicturestatus(res.data.data.photos_slider_status);
                setselfie_pic_status(res.data.data.selfie_status);
                setGender(res.data.data.gender);
                //code to be updated
            }).catch((error) => {
                console.log(error);
            })
        }
    }, [conId])
    useEffect(() => {
        try {
            let query = ref(db, `Frify_test_users/${userId}/`);
            onValue(query, (snapshot) => {
                //console.log("The Total Snapshot::: ", snapshot.val());
                setonline(snapshot.val().onlineStatus);
                //console.log(snapshot.val().onlineStatus);

            })
        }
        catch (e) {
            console.log(e);
        }
    })

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id);
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/user_profile`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            //console.log(res);
            setuserpath(res.data.data.selfie_pic);

            // console.log(res.data.data.selfie_pic,'imageee');
        }).catch((e) => {
            console.log(e)
        })

    }, [messages]);


    function writeNewPost(e) {
        if (!messages) {
            set(ref(db, `Frify_test_users/${conId}/Messages/`), {
            }).then((res) => {
                //console.log(res);
            })
                .catch((error) => {
                    console.log(error);
                });
        }
        let message = document.getElementById('msg_input').value;

        // A post entry.
        const postData = {
            "id": conId,
            "type": "text",
            "msg": message,
            "date": `${date} ${time}`,
            "sender_id": local_storage.user_id,
            "isRead": false,
            "isDelivered": false,
            "name": local_storage.name,
            "blocked_message": false
        };
       
        const latestMsg = {
            "Latest_message": {
                "date": `${date} ${time}`,
                "isDelivered": '',
                "isRead": '',
                "msg": message,
                "sender_id": local_storage.user_id
            },
            "blockedByOthers": false,
            "blockedByYouStatus": false,
            "id": chatid,
            "other_user_id": userId,
            "other_user_name": local_storage.name,
            "other_user_pic": userpath,
            "YourTypingStatus": '',
        };
        //------------------ipdate latesh msg
        let query = ref(db, `Frify_test_users/${local_storage.user_id}/conversations`);
        //console.log("The Query is: ", query);
        //console.log("test data is: ", `Frify_test_users/${local_storage.user_id}/conversations`)
        onValue(query, (snapshot) => {
            //console.log(snapshot.val(), 'SANP');
            let converstionsList = snapshot.val();
            var indexOfUserId = 0;
            //var indexOfUserId = converstionsList.map(function (e) {return e.other_user_id; }).indexOf(userId);
            //console.log("indexOfUserId",indexOfUserId);
            localStorage.setItem("msg_input", JSON.stringify(message));
            //Get a key for a new Post.
            const newPostKey = push(child(ref(db), 'posts')).key;
            //console.log("The newPostKey is", newPostKey);
            const keyy = messages && messages.length > 0 ? messages.length : 0
            //console.log("key is", keyy);
            //Write the new post's data simultaneously in the posts list and the user's post list.
            const updates = {};
            updates[`Frify_test_users/${conId}/Messages/` + keyy] = postData;
            //updates['Frify_test_users/'+ local_storage.user_id] = latestdata;
            // updates['/user-posts/' + uid + '/' + newPostKey] = postData;
            //updates['Frify_test_users/' + userId + '/conversations/' + indexOfUserId + '/'] = latestMsg;

            const latestDataRef = ref(db, `Frify_test_users/${local_storage.user_id}`);
            get(latestDataRef)
                .then((snapshot) => {
                    const existingLatestData = snapshot.val();
        
                    // Update the fields you want to change in latestdata
                    existingLatestData.delete_status = false;
                    existingLatestData.fcm_token = local_storage.device_id;
                    existingLatestData.lastSeen = '';
                    existingLatestData.name = local_storage.name;
                    existingLatestData.notificationStatus = true;
                    existingLatestData.onlineStatus = true;
                    existingLatestData.pic = userpath;
                    existingLatestData.selfie_status = local_storage.selfie_status;
                    existingLatestData.gender = local_storage.gender;
                    existingLatestData.photos_slider_status = local_storage.photos_slider_status;
        
                    // Update latestMsg
                    updates['Frify_test_users/' + userId + '/conversations/' + indexOfUserId + '/'] = latestMsg;
        
                    // Update latestdata in the database
                    updates[`Frify_test_users/${local_storage.user_id}`] = existingLatestData;
        
                    return update(ref(db), updates);
                })
                .catch((error) => {
                    console.log(error);
                });

            return update(ref(db), updates);
        })
       
    }
    
    const makeinputEmpty = (e) => {
        setMsg(e.target.value);
    }
    const makeinputEmpty1 = () => {
        setMsg('');
    }

    const dateArray = [];

    return (
        <div>
            <Card>
                <div className='chat_head' >
                    <img src={`${picturestatus == '1' && selfie_pic_status == '1' ? 'https://bu3is.krify.com/matchmade/assets/album/' + '/' + img.image : gender == '1' ? male1 : female1}`} className="chat_pic" />
                    {/* <img src={`https://bu3is.krify.com/matchmade/assets/album/${img.image}`} className="chat_pic" /> */}
                    <span className='chat_name'><b>{name}</b></span><br></br>

                    {online == 'true' ? <span className='online_status'>
                        <span class="green_dot"> </span>online</span> : <span className='online_status'>
                        <span class="red_dot"> </span>offline</span>}
                </div>
                <form>
                    <Card.Body style={{ overflowY: 'scroll', height: "85vh" }}>
                        <Card.Text>
                            <div className="msgs" >
                                {messages && messages.length > 0 && messages.map((item) => {
                                    if (dateArray.length == 0) {
                                        dateArray.push(`${item.date.substring(0, 10)}`);
                                    }
                                    else if (!dateArray.includes(`${item.date.substring(0, 10)}`)) {
                                        dateArray.push(item.date.substring(0, 10));
                                    }
                                })}
                                {/* {console.log("dateArray", dateArray, dateArray.length)} */}
                                {messages && messages.length > 0 && messages.map((item) => (
                                    <div>

                                        {/* {console.log("ddafdafsf", `${item.sender_id === local_storage.user_id}`)} */}
                                        {item.sender_id === local_storage.user_id ?
                                            (
                                                <div>
                                                    <div className='text-center' >
                                                        {dateArray.includes(`${item.date.substring(0, 10)}`) ?
                                                            (<div>
                                                                 <span class="chat_scroll_data"> {item.date.substring(0, 10)} </span>
                                                                <span style={{ display: "none" }}> {dateArray.shift()} </span>
                                                            </div>) : ""}
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-end'>
                                                        <div className='msg sent'>
                                                            <p>{item.msg}
                                                                <span class="chat_time">
                                                                    {/* <sub> {item.date.substring(11, 16)} </sub> */}
                                                                </span> </p>
                                                        </div>
                                                        <div class="avatar"> {item.name.toUpperCase().substring(0, 1)} </div>
                                                    </div>
                                                </div>)
                                            :
                                            (<div className='d-flex align-items-center justify-content-start'>
                                                <div class="avatar"> {item.name.toUpperCase().substring(0, 1)} </div>
                                                <div className='msg received'>
                                                    <p>{item.msg} </p>
                                                </div>

                                            </div>)}

                                    </div>
                                ))}
                                <div ref={bottomRef} />
                            </div>
                        </Card.Text>
                        <div className="sendMsg">
                            <Input value={msg} style={{ width: '78%', fontSize: '15px', fontWeight: '550', marginLeft: '5px', marginBottom: '-3px' }} placeholder='Type message...' type="text" id="msg_input" onChange={(e) => makeinputEmpty(e)} pattern="^[a-zA-Z][\sa-zA-Z]*" />
                            {/* <Button  style={{ width: '18%', fontSize: '15px', fontWeight: '550', margin: '4px 5% -13px 5%', maxWidth: '200px'}} ></Button> */}
                            <Button type="submit" onClick={(e) => {
                                writeNewPost()
                                makeinputEmpty1()
                            }} className="chat_send" disabled={!msg}><img src={send_btn} /></Button>
                        </div>
                        <div ref={scroll}></div>
                    </Card.Body> </form>
            </Card>

            {/* <SendMessage scroll={scroll} /> */}


        </div>
    )
}

export default NewChat;