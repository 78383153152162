import React, { useState } from "react";
import logo from "../../assets/images/sitelogo.png";
import "../../assets/css/header.css";
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import LottieLoader from '../lottie/lottieloader';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import banner from '../../assets/images/banner.png'

function Header() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [gender, setGender] = useState('');
  const [value, setValue] = useState();
  const [looking, setLooking] = useState(0);
  const [disable, setDisable] = useState(false);
  const [lookingmen, setLookingmen] = useState(false)
  const [lookingwomen, setLookingwomen] = useState(false)

  const goTooLogin = () => {
    navigate('/LogIn')
  }


  return (
    <div>
      {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}
      <div className="head">
        <Navbar expand="lg" className="black">
          <Container fluid>
            <Navbar.Brand href="https:frify.com" className="col-6">
              <img
                src={logo}
                className="d-inline-block align-top site_logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-lg-0 menu col-6"
                style={{ maxHeight: '100px' }}
                navbarScroll
              >
                <Nav.Link onClick={() => navigate('/')} className="px-4 active">Home</Nav.Link>
                <Nav.Link onClick={() => navigate('/aboutpage')} className="px-4 text-black">About</Nav.Link>
                <Nav.Link onClick={() => navigate('/membership')} href="#" className="px-4 text-black">Membership</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
       
      </div>
      <div className="main_bg">
        <div>
     <h1>Search for Friend</h1>
     <h2 className="text-center text-white" style={{fontSize:'50px'}}>START <span style={{color:'#ff1a8c'}}>FREE</span> TODAY</h2>
     <Button className="login_btn d-block m-auto mt-3" onClick={()=>navigate('/Login')}>LOGIN</Button><br></br>
     </div>
      </div>
    </div>

  )
}

$(document).ready(function () {
  $(window).scroll(function () {
    let scroll = $(window).scrollTop();
    if (scroll > 120) {
      $(".black").css("background", "black");
      $(".site_logo").css("width", "40%");
    }

    else {
      $(".black").css("background", "transparent");
      $(".site_logo").css("width", "auto");
    }
  })
})

export default Header;
