import React from "react";
import { useEffect } from "react";

function Test() {
  const google = window.google;
  function handleCallbackResponse(response) {
    console.log("Encoded JWT ID tken: " + response.credential);
  }

  useEffect(() => {

    google.accounts.id.initialize({
      client_id: "489002618598-d6o4ni7uoufn4669l2litpubi3vmh59g.apps.googleusercontent.com",
      callback: handleCallbackResponse
    });
    google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      { theme: "outline", size: "large" }
    );
  }, []);


  return (
    <div>
      <div id="signInDiv"></div>
    </div>
  );
}

export default Test;