import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import Routing from './Routing/Routing';
import React from 'react';
//import Popup from './popup';
function App() {
  return (
    <div className="App">
        <Routing />
        </div>
  );
}

export default App;
