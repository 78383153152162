import React, { useEffect, useState, useRef } from "react";
import Sidemenu from "../sidebar/sidemenu";
import axios from 'axios';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import { useForm } from "react-hook-form";
import { Form, Button } from 'react-bootstrap';
import { Input } from '@material-ui/core';
import send_btn from '../../assets/images/Send.png';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import Admin from '../../assets/images/lock_icon.png';
import { Card } from 'react-bootstrap';
import CardHeader from "react-bootstrap/esm/CardHeader";
import LottieLoader3 from '../lottie/lottieloader3';

const Contactus = () => {

  function makePageTransparent() {
    var overlay = document.getElementById('overlay');
    overlay.style.display = 'block';
  }

  function makePagenotTransparent() {
    var overlay = document.getElementById('overlay');
    overlay.style.display = 'none';
  }

  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollableDivRef = useRef(null);

  const local_storage = JSON.parse(localStorage.getItem('userDetails'));
  const { register, handleSubmit, formState: { errors } } = useForm();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [open, setOpen] = useState([]);
  const [close, setClose] = useState([]);
  const [tktlength, setTktLength] = useState('')
  useEffect(() => {
    makePageTransparent();
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('type', '0');
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/contact_reply_data`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData

    }).then((res) => {
      // console.log(res.data);
      setOpen(res.data.tickets);
      setTktLength(res.data.tickets.length);
      // console.log(res.data.tickets.length);
      makePagenotTransparent();

    }).catch((e) => {
      console.log(e);
      makePagenotTransparent();
    })
  }, [])
  useEffect(() => {
    makePageTransparent();
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('type', '1');
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/contact_reply_data`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData
    }).then((res) => {
      // console.log(res.data.tickets);
      setClose(res.data.tickets);
      makePagenotTransparent();

    }).catch((e) => {
      console.log(e);
      makePagenotTransparent();
    })
  }, [])

  const onContactSubmit = () => {
    makePageTransparent();
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('name', local_storage.name);
    regFormData.append('number', local_storage.number);
    regFormData.append('message', msg);
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/contact`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData
    }).then((res) => {
      if (res.data.status === 1) {
        makePagenotTransparent();
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { }
        }).showToast();
      } else {
        makePagenotTransparent();
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { }
        }).showToast();
      }
    }).catch((e) => {
      console.log(e, 'e');
      makePagenotTransparent();
    })
  }

  const [adminreply, setAdminreply] = useState([]);
  const [msg, setMsg] = useState('');
  const [tkt, setTkt] = useState('');
  const [tabs, setTabs] = useState(0);
  const [tktstatus, setTktstatus] = useState('');
  const onContactReply = (ticket_id) => {
    makePageTransparent();
    document.getElementById('chat_screen').style.visibility = "visible"
    setTkt(ticket_id);
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('ticket_id', ticket_id);

    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/contactMessages`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData
    }).then((res) => {
      // console.log(res);
      setTktstatus(res.data.ticket_status);
      // console.log(res.data.ticket_status);
      setAdminreply(res.data.contactUsMessages);
      scrollableDivRef.current?.scrollIntoView({ behavior: 'smooth' });
      makePagenotTransparent();
    }).catch((e) => {
      console.log(e, 'e');
      makePagenotTransparent();
    })
  }

  const msgsend = () => {
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('name', local_storage.displayName);
    regFormData.append('number', local_storage.number);
    regFormData.append('ticket_id', tkt);
    regFormData.append('message', msg);
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/contact`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData
    }).then((res) => {
      onContactReply(tkt);
      scrollableDivRef.current?.scrollIntoView({ behavior: 'smooth' });
      // alert('Scroll');
      setMsg('');
      if (res.data.status === 1) {
        //Toast Message Removed.
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { }
        }).showToast();
      }

    }).catch((e) => {
      console.log(e, 'e')
    })

  }

  const msgfuncton = (e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      msgsend();

    }
  }

  return (
    <div className="d-flex">
      <div className="col-3 page_left" style={{ width: 'auto' }}>
        <Sidemenu />
      </div>


      <div id="overlay">
        <div className='AddloadingImage'>
          <LottieLoader3 />
        </div>
      </div>
      <div className="col-9 d-flex flex-column contact-page page_right">
        <div className="d-flex flex-row bg-white top-bar shadow">
          <p><b>Contact Us</b></p>
        </div>
        <div className="d-flex" >
          <div className="user_info col-6 mt-4 mb-3 px-5">
            <Tabs onSelect={(e) => { setTabs(e) }} style={{ flexDirection: 'row' }}>
              <Tab label="Open Tickets">
                {/* <div className="mt-3">
                  <Form onSubmit={handleSubmit(onContactSubmit)}>
                    <Form.Group className="mb-3">
                      <Form.Control {...register("name", { required: true })} type="text" placeholder="Your Name" />
                      <span className='error_msg'> {errors.name?.type === 'required' && "Please enter your name"}</span>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} type="text" placeholder="Your Email" />
                      <span className='error_msg'> {errors.email?.type === 'required' && "Please enter email address"}</span>
                      <span className='error_msg'>{errors.email?.type === 'pattern' && "Please enter valid E-mail address"}</span>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Control {...register("message", { required: true })} as="textarea" rows={3} placeholder="Your Message" />
                      <span className='error_msg'> {errors.message?.type === 'required' && "Please enter message"}</span>
                    </Form.Group>
                    <Button type="submit" className="btn btn-secondary">
                      SEND MESSAGE
                    </Button>
                  </Form>
                </div> */}
                <div className="mt-3">
                  {open && open.length > 0 ? (
                    open.map(replies => (
                      <div key={replies.ticket_id}>
                        <button className="card-button"
                          onClick={() => { onContactReply(replies.ticket_id) }}
                        >
                          <Card className="notifications_card mb-3" style={{ backgroundColor: `${replies.ticket_id == tkt ? '#ffdddd' : ''}` }}>
                            <CardHeader style={{ width: '20%' }}>
                              <img src={Admin} className="my-2" />
                            </CardHeader>
                            <Card.Body>
                              <Card.Text>
                                <p className="m-0 p-0" style={{ fontWeight: 700, color: '#1F2024', fontSize: '15px' }}> Ticket Id </p>
                                <p className="m-0 p-0" style={{ color: '#71727A', fontSize: '12px' }}> {replies.ticket_id} </p>
                                <p className="m-0 p-0" style={{ color: '#71727A', fontSize: '12px' }}> {replies.date} {replies.time} </p>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </button>

                      </div>
                    ))) : <Button variant="btn bg-button text-white" onClick={() => { onContactReply() }} className="d-block m-auto mt-5">Add Ticket</Button>}

                </div>

              </Tab>
              <Tab label="Closed Tickets">
                <div className="mt-3">
                  {close && close.length > 0 ? (
                    close.map(replies => (
                      <div key={replies.ticket_id}>
                        <button className="card-button"
                          onClick={() => { onContactReply(replies.ticket_id) }}>
                          <Card className="notifications_card mb-3" style={{ backgroundColor: `${replies.ticket_id == tkt ? '#ffdddd' : ''}` }}>
                            <CardHeader style={{ width: '20%' }}>
                              <img src={Admin} className="my-2" />
                            </CardHeader>
                            <Card.Body>
                              <Card.Text>
                                <p className="m-0 p-0" style={{ fontWeight: 700, color: '#1F2024', fontSize: '15px' }}> Ticket Id </p>
                                <p className="m-0 p-0" style={{ color: '#71727A', fontSize: '12px' }}> {replies.ticket_id} </p>
                                <p className="m-0 p-0" style={{ color: '#71727A', fontSize: '12px' }}> {replies.date} {replies.time} </p>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </button>
                      </div>
                    ))) : <p className="text-center">No Closed Tickets</p>
                  }
                </div>
              </Tab>
            </Tabs>
          </div>


          <div className="col-6 bg-white" style={{ height: 'calc(88vh)', overflowY: "auto", overflowX: "hidden", visibility: "hidden" }} id="chat_screen">

            <div>

              <div>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <img src={Admin} style={{ width: '9%' }} />
                  <h4 className="text-center m-0 px-2" >Admin</h4>
                </div>
                <hr style={{ color: 'black' }}></hr>
              </div>

              <div className="px-5 pb-5">
                {adminreply ? (
                  adminreply.map(replies => (
                    <div >
                      <div>
                        {replies.send_by === '1' ?
                          <div >
                            <div className='d-flex align-items-center justify-content-end'>
                              <div className='msg sent'>
                                <p> {replies.msg} </p>
                              </div>
                              <div className="avatar"> U </div>
                            </div>
                            <div> </div>
                          </div>
                          :
                          <div className='d-flex align-items-center justify-content-start'>
                            <div className="avatar"> A </div>
                            <div className='msg received'>
                              <p> {replies.msg} </p>
                            </div>
                          </div>
                        }
                      </div>

                    </div>
                  )))
                  : ""}
                <div ref={scrollableDivRef}></div>
              </div>

              {tktstatus == '0' ? <div className="sendMsg_1">
                <Input onKeyDown={msgfuncton} onChange={(e) => { setMsg(e.target.value) }} style={{ width: '100%', fontSize: '15px', fontWeight: '550' }}
                  placeholder='Type message...' type="text" id="msg_input" pattern="^[a-zA-Z][\sa-zA-Z]*" value={msg} />
                <Button type="submit" className="chat_send" >
                  <img src={send_btn} onClick={msgsend} />
                </Button>
              </div> : tktstatus == '1' ? <div className="sendMsg_1" style={{ visibility: 'hidden' }}>
                <Input onChange={(e) => { setMsg(e.target.value) }} style={{ width: '100%', fontSize: '15px', fontWeight: '550' }}
                  placeholder='Type message...' type="text" id="msg_input" pattern="^[a-zA-Z][\sa-zA-Z]*" value={msg} />
                <Button type="submit" className="chat_send" >
                  <img src={send_btn} onClick={msgsend} />
                </Button>
              </div> : tktlength == '0' && <div className="sendMsg_1">
                <Input onChange={(e) => { setMsg(e.target.value) }} style={{ width: '100%', fontSize: '15px', fontWeight: '550' }}
                  placeholder='Type message...' type="text" id="msg_input" pattern="^[a-zA-Z][\sa-zA-Z]*" value={msg} />
                <Button type="submit" className="chat_send">
                  <img src={send_btn} onClick={onContactSubmit} />
                </Button>
              </div>}

            </div>

          </div>

        </div>
      </div>
    </div>

  )
}

export default Contactus